import { useStores } from "@huum/store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get, join, map, toNumber, pick } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { saveBooking as saveBookingRequest, saveBookingException as saveBookingExceptionRequest } from "@huum/api/requests/bookings"
import moment from "moment";
import { translate } from "@huum/i18n";
import { goBack } from "@huum/services/NavigationService";

export interface BookingInput {
  id?: string;
  parentId?: string
  bookingId?: string
  title: string;
  startTime: number;
  endTime: number;
  startDate?: number;
  endDate?: number;
  originalStartDate?: number;
  private?: boolean;
  isRecurring?: boolean;
  isException?: boolean;
  targetTemperature?: number;
  repeatDuration?: number;
  isCancelled?: number;
  isRescheduled?: number;
  weeklyPattern?: number[]
  parentEvent?: any,
}

const arrayToStringPattern = (pattern?: number[]) => join(pattern, '');
export const stringToArrayPattern = (pattern?: string) => pattern ? map(pattern, toNumber) : [0, 0, 0, 0, 0, 0, 0];

export const useBookingModify = () => {
  const { auth, toast, modal, sauna } = useStores();
  const session = get(auth, "sessionHash", null);
  const queryClient = useQueryClient()

  const { mutate, data, isError, isSuccess, isLoading } = useMutation(saveBookingRequest, {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["bookingsList"]})
    }
  })
  const {
    mutate: mutateException,
    data: exceptionData,
    isError: exceptionIsError,
    isSuccess: exceptionIsSuccess,
    isLoading: exceptionIsLoading
  } = useMutation(saveBookingExceptionRequest, {
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["bookingsList"]})
    }
  })

  useEffect(() => {
    if (isSuccess || exceptionIsSuccess) {
      toast.showToast({
        message: translate('text.success'),
        type: "success"
      })
    }
  }, [isSuccess, exceptionIsSuccess])



  const saveBooking = useCallback(async (data: BookingInput) => {
    const patternString = arrayToStringPattern(data.weeklyPattern);

    const body = {
      id: '',
      parentId: '',
      session,
      ...data,
      isRecurring: !!data.repeatDuration ? 1 : 0,
      weeklyPattern: patternString,
      saunaId: sauna.saunaId
    } as any;

    mutate(body)
  }, [session,])

  const saveBookingException = useCallback(async (data: BookingInput) => {
    const {
      parentId,
      bookingId,
      isException, // isAlready an exception
      weeklyPattern
    } = data;

    const patternString = arrayToStringPattern(weeklyPattern);

    const input = {
      isCancelled: 0,
      isRescheduled: 1,
      session,
      ...data,
      endDate: moment(data.endDate).unix(),
      startDate: moment(data.startDate).unix(),
      weeklyPattern: patternString,
      bookingId: isException ? parentId : data.id,
      id: isException ? bookingId : null,
      saunaId: sauna.saunaId,
    } as any;

    mutateException(input)
  }, [session,])

  const saveBookingAndUpcoming = useCallback(async (data: BookingInput) => {
    const {
      parentId,
      bookingId,
      startDate,
      originalStartDate
    } = data;

    let input = {
      ...data,
      saunaId: sauna.saunaId,
    }

    if (!parentId) {
      input.parentId = bookingId;
      input.bookingId = undefined;
      input.id = undefined;
    } else if (originalStartDate && !moment(startDate).isSame(moment.unix(originalStartDate))) {
      input.bookingId = bookingId;
    } else {
      input.id = bookingId;
    }
    await saveBooking(input)
  }, [saveBooking])


  const deleteBooking = useCallback(async (event: BookingInput) => {
    const body = {
      ...(pick(event, ["endTime", "title", "bookingId", "parentId", "comments", "isException", "saunaId", "id"])),
      startDate: moment(event.startDate).unix(),
      startTime: moment(event.startDate).unix(),
      endDate: 0,
      saunaId: sauna.saunaId,
    }
    await saveBooking(body);
    modal.cleanModalState()
  }, [saveBooking])

  const deleteBookingException = useCallback(async (event: BookingInput) => {
    let input = {
      ...(pick(event, ["id", "title", "bookingId", "parentId", "comments", "saunaId", "isException"])),
      isCancelled: 1,
      isRescheduled: 0,
      endDate: 0,
      saunaId: sauna.saunaId,
    }

    await saveBookingException(input)
    modal.cleanModalState()
  }, [saveBookingException])

  const deleteBookingAndUpcoming = useCallback(async (event: BookingInput) => {
    const { parentId, id, originalStartDate, startTime: eventStartTime, parentEvent, isException, ...rest } = event;

    const { startTime, endTime  } = parentEvent;

    let input = {
      ...(pick(rest, ["title", "bookingId", "comments", "saunaId"])),
      startTime,
      endTime,
      id,
      parentId: parentId && parentId !== id ? parentId : null,
      startDate: toNumber(originalStartDate),
      isException,
      saunaId: sauna.saunaId,
    }

    const unixStartDate = moment.unix(eventStartTime).startOf('day').unix();

    if (unixStartDate !== originalStartDate) {
      input.endDate = unixStartDate;
    } else {
      input.endDate = 0;
    }

    await saveBooking(input)
    modal.cleanModalState()
  }, [saveBooking])

  return {
    saveBooking,
    deleteBooking,
    isError,
    isSuccess,
    isLoading,
    saveBookingAndUpcoming,
    saveBookingException,
    deleteBookingException,
    deleteBookingAndUpcoming,
  };
};


