import { palette } from '@huum/theme/helpers';
import { Theme } from 'native-base';
import { Dimensions, StyleSheet } from 'react-native';


const screenWidth = Dimensions.get("window").width;

export const svgGreenConfig = {
  fill: palette.green[200],
};

export const svgRedConfig = {
  fill: palette.invalid,
  strokeLinecap: "round",
  strokeLinejoin:"round"
};

export const yAxisSVG = {
  fontWeight: "bold",
  fill: palette.primaryDark,
};

export const xAxisSVG = {
  fill: palette.dimmedText,
  fontSize: 10,
  fontWeight: "400",
};

export const getStatisticsBarGraphStyles = (theme: Theme) => {
  const { colors, space } = theme;
  return StyleSheet.create({
    graphContainer: {
      height: 300,
      width: screenWidth,
      flexDirection: "row",
      backgroundColor: palette.white[100],
    },
    barChart: {
      height: 280,
      width: screenWidth - 60,
    },
    yAxis: {
      height: 280,
      paddingLeft: 10,
      width: 40,
    },
  });
}

