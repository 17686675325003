import React, { PureComponent } from 'react';
import { ScrollView } from 'react-native';
import HTML from 'react-native-render-html';
import PropTypes from 'prop-types';
import { ModalContainer } from './modal-container';

const TAG_STYLES = {
  h1: { textAlign: 'justify', marginTop: 30, marginBottom: 15 },
  h3: { textAlign: 'justify', marginTop: 20, marginBottom: 10 },
  p: { textAlign: 'justify', marginVertical: 5 },
  li: { textAlign: 'justify' },
};

interface ModalProps  {
  closeModal: () => void;
  textName: string,
}

const HTMLModal = (props: ModalProps) => {
  const { textName, closeModal } = props;
  const texts = require('@huum/utils/texts');
  const htmlText = texts[textName];

  return (
    <ModalContainer closeModal={closeModal} title=''>
      <ScrollView>
        <HTML
          html={htmlText}
          tagsStyles={TAG_STYLES}
        />
      </ScrollView>
    </ModalContainer>
  );
}


export default HTMLModal;
