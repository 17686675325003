//@ts-nocheck
import React from 'react';
import { translate } from '@huum/i18n';
import { ModalContainer } from './modal-container';
import { Center, Image, Stack } from 'native-base';
import { Text } from '@huum/components/atoms/text';
import SlideButton from '@huum/components/molecules/slide-button';

const outerContainerHorizontalPadding = 8;
const innerContainerHorizontalPadding = 60;

const EmergencyStopModal = (props: any) => {
  const { closeModal, onStop } = props;

  const onSlideComplete = () => {
    closeModal();
    onStop()
  }

  return (
    <ModalContainer closeModal={closeModal} title={translate('label.emergency_stop')}>
      <Stack space={4}>
          <Center>
            <Image resizeMode="contain" size={20} source={require('@huum/assets/legacy/drop-red.png')} />
            <Image bottom={4} height={8} resizeMode="contain" position="absolute" source={require('@huum/assets/legacy/alert-bold.png')} />
          </Center>
          <Center>
            <Text bold textAlign="center" preset="body1">{translate('text.about_to_stop_sauna')}</Text>
            <Text textAlign="center" preset="subtitle1">{translate('text.sauna_control_will_be_disabled')}</Text>
            <Text textAlign="center" preset="subtitle1">{translate('text.to_restart_sauna_use_control')}</Text>
          </Center>
          <Stack mt={4}>
            <SlideButton
              label="Swipe to stop sauna"
              onSlideComplete={onSlideComplete}
              threshold={
                outerContainerHorizontalPadding + innerContainerHorizontalPadding
              }
            />
          </Stack>
        </Stack>
    </ModalContainer>
  );
}

export default EmergencyStopModal;
