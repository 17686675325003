import OnboardingScreen from "@huum/features/authentication/screens/onboarding";
import SaunaConnectingScreen from "@huum/features/authentication/screens/sauna-connecting";
import MenuView from "@huum/features/settings";
import ProfileView from "@huum/features/settings/views/profile";
import ChangePasswordView from "@huum/features/settings/views/profile/change-password/index";
import SafetySettings from "@huum/features/settings/views/safety";
import PaymentSettings from "@huum/features/settings/views/payment";
import BillingForm from "@huum/features/settings/views/payment/credit-card-form/index";
import StatisticsView from "@huum/features/statistics";
import BookingsView from "@huum/features/booking/bookings";
import { useStores } from "@huum/store";
import { createStackNavigator, StackNavigationOptions } from "@react-navigation/stack";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { navigate } from "@huum/services/NavigationService";
import CreateBooking from "@huum/features/booking/create-bookings";
import MainControllerScreen from "@huum/features/sauna-controller/screens/main";
import SmartConfigConnectScreen from "@huum/features/iot/smart-config-connect";
import SaunaSelectScreen from "@huum/features/sauna-controller/screens/sauna-select";


export type MainNavigatorParamList = {
  onboarding: undefined;
  saunaConnect: undefined;
  controller: undefined;
  bookingTabs: undefined;
  saunaSelect: undefined;
  CreateBooking: undefined;
  bookingDetails: undefined;
  changePassword: undefined;
  safetySettings: undefined;
  paymentSettings: undefined;
  billingForm: undefined;
  Bookings: undefined;
  Menu: undefined;
  Profile: undefined;
  Statistics: undefined;
  smartConfigConnect: undefined;
};

const forFade = ({ current }: { current: any }) => ({
  cardStyle: {
    opacity: current.progress,
  },
});

const Stack = createStackNavigator<MainNavigatorParamList>();

const mainNavigatorOptions: StackNavigationOptions = {
  headerShown: false,
}

const MainNavigator = observer(() => {
  const { auth, sauna } = useStores()
  useEffect(() => {
    if (!auth.sessionHash) {
      navigate("onboarding")
    } else if (!sauna.saunaId) {
      navigate("saunaConnect")
    } else {
      navigate('controller')
    }
  }, [auth.sessionHash])

  const initialRouteName = auth.sessionHash ? "controller" : "onboarding";
  
  return (
    <Stack.Navigator initialRouteName={initialRouteName} screenOptions={mainNavigatorOptions}>
      <Stack.Group
        screenOptions={{
          cardStyleInterpolator: forFade
        }}
      >
        {!auth.sessionHash && <Stack.Screen name="onboarding" component={OnboardingScreen} />}
      </Stack.Group>
  
      {
        auth.sessionHash && (
          <Stack.Group key={`navigation-${auth.language}`}>
            <Stack.Screen name="controller" component={MainControllerScreen} />
            <Stack.Screen name="saunaSelect" component={SaunaSelectScreen} />
            <Stack.Screen name="Bookings" component={BookingsView} />
            <Stack.Screen name="saunaConnect" component={SaunaConnectingScreen} />
            <Stack.Screen name="CreateBooking" component={CreateBooking} />
            <Stack.Screen name="Menu" component={MenuView} />
            <Stack.Screen name="Profile" component={ProfileView} />
            <Stack.Screen name="Statistics" component={StatisticsView} />
            <Stack.Screen name="changePassword" component={ChangePasswordView} />
            <Stack.Screen name="safetySettings" component={SafetySettings} />
            <Stack.Screen name="paymentSettings" component={PaymentSettings} />
            <Stack.Screen name="billingForm" component={BillingForm} />
            <Stack.Screen name="smartConfigConnect" component={SmartConfigConnectScreen} />
          </Stack.Group>
        )
      }
    </Stack.Navigator>
  )
});

export default MainNavigator;