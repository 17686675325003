//@ts-nocheck
import React from 'react';
import {
  View,
  Animated,
  Text,
  Image,
  PanResponder,
  Dimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import { useSlideButtonStyles } from './styles';
import { Touchable } from '@huum/components/atoms/touchable/touchable';

const {width} = Dimensions.get('screen');
const SLIDER_START_POSITION = 45; /* make sure this is the
                                  same size as the circle
                                  in the style sheet */

class SlideButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sliderPosition: new Animated.Value(SLIDER_START_POSITION),
      panResponder: PanResponder.create({
        onStartShouldSetPanResponder: () => false,
        onMoveShouldSetPanResponder: () => false,
      }),
    };
  }

  startPanResponder = () => {
    const {sliderPosition} = this.state;

    this.setState({
      panResponder: PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onMoveShouldSetPanResponder: () => true,
        onPanResponderMove: (_e, gestureState) => {
          const {dx, dy} = gestureState;

          Animated.spring(sliderPosition, {
            toValue: Math.max(
              Math.min(
                SLIDER_START_POSITION + dx,
                width - 2 * this.props.threshold,
              ),
              SLIDER_START_POSITION,
            ),
            tension: 150,
          }).start();

          // when user swipes out of button:
          if (Math.abs(dy) > 100) {
            this.releasePanResponder(_e, gestureState);
          }
        },
        onPanResponderRelease: this.releasePanResponder,
      }),
    });
  };

  releasePanResponder = (_, gestureState) => {
    const {onSlideComplete} = this.props;
    const {sliderPosition} = this.state;
    const {dx} = gestureState;

    const position = SLIDER_START_POSITION + dx;

    if (position + 2 * this.props.threshold >= width) {
      onSlideComplete();
    } else {
      Animated.spring(sliderPosition, {
        toValue: SLIDER_START_POSITION,
        friction: 5,
      }).start();
    }

    this.setState({
      panResponder: PanResponder.create({
        onStartShouldSetPanResponder: () => false,
        onMoveShouldSetPanResponder: () => false,
      }),
    });
  };

  render = () => {
    const {label} = this.props;
    const {panResponder, sliderPosition} = this.state;

    const slider = {width: sliderPosition};

    const styles = useSlideButtonStyles();

    return (
      <View style={styles.container} {...panResponder.panHandlers}>
        <View style={styles.slideButtonContainer}>
          <View style={[styles.safeAreaStyle, styles.slideButton]}>
            <Text style={styles.sliderLabel}>{label}</Text>
          </View>
          {/* SLIDER: */}
          <Touchable
            onPressIn={this.startPanResponder}
            style={[styles.draggableOverlay, {zIndex: 999}]}
            // event={event}
          >
            <Animated.View style={[styles.animatedView, slider]}>
              <View style={styles.sliderBackground} />
              <View style={styles.sliderCircle} />
              <Image
                source={require('@huum/assets/legacy/forward-arrow.png')}
                style={styles.arrow}
              />
            </Animated.View>
          </Touchable>
        </View>
      </View>
    );
  };
}

SlideButton.propTypes = {
  label: PropTypes.string,
  event: PropTypes.string,
  onSlideComplete: PropTypes.func.isRequired,
  threshold: PropTypes.number, // use container padding relative to screen width
};


export default SlideButton;
