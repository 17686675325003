const toFahrenheit = (degrees) => degrees * (9 / 5) + 32;

const formatDegreeStatistic = (rawResult, temperatureUnit) => {
  const unroundedResult = temperatureUnit === 'celsius' ? parseFloat(rawResult) : toFahrenheit(rawResult);
  const result = parseFloat(unroundedResult.toPrecision(3)); // 3 significant figures;

  return result;
};

export default formatDegreeStatistic;
