import moment from "moment";
import {
  times,
  groupBy,
  merge,
  reduce,
  keyBy,
  map,
  values,
  size,
  uniqBy,
  last,
  first,
  get,
  orderBy,
} from "lodash";

export const generateMonth = (month) => {
  const monthMoment = moment(month).startOf("month");
  const daysInMonth = monthMoment.daysInMonth();
  return times(daysInMonth, (iteration) => {
    const monthDayMoment = moment(monthMoment).add(iteration, "days");
    const dayIndex = monthDayMoment.get("day");
    return {
      day: dayIndex === 0 ? 6 : dayIndex - 1,
      date: monthDayMoment.get("date"),
      week: monthDayMoment.get("week"),
      year: monthDayMoment.get("year"),
      key: monthDayMoment.format("YYYY-MM-DD"),
    };
  });
};

export const loadNextWeek = (weekDate, previousData) => {
  const weekDay = moment(weekDate).startOf("week");
  const days = times(7, (iteration) => {
    const weekDayMoment = moment(weekDay).add(iteration, "days");
    const dayIndex = weekDayMoment.get("day");
    return {
      day: dayIndex === 0 ? 6 : dayIndex - 1,
      date: weekDayMoment.get("date"),
      week: weekDayMoment.get("week"),
      year: weekDayMoment.get("year"),
      key: weekDayMoment.format("YYYY-MM-DD"),
    };
  });

  const week = {
    [weekDay.week()]: keyBy(days, "day"),
  };

  return merge(week, previousData);
};

export const generateWeeksList = (monthDate) => {
  const month = generateMonth(monthDate);
  return reduce(
    groupBy(month, "week"),
    (result, value, key) => {
      if (size(value) < 7) {
        return loadNextWeek(moment(value[0].key), result);
      }

      return {
        ...result,
        [key]: keyBy(value, "day"),
      };
    },
    {}
  );
};

export const loadCalendarMonthData = (monthDate, previousData) => {
  const monthsList = [
    ...previousData,
    {
      month: moment(monthDate).month(),
      weeks: orderBy(generateWeeksList(monthDate), (value: any) => value[0].key),
    },
  ];

  return uniqBy(monthsList, "month");
};

export const loadNextMonth = (monthDate, previousData) => {
  const weeksList = generateWeeksList(monthDate);
  return merge(weeksList, previousData);
};

export const toVisibleListData = (dataObjects) =>
  orderBy(
    map(dataObjects, (value, key) => ({
      week: key,
      year: get(last(values(value)), "year", get(first(values(value)), "year")),
      days: values(value),
    })),
    // Week is string, simply converting to number will break it elsewhere, easier to just order here correctly
    [
      "year",
      function (o) {
        return Number(o.week);
      },
    ]
  );
