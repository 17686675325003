import { StyleSheet } from "react-native"
import { Theme } from 'native-base';

export const getStyles = (theme: Theme) => {
  const { colors, space, shadows } = theme;
  return StyleSheet.create({
    container: {
      ...shadows[6]
    }
  })
}
