import React, { useCallback, useEffect } from "react";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { translate } from "@huum/i18n";
import { Box, KeyboardAvoidingView, Stack } from "native-base";
import { Screen } from "@huum/components/organisms/screen";
import { useStores } from "@huum/store";
import { Text } from "@huum/components/atoms/text";
import { FormInput } from "@huum/components/atoms/form-input";
import { useForm } from "react-hook-form";
import Button from "@huum/components/atoms/button";
import { useChangePassword } from "@huum/hooks/api/useProfile";
import { goBack } from "@huum/services/NavigationService";

// Add error handling on input values and modal messages on request
const ChangePasswordView = () => {
  const { auth, toast } = useStores();
  const { control, handleSubmit } = useForm();

    const { getNewPassword, isError, isSuccess } = useChangePassword();

  const handlePasswordChange = useCallback((values: any) => {
    const { oldPassword, password1, password2 } = values;
    if (!oldPassword || !password1 || !password2) {
      toast.showToast({
        message: translate("error.password"),
        type: "error",
        duration: 3000,
      });
    } else {
      getNewPassword({...values})
    }
  }, [])

  useEffect(() => {
    if (isSuccess) {
      toast.showToast({
        message: translate("text.password_changed"),
        type: "success",
        duration: 3000,
      });
      goBack();
    }
  }, [isSuccess]);

  return (
    <>
      <MainHeader
        withBackNavigation
        title={translate("button.profile_and_id")}
        info={translate("button.change_password")}
      />
      <Screen>
        <Stack space="8" paddingX="2" pb="8" justifyContent="center" flex={1}>
          <Text preset="subtitle1" fontWeight={"600"}>
            {translate("button.change_password")}
          </Text>
          <FormInput
            isSecure
            name="oldPassword"
            placeholder={translate("label.old_password")}
            control={control}
          />
          <FormInput
            isSecure
            name="password1"
            placeholder={translate("label.new_password")}
            control={control}
          />
          <FormInput
            isSecure
            name="password2"
            placeholder={translate("label.confirm_password")}
            control={control}
          />
          {/* KeyboardAvoidingView needs adjusting */}
          <KeyboardAvoidingView/>
        </Stack>
        <Box safeAreaBottom={4} paddingX="2">
          <Button
            label={"Confirm"}
            onPress={handleSubmit(handlePasswordChange)}
          />
        </Box>
      </Screen>
    </>
  );
};

export default ChangePasswordView;
function useProfile(): { getNewPassword: any; isLoading: any; } {
  throw new Error("Function not implemented.");
}

