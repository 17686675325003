import { BookingInput } from "@huum/hooks/api/useBookingModify";
import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
import moment from "moment";

interface BookingsListProps {
  session: string;
  date: string;
  saunaId: string;
}

interface SaveBookingBody extends Omit<BookingInput, 'repeatPattern'>{
  repeatPattern: string;
  session: string;
  saunaId: string;
}


export async function getBookingsList(props: BookingsListProps) {
  const { session, date, saunaId } = props;

  const response = await api.huum.get("/booking/get_bookings", {
    version: 3,
    session,
    month: moment(date).format('YYYY-MM'),
    saunaId,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function saveBooking(body: SaveBookingBody) {

  const response = await api.huum.post("/booking/save_booking", {
    version: 3,
    ...body
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function saveBookingException(body: SaveBookingBody) {
  const response = await api.huum.post("/booking/save_booking_exception", {
    version: 3,
    ...body
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}



