//@ts-nocheck

import React, { useCallback, useMemo } from "react";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "./modal-container";
import { translate } from "@huum/i18n";
import { Box, Spacer, Stack } from "native-base";
import { useStores } from "@huum/store";
import { getModalStyles } from "./styles";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import moment from "moment";
import { isEmpty, map, upperCase } from "lodash";
import Button from "@huum/components/atoms/button";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { navigate } from "@huum/services/NavigationService";
import { BookingInput, useBookingModify } from "@huum/hooks/api/useBookingModify";

interface BookingDetailModalProps {
  mine: boolean;
  isPrivate: boolean;
  day: string;
  month: string;
  start: string;
  end: string;
  parentId?: string;
  startDate: string;
  title: string;
  owner: string;
  quests?: string[];
  comments?: string[];
  repeatDuration: number;
}

// some headers do not have trnaslations 
const BookingDetailModal = (props: BookingDetailModalProps) => {
  const {
    mine,
    isPrivate,
    startDate,
    start,
    end,
    title,
    owner,
    quests,
    comments,
    repeatDuration,
    parentId,
  } = props;
  const { modal } = useStores();

  const { deleteBookingAndUpcoming, deleteBookingException, deleteBooking } = useBookingModify();

  const styles = useThemedStyles(getModalStyles);

  const privateText = isPrivate ? translate('label.private') : null;

  const handleEditPress = useCallback(() => {
    navigate("CreateBooking", { event: props });
    modal.closeModal();
  }, [props])

  const handleDeletePress = useCallback(() => {
    if (repeatDuration > 0) {
      modal.openModal("confirmModal", {
        onConfirm: () => deleteBookingException(props),
        onCancel: () => deleteBookingAndUpcoming(props),
        modalTitle: translate("text.you_are_deleting_a_repeating_booking"),
        contentText: translate("text.delete_single_event_or_all"),
        confirmButtonLabel: translate("label.single"),
        cancelButtonLabel: translate("label.all"),
      });
    } else if (parentId) {
      deleteBookingException(props)
    } else {
      deleteBooking(props);
    }
    

  }, [props, deleteBooking])

  const handleContactHost= useCallback(() => {

  }, [props])

  const guestsList = useMemo(() => {
    if(!isEmpty(quests)) {
      return (
        <Box mt={8}>
          <Text style={styles.dimmedLargeText}>
            {translate("label.guests")}
          </Text>
          <Spacer h={2} />
          {map(quests, (guest) => (
            <Text style={styles.personText}>{guest}</Text>
          ))}
        </Box>
      );
    } else null
  }, [quests])

  const displayButtons = useMemo(() => {
    if(!mine) {
      return <Button onPress={handleContactHost} label={"Contact host"} />;
    } else return (
      <Stack alignItems="center" space={6}>
        <Button onPress={handleEditPress} label={translate('label.edit_booking')} style={styles.button} />
        <Touchable
          justifyContent={"center"}
          alignItems={"center"}
          onPress={handleDeletePress}
        >
          <Text style={styles.buttonText}>
            {translate("label.delete_booking")}
          </Text>
        </Touchable>
      </Stack>
    );
  }, [])

  const commentsList = useMemo(() => {
    if (!isEmpty(comments)) {
      return (
        <Box mt={8}>
          <Text style={styles.dimmedLargeText}>
            {translate("label.comments")}
          </Text>
          <Spacer h={2} />
          {map(comments, (comment) => (
            <Text style={styles.personText}>{comment}</Text>
          ))}
        </Box>
      );
    } else null;
  }, [comments]);

  return (
    <ModalContainer
      title={translate("label.bookings")}
      closeModal={modal.closeModal}
    >
      <Box paddingX={4}>
        <Box mb={2}>
          <Text style={styles.privateText}>{privateText}</Text>
        </Box>
        <Text style={styles.titleText}>{title}</Text>
        <Box flexDir={"row"} mt={4}>
          <Box mr={12}>
            <Text style={styles.dateText}>
              {moment(startDate).format("MMMM DD")}
            </Text>
            <Text style={styles.dimmedText}>
              {upperCase(moment(startDate).format("ddd"))}
            </Text>
          </Box>
          <Box>
            <Text style={styles.dateText}>
              {start} - {end}{" "}
            </Text>
            <Text style={styles.dimmedText}>{translate('text.heating_time')}</Text>
          </Box>
        </Box>
        {guestsList}
        <Box mt={8}>
          <Text style={styles.dimmedLargeText}>{translate('text.hosted_by')}</Text>
          <Spacer h={2} />
          <Text style={styles.personText}>{owner}</Text>
        </Box>
        {commentsList}
        <Box mt={8}>{displayButtons}</Box>
      </Box>
    </ModalContainer>
  );
};

export default BookingDetailModal;
