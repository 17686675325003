import React from "react";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "./modal-container";
import { translate } from "@huum/i18n";
import { Control, useForm } from "react-hook-form";
import { Stack } from "native-base";
import Button from "@huum/components/atoms/button";
import TextButton from "@huum/components/molecules/text-button";

interface ModalContentProps {
  control?: Control;
  saunaId: string;
  modalTitle: string;
  contentText: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  onConfirm: () => void;
  closeModal: () => void;
  onCancel: () => void;
}

const ConfirmModal = (props: ModalContentProps) => {
  const {
    onConfirm,
    onCancel,
    modalTitle,
    closeModal,
    contentText,
    confirmButtonLabel,
    cancelButtonLabel,
  } = props;


  const handleConfirm = () => {
    onConfirm && onConfirm();
    closeModal()
  }

  const handleCancel = () => {
    onCancel && onCancel();
    closeModal()
  }

  return (
    <ModalContainer
      title={modalTitle}
      closeModal={closeModal}
    >
      <Text preset="subtitle2" alignSelf={"center"} mb={8}>
        {contentText}
      </Text>
      <Stack space={4} justifyContent="center" alignItems="center" >
        <Button width="100%" onPress={handleConfirm} label={confirmButtonLabel} />
        <TextButton onPress={handleCancel} title={cancelButtonLabel || translate('button.cancel')} />
      </Stack>
    </ModalContainer>
  );
};

export default ConfirmModal;
