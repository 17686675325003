import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import {
  range,
  map,
  includes,
  toString,
} from 'lodash';
import { Touchable } from '@huum/components/atoms/touchable/touchable';
import { Box, ScrollView, View } from 'native-base';
import { Text } from '@huum/components/atoms/text';
import { navigate } from '@huum/services/NavigationService';
import { useStores } from '@huum/store';
import { useBookingEventsAgendaStyles } from './styles';

interface BookingEventsAgendaProps {
  activeDate: string
  unAvailableHours?: any[]
  events?: any[],
  onNewEventPress?: (date: string) => void
  onEventPress?: (event: any) => void
}

const BookingEventsAgenda = (props: BookingEventsAgendaProps) => {
  const {
    activeDate,
    unAvailableHours,
    events,
    onNewEventPress,
    onEventPress  
  } = props;

  const contentOffset = {
    x: 0,
    y: moment(activeDate).isSame(moment(), 'day') ? moment().hour() * 60 : 0
  }

  const { modal: { openModal } } = useStores();

  const styles = useBookingEventsAgendaStyles();

  const getEventParameters = (event) => {
    const start = moment(event.startDate).isBefore(moment(activeDate), 'day')
      ? moment(activeDate).startOf('day')
      : moment(event.startDate);
      
    const end = moment(event.endDate).isAfter(moment(activeDate), 'day')
      ? moment(activeDate).endOf('day').add(1, 'hours')
      : moment(event.endDate);
    
    return {
      event,
      start,
      end,
      duration: end.diff(start, 'hours'),
    };
  }

  const handleNewBookingPress = (hour: number) => () => {
    const date = moment(activeDate);
    const startTime = date.set('hour', hour).startOf('hour');
    onNewEventPress && onNewEventPress(startTime.format())
  }


  const BookingListItem = ({hour}) => {
    const hasEvent = includes(unAvailableHours, hour);
    const isInPast = moment(activeDate).set('hour', hour).isBefore(moment());
    return (
      <Touchable
        height="60px"
        justifyContent="center"
        onPress={handleNewBookingPress(hour)}
        disabled={hasEvent || isInPast}
        opacity={hasEvent || isInPast ? 0.5 : 1}
        key={toString(hour)}
      >
        <View height="1px" width="full" backgroundColor="primaryLight" />
        <Box
          position="absolute"
          left={4}
          backgroundColor="white.100"
          padding={2}
        >
          <Text
          >
            {`${hour}:00`}
          </Text>
        </Box>
        
      </Touchable> 
    );
  }

  const EventItem = (eventData: any) => {
    const {
      duration,
      start,
      event,
    } = getEventParameters(eventData);
    const eventHeight = Math.max(duration, 1) * 60;
    const eventOffset = (start.get('hour') + (start.get('minute') / 60)) * 60 + 45; // 60 is EventItem height. 45 is correction pixels

    const originalStartTime = moment(event.startDate).format('HH:mm');
    const originalEndTime = moment(event.endDate).format('HH:mm');
    return (
      <Touchable
        height={eventHeight || 0}
        top={eventOffset || 0}
        position="absolute"
        width={233}
        right={16}
        shadow={2}
        backgroundColor="primaryLight"
        padding={4}
        borderRadius={16}
        onPress={onEventPress(event)}
        key={event.title}
      >
        <Text
          style={[
            styles.eventDescription,
          ]}
        >
          {event.title}
        </Text>
        <Text
          style={[
            styles.eventContentText,
          ]}
        >
          {`${originalStartTime}-${originalEndTime}`}
        </Text>
      </Touchable>
    );
  }

  const Events = useMemo(() => {
    return map(events, (event) => EventItem(event));
  }, [events])

  const BookingHours = useMemo(() =>
    map(range(0, 24), (value) => <BookingListItem key={value} hour={value} />)
  , [activeDate, unAvailableHours])

  return (
    <ScrollView
      contentOffset={contentOffset}
      background="white.100"
    >
      {BookingHours}
      {Events}
    </ScrollView>
  );
}

export default BookingEventsAgenda;

