import React, { useCallback, useMemo } from 'react';
import { take } from 'lodash';
import { Row, Text } from 'native-base';
import { ScrollSelector } from '../scroll-selector';

const { hours, minutes, durations } = require('./values.json');

interface TimeSelectProps {
  hourValue: number;
  minuteValue: number;
  onValueSelect: (value: {
    minute: number,
    hour: number,
  }) => void;
  animated: boolean;
  durationSelect: boolean;
  maxHour?: number;
  visible: boolean;
};



const TimeSelect = (props: TimeSelectProps) => {
  const {
    hourValue,
    minuteValue,
    animated,
    visible,
    maxHour,
    durationSelect,
    onValueSelect,
  } = props;

  const setHour = useCallback( (value: any) => {
    onValueSelect({
      minute: minuteValue,
      hour: value,
    });
  }, [onValueSelect, minuteValue])

  const setMinute = useCallback( (value: any) => {
    onValueSelect({
      minute: value,
      hour: hourValue,
    });
  }, [onValueSelect, hourValue])
    

  const hourValues = useMemo(() => (durationSelect && take(durations, maxHour)) || hours, [hours, durationSelect, maxHour])
  // disable minute selection if we've chosen the maximum hour
  const disableMinutes = maxHour === hourValue + 1;

  if (!visible) return null;

  return (
    <Row width="full" justifyContent="center" alignItems="center">
      <ScrollSelector
        visible={visible}
        onValueSelect={setHour}
        selectorValues={hourValues}
        value={hourValue}
        animated={animated}
        animatedDirection="left"
        // label={translate('label.hour')}
      />
      <Text mx={4} fontSize="22">:</Text>
      <ScrollSelector
        visible={visible}
        onValueSelect={setMinute}
        selectorValues={minutes}
        animated={animated}
        animatedDirection="right"
        value={minuteValue}
        // label={translate('label.minutes')}
        disabled={disableMinutes}
      />
    </Row>
  );
}

export default TimeSelect;
