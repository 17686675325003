const SaunaStatusCode = {
  // Service response code when sauna is offline
  SAUNA_OFFLINE: 230,
  // Service response code when sauna is active/started
  SAUNA_ACTIVE: 231,
  // Service response code when sauna is not active and is stopped
  SAUNA_NOT_ACTIVE: 232,
  // Service response code when sauna is online and started by other user
  SAUNA_ACTIVE_BLOCKED: 233,
  // Service response code when sauna is online and emergency stopped
  SAUNA_EMERGENCY_STOP: 400,
};

export default SaunaStatusCode;
