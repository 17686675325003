
import React, { useMemo } from 'react';
import { translate } from '@huum/i18n';
import { Box, Stack } from 'native-base';
import { Text } from '@huum/components/atoms/text';
import { map, toNumber, toString } from 'lodash';
import { Tabs } from '@huum/components/molecules/tabs';
import WeekDaysSelector from '../weekdays-selector';

interface RepeatSectionProps {
  pattern: number[];
  onRepeatDurationChange: (duration: number) => void;
  repeatDuration?: number;
  onPressWeekDay: (day: number) => void;
  selectedRepeatDuration: number;
  disabled: boolean;
}

const options = () =>  [{ label: translate('label.1_week'), value: 1 },
{ label: translate('label.2_weeks'), value: 2 },
{ label: translate('label.3_weeks'), value: 3 },
{ label: translate('label.4_weeks'), value: 4 }]

const RepeatSection = (props: RepeatSectionProps) => {

  const {
    onRepeatDurationChange,
    repeatDuration,
    disabled,
    onPressWeekDay,
    pattern,
  } = props

  const handleRepeatDurationChange = (value: number) => {
    onRepeatDurationChange && onRepeatDurationChange(value)
  }
  
  const durationTabs = useMemo(() => map(options(), (option, index) => ({
    index,
    onPress: () => handleRepeatDurationChange(option.value),
    isFocused: toNumber(repeatDuration) === option.value,
    title: option.label,
  })), [repeatDuration])
  
  return (
    <Stack py={4}>
      <Tabs
        plain
        tabs={durationTabs}
        disabled={disabled}
      />
      <Text mt={4}>
        {translate('text.repeat_on')}
      </Text>
      <WeekDaysSelector
        onPress={onPressWeekDay}
        selectedDays={pattern}
        disabled={disabled}
      />
    </Stack>
  );
}

export default RepeatSection
