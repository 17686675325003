import { palette } from "../helpers";


export default {
  ...palette,
  primary: "#0D0E15",
  primaryDark: '#0D0E15',
  primaryMedium: '#0D0E15',
  primaryLight: '#CCCCCC',
  iconColor: '#0D0E15',
};