import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import moment from "moment";
import { Box, Row } from "native-base";
import React from "react";


interface CalendarHeaderProps {
  currentMonth?: any;
  futureDatesLimit?: number;
  pastDatesLimit?: number;
  onPreviousWeekPress?: () => void;
  onNextWeekPress?: () => void;
}

const hitslop = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 20,
}
 
const CalendarHeader = (props: CalendarHeaderProps) => {
  const {
    currentMonth,
    pastDatesLimit,
    futureDatesLimit,
    onPreviousWeekPress,
    onNextWeekPress,
  } = props;

  const previousButtonDisabled =
    typeof currentMonth === "number" &&
    moment().subtract(pastDatesLimit, "months").isSame(currentMonth, "week");
  const nextButtonDisabled =
    typeof futureDatesLimit === "number" &&
    moment()
      .endOf("month")
      .add(futureDatesLimit, "months")
      .isSame(currentMonth, "week");

  return (
    <Row
      paddingX={100}
      minHeight={5}
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      flex={1}
      mb={2}
    >
      <Touchable
        disabled={previousButtonDisabled}
        onPress={onPreviousWeekPress}
        hitSlop={hitslop}
      >
        <Box justifyContent="center">
          {!previousButtonDisabled && <Icon size={6} icon="left" />}
        </Box>
      </Touchable>
      <Text preset="subtitle2" fontWeight="bold" lineHeight={20}>
        {currentMonth.format("MMMM")}
      </Text>
      <Touchable
        disabled={nextButtonDisabled}
        onPress={onNextWeekPress}
        hitSlop={hitslop}
      >
        <Box justifyContent="center">
          {!nextButtonDisabled && <Icon size={6} icon="right" />}
        </Box>
      </Touchable>
    </Row>
  );
};

export default CalendarHeader;
