import { IconSetTypes } from "@huum/assets/icons";
import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { ISliderProps, Row, Slider } from "native-base"
import { useCallback, useState } from "react";

interface SliderSelectProps extends ISliderProps {
  disabled?: boolean;
  icon?: IconSetTypes;
  unit?: string;
  showValue?: boolean;
}

const SliderSelect = (props: SliderSelectProps) => {
  const {
    disabled,
    value,
    onChange,
    icon,
    defaultValue,
    unit = '℃',
    showValue = true,
    onChangeEnd,
    ...rest
  } = props;
  
  const [sliderValue, setSliderValue] = useState<number>(value || defaultValue || 0)

  const onChangeValue = useCallback((newValue: number) => {
    setSliderValue(newValue)
    onChange && onChange(newValue)
  }, [onChange])

  const handleOnChangeEnd= useCallback((newValue: number) => {
    onChangeEnd && onChangeEnd(newValue)
  }, [onChangeEnd])
  

  return (

    <Row space={4} width="full" alignItems="center">
      <Icon icon={icon} />
      <Slider
        w="3/4"
        {...rest}
        defaultValue={defaultValue}
        value={sliderValue}
        onChange={onChangeValue}
        onChangeEnd={handleOnChangeEnd}
      >
        <Slider.Track>
          <Slider.FilledTrack background="primaryDark" />
        </Slider.Track>
        <Slider.Thumb borderWidth="0" background="primaryDark" />
      </Slider>
      {showValue && <Text>{`${sliderValue}${unit}`}</Text>}
    </Row>
  )
}

export default SliderSelect;