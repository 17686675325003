import { Box, IBoxProps, useTheme } from "native-base";
import React from "react";

const Card = (props: IBoxProps) => {
  const { children, ...rest } = props;

  const { colors } = useTheme();
  return (
    <Box bg={colors.white[100]} borderRadius={16} padding={4} shadow={3} {...rest}>
      {children}
    </Box>
  )
}

export default Card;