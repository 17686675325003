import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { AuthStore, AuthStoreModel } from "../auth/auth-store"
import { ModalStore, ModalStoreModel } from "../modal/modal-store"
import { ToastStore, ToastStoreModel } from "../toast/toast-store"
import { SaunaStore, SaunaStoreModel } from "../sauna/sauna-store";
import { LedControllerStore, LedControllerStoreModel } from "../iot/led-controller-store";

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  auth: types.optional(AuthStoreModel, {} as any),
  modal: types.optional(ModalStoreModel, {} as any),
  toast: types.optional(ToastStoreModel, {} as any),
  sauna: types.optional(SaunaStoreModel, {} as any),
  iot: types.optional(LedControllerStoreModel, {} as any),
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {
  auth: AuthStore;
  modal: ModalStore;
  toast: ToastStore;
  sauna: SaunaStore;
  iot: LedControllerStore;
}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
