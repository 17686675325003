//@ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import Modal from "react-native-modal"
import { useStores } from "../../../store"
import { MODAL_TYPES } from "./modal-types-map"
import { Box, Center } from "native-base"

const MODAL_ROOT_STYLE = { margin: 0, padding: 0, justifyContent: 'flex-end' } as ViewStyle // to remove default maring around modal

/**
 * Describe your component here
 */
export const ModalRoot = observer(function ModalRoot() {
  const { modal } = useStores()
  const { modalType, closeModal, modalProps, modalShouldClose, cleanModalState, modalStash, popModal } = modal;

  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    cleanModalState(true);
  }, [])

  useEffect(() => {
    setModalVisible(modalShouldClose ? false : !!modalType);
  }, [modalType, modalShouldClose])

  const Component = useCallback((props: any) => {
    if (modalType) {
      return MODAL_TYPES()[modalType](props)
    }

    return null;

  }, [modalType])

  const handleClose = useCallback(() => {
    if (modalStash?.length > 0 && !modalProps.clearStash) {
      popModal()
    } else if (!modalProps?.persistant) {
      closeModal();
    }
  }, [modalProps, closeModal]);


  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={!!isModalVisible}
      hideModalContentWhileAnimating
      useNativeDriver
      onBackButtonPress={handleClose}
      onBackdropPress={handleClose}
      onModalHide={cleanModalState}
      avoidKeyboard
      propagateSwipe
    >
       <Box
        safeArea
        borderRadius={30}
        minWidth="2/3"
        maxWidth="3/4"
        alignSelf={"center"}
      >
        <Component {...modalProps} closeModal={handleClose} />
      </Box>
    </Modal>
  );
})
