//@ts-nocheck
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStores } from "@huum/store";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, split, toString } from "lodash";
import { getClientToken, getCustomerInfo, getPaymentAmount, savePaymentNonce } from "@huum/api/requests/user";
import { navigate } from "@huum/services/NavigationService";
import { translate } from "@huum/i18n";


export const usePaymentFlow = (type: "info" | 'payment') => {
  const { auth, toast, sauna } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const { data: customerInfo, isSuccess: customerInfoSuccess } = useQuery(["customerInfo"], () =>
    getCustomerInfo({ session: auth.sessionHash, saunaId: sauna.saunaId }),
    {
      enabled: type === "info"
    }
  );

  const { data: clientToken, isSuccess: hasToken } = useQuery(
    ["clientToken"],
    () =>
      getClientToken({ session: auth.sessionHash, saunaId: sauna.saunaId }),
    {
      enabled: type === "payment"
    }
  );

  const { data: paymentAmount, isSuccess: hasAmount } = useQuery(
    ["paymentAmount"],
    () =>
      getPaymentAmount({
        session: auth.sessionHash,
        saunaId: sauna.saunaId,
      }),
    {
      enabled: type === "payment"
    }
  );

  const { mutate: savePaymentNonceMutation } = useMutation(savePaymentNonce);



  const connectPayment = async (values: any) => {
    
  };

  return {
    isLoading,
    customerInfo,
    customerInfoSuccess,
    connectPayment,
  };
}
