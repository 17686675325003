import { UserEmailAuthBody } from "@huum/api/requests/auth";
import Button from "@huum/components/atoms/button";
import { FormInput } from "@huum/components/atoms/form-input";
import { Text } from "@huum/components/atoms/text";
import { FlowComponentProps } from "@huum/components/organisms/flow-container";
import { useLogin } from "@huum/hooks/api/useLogin";
import { translate } from "@huum/i18n";
import { Center, Stack } from "native-base";
import { useCallback } from "react";

const LoginView = (props: FlowComponentProps) => {
  const { control, handleSubmit, setViewComponent } = props;

  const { login, isLoading } = useLogin();

  const handleLogin = useCallback(
    (values: UserEmailAuthBody) => {
      login({ username: values?.username, password: values?.password });
    },
    [login],
  )

  const onPasswordResetPress = () => setViewComponent('passwordReset')
  

  return (
    <Stack
      width={{
        sm: "100%",
        md: "420px"
      }}
    >
      <Stack
        mt={20}
        mb={4}
        space={4}
      >
        <FormInput
          placeholder={translate("label.email")}
          name="username"
          control={control}
          autoCapitalize="none"
          autoCorrect={false}
          keyboardType="email-address"
        />
        <FormInput
          placeholder={translate("label.password")}
          name="password"
          control={control}
          isSecure
        />
      </Stack>
      <Stack space={4}>
        <Button onPress={onPasswordResetPress} variant="text" label={translate("button.forgot_password")} />
        <Button onPress={handleSubmit(handleLogin)} label={translate("button.log_in")} />
      </Stack>
    </Stack>
  );
};

export default LoginView;
