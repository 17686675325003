import { StyleSheet } from "react-native"
import { palette } from "@huum/theme/helpers/palette"
import { Theme } from 'native-base';

export const getStyles = (theme: Theme) => {
  const { colors, space, shadows } = theme
  return StyleSheet.create({
    headerContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: space[4],
      paddingVertical: space[4],
      backgroundColor: palette.background,
    },
    headerContainerPlain: {
      flexDirection: "row",
      paddingHorizontal: space[4],
      marginBottom: space[4],
      backgroundColor: colors.gray[50],
    },
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    textContainer: {
      flexDirection: "column",
    },
  });
}
