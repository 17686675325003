import { Box, Stack } from "native-base";
import { translate } from "@huum/i18n";
import moment from "moment";
import { FormInput } from "@huum/components/atoms/form-input";
import { useForm, useWatch } from "react-hook-form";
import { Screen } from "@huum/components/organisms/screen";
import SeparatorLine from "@huum/components/atoms/separator";
import TimeSelectorRow from "@huum/components/organisms/expandable-timer";
import { useRoute } from "@react-navigation/native";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { useCallback, useEffect, useState } from "react";
import ToggleSection from "@huum/components/molecules/toggle-section";
import RepeatSection from "../components/repeat-section";
import { toggleRepeatingDayHelper } from "@huum/utils/selectors/booking";
import SliderSelect from "@huum/components/molecules/slider-select";
import Button from "@huum/components/atoms/button";
import {
  stringToArrayPattern,
  useBookingModify,
} from "@huum/hooks/api/useBookingModify";
import { useStores } from "@huum/store";
import { pick, toNumber } from "lodash";
import { goBack, navigate } from "@huum/services/NavigationService";

const defaultRepeatPattern = [0, 0, 0, 0, 0, 0, 0];

interface CreateBookingProps {
  event?: any,
  date?: string,
  onSubmit?: () => void,
}

const CreateBooking = (props: CreateBookingProps) => {
  const { event = {}, date, onSubmit } = props;
  const { sauna, modal } = useStores();
  const { maxHeatingTime } = useSaunaStatus(true);

  const startTime = event?.startDate
  ? moment(event.startDate)
  : moment(date);

  const endTime = event?.endDate
  ? moment(event.endDate)
  : moment(startTime).add(3, "hours");

  const [timerValues, setTimerValues] = useState({
    startTime,
    endTime,
  },)
  
  const { saveBooking, saveBookingException, saveBookingAndUpcoming } =
  useBookingModify();
  
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      title: event?.title,
      weeklyPattern: stringToArrayPattern(event?.weeklyPattern),
      targetTemperature: 0,
      repeatDuration: event?.repeatDuration || 0,
      isPrivate: !!event?.isPrivate,
    },
  });

  const onTimeChange = useCallback(
    (values: any) => {
      setTimerValues({
        startTime: moment(values.start),
        endTime: moment(values.end),
      });
    },
    [setTimerValues]
  );

  const [repeatDuration, pattern, targetTemp, isPrivate] =
    useWatch({
      control,
      name: [
        "repeatDuration",
        "weeklyPattern",
        "targetTemperature",
        "isPrivate",
      ],
    });

  const changeFormValue = (key: any) => (value: any) => {
    setValue(key, value);
  };

  const onHeaterSectionToggle = () => {
    setValue("targetTemperature", targetTemp === 0 ? 90 : 0);
  };

  const onTargetTempChange = (value: number) => {
    setValue("targetTemperature", value);
  };

  const onRepeatSectionToggle = () => {
    setValue("repeatDuration", repeatDuration > 0 ? 0 : 1); // todo
    if (repeatDuration > 0) {
      setValue("weeklyPattern", defaultRepeatPattern); // todo
    }

    if (maxHeatingTime < 6) {
      setValue("targetTemperature", 0)
    }
  };

  const onChangeRepeatPattern = (day: number) => {
    const newPattern = toggleRepeatingDayHelper(
      day,
      pattern || defaultRepeatPattern
    );
    
    setValue("weeklyPattern", newPattern); // todo
  };

  const handleBookingSubmit = handleSubmit((values) => {
    const { isPrivate, repeatDuration, ...rest } = values;

    let input = {
      ...pick(event, ["id", "isException", "parentId"]),
      startTime: moment(timerValues.startTime).unix(),
      endTime: moment(timerValues.endTime).unix(),
      private: isPrivate ? 1 : 0,
      repeatDuration,
      bookingId: event.bookingId || event.id,
      ...rest,
    };

    if (event.id && repeatDuration) {
      modal.openModal("confirmModal", {
        onConfirm: () => {saveBookingException(input); goBack()},
        onCancel: () => {saveBookingAndUpcoming(input), goBack()},
        modalTitle: translate("text.you_are_editing_a_repeating_booking"),
        contentText: translate("text.change_single_event_or_all"),
        confirmButtonLabel: translate("label.single"),
        cancelButtonLabel: translate("label.all"),
      });
      return;
    } else if (event.parentId) {
      saveBookingException(input);
    } else {
      saveBooking(input);
    }

    onSubmit && onSubmit()
    navigate("Bookings");
  });

  const renderTemperatureSelect = useCallback(() => (
    <Box mt={4}>
      <SliderSelect
        onChange={onTargetTempChange}
        defaultValue={toNumber(targetTemp)}
        icon="heat"
        minValue={40}
        maxValue={110}
      />
    </Box>
  ), [targetTemp, sauna.latestTargetTemp])


  return (
    <Screen padding={0} mt={8}>
      <Box p={4}>
        <FormInput
          name="title"
          placeholder={translate("label.event_placeholder")}
          control={control}
          isDisabled={event.id}
        />
      </Box>
      <SeparatorLine mx={4} />
      <Stack space={6} p={4}>
        <TimeSelectorRow
          start={timerValues.startTime}
          end={timerValues.endTime}
          maxHeatingTime={maxHeatingTime}
          onChange={onTimeChange}
          timerStartData={startTime}
        />
        <SeparatorLine />
        <ToggleSection
          label={translate("label.repeat")}
          isToggled={repeatDuration > 0}
          onToggle={onRepeatSectionToggle}
          disabled={event?.repeatDuration && event.id}
          ExtraComponent={() => (
            <RepeatSection
              disabled={event?.repeatDuration && event.id}
              pattern={pattern}
              repeatDuration={event?.repeatDuration || repeatDuration}
              onRepeatDurationChange={changeFormValue("repeatDuration")}
              onPressWeekDay={onChangeRepeatPattern}
            />
          )}
        />
        <SeparatorLine />
        <ToggleSection
          label={translate("label.start_heater_automatically")}
          onToggle={onHeaterSectionToggle}
          isToggled={targetTemp > 0}
          disabled={maxHeatingTime < 6 && !!repeatDuration}
          ExtraComponent={renderTemperatureSelect}
        />
        <SeparatorLine />
        <ToggleSection
          label={translate("label.is_event_private")}
          onToggle={changeFormValue("isPrivate")}
          isToggled={isPrivate}
          infoText={translate('info.event_private')}
        />
        <SeparatorLine marginX={2} />
        <Button
          onPress={handleBookingSubmit}
          mt={2}
          label={translate("button.save")}
        />
      </Stack>
    </Screen>
  );
};

export default CreateBooking;
