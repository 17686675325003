export const presets = {
  h1: {
    fontSize: "6xl",
    fontWeight: "bold",
  },
  h2: {
    fontSize: "5xl",
    fontWeight: "extraBold",
  },
  h3: {
    lineHeight: 'xl',
    fontSize: "4xl",
    fontWeight: "semibold",
  },
  h4: {
    lineHeight: 'lg',
    fontSize: "3xl",
    fontWeight: "semibold",
  },
  h5: {
    lineHeight: 'lg',
    fontSize: "2xl",
  },
  h6: {
    fontSize: "xl",
    lineHeight: 25,
  },
  body1: {
    fontSize: "lg",
    lineHeight: 28,
  },
  body2: {
    color: "gray.600",
    fontSize: "md",
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: 24,
  },
  subtitle2: {
    fontSize: 14,
    lineHeight: 24,
  },
  button: {
    fontSize: 14,
    lineHeight: 16,
  },
  caption: {
    color: "gray.400",
    fontSize: 12,
    lineHeight: 16,
  },
  overline: {
    fontSize: 10,
    lineHeight: 14,
    fontWeight: "medium",
  },
  link: {
    fontSize: 17,
    lineHeight: 24,
  },
  error: {
    color: "red.400",
    fontSize: 14,
    lineHeight: 24,
  },
};

export type PresetType = keyof typeof presets;