import { Theme } from "native-base";
import { StyleSheet } from "react-native";

export const getSupprtIconButtonStyles = (theme: Theme) => {
  const { colors, space } = theme;
  return StyleSheet.create({
    iconContainer: {
      height: 64,
      widht: 40,
      alignItems: "center",
      marginBottom: 8
    },
  });
};
