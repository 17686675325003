import { NavigationHeader } from "@huum/components/molecules/header/navigation-header";
import { dropRight, last, size } from "lodash";
import { Box, Center, Stack } from "native-base";
import { useCallback, useState } from "react";
import { Control, SubmitHandler, useForm, UseFormWatch } from "react-hook-form";
import { AvoidSoftInputView } from "react-native-avoid-softinput";
import BrandLogo from "../brand-logo";
import { goBack } from "@huum/services/NavigationService";

interface FlowContainerProps {
  components: {
    [key: string]: (React.JSXElementConstructor<any>)
  },
  initialView: string;
  canGoBack?: boolean;
}

export interface FlowComponentProps {
  setViewComponent: (key: string) => void;
  control: Control;
  handleSubmit: SubmitHandler<any>;
  watch: UseFormWatch<any>;
}


const FlowContainer = (props: FlowContainerProps) => {
  const { components, initialView, canGoBack } = props;
  const [componentKey, setComponentKey] = useState<string>(initialView);
  const [componentStack, setComponentStack] = useState<string[]>([]);

  const { control, handleSubmit, watch } = useForm();
  
  const Component = components?.[componentKey || initialView] || Box;

  const handleSetComponentKey = useCallback(
    (key: string) => {
      setComponentStack((stack) => [...stack, componentKey])
      setComponentKey(key)
    },
    [componentKey],
  )

  const handleBackPress = useCallback(() => {
    if (componentStack.length === 0) goBack();
    setComponentStack(stack => dropRight(stack));
    setComponentKey(last(componentStack) || initialView)
  }, [componentStack])
  

  return (
    <Stack
      p={8}
      flex={1}
    >
      <NavigationHeader
        isRoute={false}
        onBackPress={handleBackPress}
        withBackNavigation={canGoBack || (size(componentStack) > 0)}
      />
      <AvoidSoftInputView
        style={{ flex: 1}}
        avoidOffset={8}
      >
        <Center
          flex={1}
        >
          <BrandLogo size="medium" />
          <Component
            control={control}
            handleSubmit={handleSubmit}
            setViewComponent={handleSetComponentKey}
            watch={watch}
          />
        </Center>
      </AvoidSoftInputView>
    </Stack>
    
  )
}

export default FlowContainer;