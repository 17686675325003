import { palette } from '@huum/theme/helpers';
import { StyleSheet } from "react-native";
import { Theme } from "native-base";


const HEIGHT = 44;
const SMALLER_HEIGHT = 38;

export const getCalendarStyles = (theme: Theme) => {
  const { colors, space, shadows } = theme;
  return StyleSheet.create({
    weekCalendarWeek: {
      height: 64,
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
    },
    monthCalendarWeek: {
      justifyContent: "space-between",
      flexDirection: "row",
    },
    weekCalendarDay: {
      height: HEIGHT,
      width: HEIGHT,
      marginHorizontal: 4,
      justifyContent: "center",
      alignItems: "center",
    },
    monthCalendarDay: {
      height: SMALLER_HEIGHT,
      width: SMALLER_HEIGHT,
      marginHorizontal: 4,
      justifyContent: "center",
      alignItems: "center",
    },
    weekCalendarDaySelected: {
      height: HEIGHT,
      width: HEIGHT,
      marginHorizontal: 4,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: HEIGHT / 2,
      backgroundColor: colors.white[100],
      ...shadows[6],
    },
    montCalendarDaySelected: {
      height: SMALLER_HEIGHT,
      width: SMALLER_HEIGHT,
      lineHeight: 18,
      marginHorizontal: 4,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: SMALLER_HEIGHT / 2,
      backgroundColor: colors.white[100],
      ...shadows[6],
    },
    selectedText: {
      fontSize: 10,
      lineHeight: 16,
      color: palette.dimmedText,
    },
    activityIndicator: {
      height: 5,
      width: 5,
      borderRadius: 3,
      position: "absolute",
      bottom: 10,
    },
    disabledDay: {
      opacity: 0.5,
    },
    monthText: {
      fontSize: 10,
      lineHeight: 16,
      color: palette.dimmedText,
    },
    calendarDayText: {
      lineHeight: 18,
      fontWeight: "700",
    },
    sessionNumber: {
      fontSize: 8,
      lineHeight: 8,
      color: palette.invalid,
      position: "absolute",
      bottom: 2
    },
  });
};
