import { View } from "native-base";
import GradientPath from "react-native-svg-path-gradient";
import { ARC_PATH, ControllerArc } from "./empty-arc";
import Svg, { Text as SvgText } from "react-native-svg";

interface ControllerGradientProps {
  visible?: boolean;
  temperature: number;
  temperaturePercentage: number;
  size: number;
  monotone?: boolean;
  temperatureColor?: string;
}

const ControllerGradientPath = (props: ControllerGradientProps) => {
  const {
    visible = true,
    temperature,
    temperaturePercentage,
    size,
    monotone,
    temperatureColor,
  } = props;
  if (!visible) return null;

  const min = 0;

  if (temperature <= min) {
    return null
  }

  const colors = monotone ? [temperatureColor] : [
    'rgb(13, 80, 99)',
    'rgb(213, 50, 98)',
  ]

  return (
    <View position="absolute" width={size} height={size} zIndex={2}>
      <Svg viewBox={'0 0 294 294'}>
        <GradientPath
          d={ARC_PATH}
          colors={colors}
          strokeWidth={16}
          roundedCorners={true}
          percent={temperaturePercentage}
        />
      </Svg>
    </View>
  )
}

export default ControllerGradientPath;