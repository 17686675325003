import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getStatisticsStyles } from "./styles";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { useStatistics } from "@huum/hooks/api/useStatistics";
import { getSessionsCount, selectStatisticsData } from "@huum/utils/helpers/statistics-selectors";
import formatDegreeStatistic from "@huum/utils/helpers/degree-statistics-selectors";
import formatRawStatistic from "@huum/utils/helpers/raw-statistic-selector";
import StatisticsLineGraph from "@huum/components/organisms/statistics-line-graph";
import StatisticsBarGraph from "@huum/components/organisms/statistics-bar-graph";
import StatisticsCalendarGraph from "@huum/components/organisms/statistics-calendar-graph";
import Loader from "@huum/components/organisms/loader";
import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Tabs } from "@huum/components/molecules/tabs";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { Box, Row, ScrollView } from "native-base";
import { get, map } from "lodash";
import moment from "moment";


const StatisticsView = () => {
  const [tabIndex, setIndex] = useState(0);
  const [selectedDate, setDate] = useState(moment().format("YYYY-MM-DD"));
  const { sauna } = useStores();

  const { getStatistics, statisticsResults, isSuccess, isLoading } = useStatistics();

  const temperatureUnit = get(sauna, "temperatureUnit", "");

  const styles = useThemedStyles(getStatisticsStyles);

  useEffect(() => {
    getStatistics({ month: moment().format("YYYY-MM") });
  }, [tabIndex]);

  const getSessionCount = useMemo(() => {
    const data = getSessionsCount(statisticsResults, selectedDate);
    return data
  }, [statisticsResults, selectedDate]);

  const dailySessions = get(getSessionCount, "dailySessions");
  const weeklySessions = get(getSessionCount, "weeklySessions");
  const monthlySessions = get(getSessionCount, "monthlySessions");

  const goToDayView = useCallback((value: any) => {
    setDate(value);
    setIndex(0);
  }, []);

  const renderStatsComponent = useMemo(() => {
    if (tabIndex === 0)
      return (
        <StatisticsLineGraph
          temperatureUnit={temperatureUnit}
          statistics={statisticsResults}
          selectedDate={selectedDate}
        />
      );
    else if (tabIndex === 1) return (
      <StatisticsBarGraph
        selectedDate={selectedDate}
        statistics={statisticsResults}
        temperatureUnit={temperatureUnit}
      />
    );
    else return (
      <StatisticsCalendarGraph
        goToDayView={goToDayView}
        selectedDate={selectedDate}
        statistics={statisticsResults}
      />
    );
  }, [temperatureUnit, statisticsResults, selectedDate, tabIndex]);


  const tabsList = useMemo(() => {
    return [
      {
        title: translate("button.day"),
        onPress: () => setIndex(0),
        isFocused: tabIndex === 0,
      },
      {
        title: translate("button.week"),
        onPress: () => setIndex(1),
        isFocused: tabIndex === 1,
      },
      {
        title: translate("button.month"),
        onPress: () => setIndex(2),
        isFocused: tabIndex === 2,
      },
    ];
  }, [tabIndex]);


  const AverageItem = useCallback(
    (item: any) => {
      const { measure, results, unit } = item;
      const tempunit = temperatureUnit === "celsius" ? "°C" : "°F";

      const rawResult = results[tabIndex] || 0;
      const result =
        unit === "degrees"
          ? formatDegreeStatistic(rawResult, temperatureUnit)
          : formatRawStatistic(rawResult);

      return (
        <Box style={styles.averageItemContainer}>
          <Box 
            flexDir={"row"}
            alignItems={"center"}
            >
            <Text preset="subtitle1" fontWeight="700">
              {result}
            </Text>
            {unit && (
              <Text preset="subtitle1" fontWeight="700">
                {tempunit}
              </Text>
            )}
          </Box>
          <Text style={styles.infoText}>{measure}</Text>
        </Box>
      );
    },
    [tabIndex]
  );

  const AveragesSection = useCallback(() => {
    const data = selectStatisticsData(statisticsResults, moment(selectedDate));
    return (
      <Box flexDirection="row" flexWrap="wrap">
        {map(data, (item) => (
          <AverageItem {...item} />
        ))}
      </Box>
    );
  }, [statisticsResults, selectedDate, tabIndex]);

  const handleDateChange = useCallback(
    (direction: number) => () => {
      if(tabIndex === 0) {
        const chosenDay =
        direction > 0
          ? moment(selectedDate).add(1, "day").format("YYYY-MM-DD")
          : moment(selectedDate).subtract(1, "day").format("YYYY-MM-DD");

      setDate(chosenDay);

      if (moment(chosenDay).month() !== moment(selectedDate).month()) {
        getStatistics({ month: chosenDay });
      }
    } else if(tabIndex === 1) {
      const chosenWeek = direction > 0
      ? moment(selectedDate).add(1, 'week').endOf('week').format('YYYY-MM-DD')
      : moment(selectedDate).subtract(1, 'week').startOf('week').format('YYYY-MM-DD');

      setDate(chosenWeek);

    if (moment(chosenWeek).month() !== moment(selectedDate).month()) {

       getStatistics({ month: chosenWeek });
    }
    } else if( tabIndex === 2 ) {
      const chosenMonth = direction > 0
      ? moment(selectedDate).add(1, 'month').startOf('month').format('YYYY-MM-DD')
      : moment(selectedDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

      setDate(chosenMonth);

    if (moment(chosenMonth).month() !== moment(selectedDate).month()) {
      getStatistics({ month: chosenMonth });
    }
    }
  }, [selectedDate, tabIndex]);

  const displayDates = useMemo(
    () => {
      if (tabIndex === 0) return moment(selectedDate).format("D MMMM");
      else if (tabIndex === 1) {
        const firstDayOfWeek = moment(selectedDate).startOf("week");
        const lastDayOfWeek = moment(selectedDate).endOf("week");

        const firstDayMonth = moment(firstDayOfWeek).format("MMMM");
        const lastDayMonth = moment(lastDayOfWeek).format("MMMM");
        const firstDayDate = moment(firstDayOfWeek).date();
        const lastDayDate = moment(lastDayOfWeek).date();

        if (moment(firstDayOfWeek).month() === moment(lastDayOfWeek).month()) {
          return `${firstDayMonth} ${firstDayDate} – ${lastDayDate}`;
        }

        return `${firstDayDate} ${firstDayMonth} – ${lastDayDate} ${lastDayMonth}`;
      } else if (tabIndex === 2) {
        const currentMonth = moment(selectedDate).format("MMMM");
        const currentYear = moment(selectedDate).format("YYYY");

        return `${currentMonth}, ${currentYear}`;
      }
    },
    [selectedDate, tabIndex]
  );

  const displaySessionCount = useCallback(
    (index: number) => {
      if (index === 0) return dailySessions;
      else if (index === 1) return weeklySessions;
      else if (index === 2) return monthlySessions;
    },
    [tabIndex, dailySessions, weeklySessions]
  ); 

  const DateSelector = useCallback(() => {
    return (
      <Row h={16} style={styles.selectorContainer}>
        <Touchable onPress={handleDateChange(-1)}>
          <Icon icon="large-left" size={8} />
        </Touchable>
        <Box flexDir={"column"} alignItems={"center"}>
          <Text preset="subtitle2" fontWeight="700">
            {displayDates}
          </Text>
          <Text style={styles.sessionText}>{`${displaySessionCount(
            tabIndex
          )} ${translate("label.sessions")}`}</Text>
        </Box>
        <Touchable onPress={handleDateChange(1)}>
          <Icon icon="large-right" size={8} />
        </Touchable>
      </Row>
    );
  }, [selectedDate, dailySessions, weeklySessions, tabIndex]);


  return (
    <>
      <MainHeader withBackNavigation title={translate("button.statistics")} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.scrollContainer}
      >
        {renderStatsComponent}
        <Box marginX={8} mt={10} pb={8}>
          <AveragesSection />
          <Box mt={3} />
          <DateSelector />
        </Box>
      </ScrollView>
      <Tabs tabs={tabsList} />
      <Loader isLoading={isLoading} minimumDuration={500} />
    </>
  );
};

export default StatisticsView;
