import React, { useState } from "react";
import { Image, View } from "react-native";
import { includes } from "lodash";
import { Switch } from "@huum/components/molecules/switch";
import { Box, Row, useTheme } from "native-base";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { translate } from "@huum/i18n";
import { Text } from "@huum/components/atoms/text";

const SaunaLightController = (props: any) => {
  const { setSaunaLightStatus, config, lightStatus, statusCode } = props;
  const [isToggled, setIsToggled] = useState<boolean>(!!lightStatus)
  const { colors } = useTheme();

  const isFeatureDisabled =
    !config ||
    !includes([2, 3], config) ||
    includes(
      [
        SaunaStatusCode.SAUNA_OFFLINE,
        SaunaStatusCode.SAUNA_ACTIVE_BLOCKED,
        SaunaStatusCode.SAUNA_EMERGENCY_STOP,
      ],
      statusCode
    );

  const handleLightToggle = (value) => {
    setIsToggled(value)
    setSaunaLightStatus && setSaunaLightStatus(value);
  };

  if (isFeatureDisabled) {
    return (
      <Box p={4}>
        <Text >{translate('error.functionality_not_available')}</Text>
      </Box>
    )
  }

  return (
    <Row
      space={2}
      opacity={isFeatureDisabled ? 0.5 : 1}
      px={8}
      py={4}
    >
      <Image
        source={require("@huum/assets/legacy/lights_icon.png")}
        style={{ tintColor: colors.primaryDark, height: 30, width: 30 }}
      />
      <Switch
        disabled={isFeatureDisabled}
        onToggle={handleLightToggle}
        value={isToggled}
      />
    </Row>
  );
};

export default SaunaLightController;
