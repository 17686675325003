import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "@huum/components/atoms/button";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { Box, Row, ScrollView, Stack } from "native-base";
import TimeSelect from ".";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "@huum/components/views/modals/modal-container";
import { Pressable } from "react-native";
import { map, max } from "lodash";
import moment from "moment";
import { getTimerValues } from "@huum/utils/helpers/timer-helpers";
import { getHitSlop } from "@huum/utils/hitSlop";

interface TimeSelectValues {
  minute: number;
  hour: number;
}

enum TimeSelectTypes {
  start = "start",
  duration = "duration",
  end = "end",
}

interface TimerSelectState {
  [TimeSelectTypes.start]: TimeSelectValues;
  [TimeSelectTypes.end]: TimeSelectValues;
  [TimeSelectTypes.duration]: TimeSelectValues;
}




const TimeSelectModal = (props: any) => {
  const { modal } = useStores();
  const { closeModal } = modal;

  const {
    title,
    maxHour = 3,
    startTime,
    endTime,
    onValueSelect,
    buttonLabel
  } = props;

  const initialStart = moment(startTime || moment());
  const initialEnd = moment(endTime || moment().add(maxHour, "hours"));
  const initialDuration = moment.duration(initialEnd.diff(initialStart));

  const initialTimeValues = {
    [TimeSelectTypes.start]: { hour: initialStart.hours(), minute: initialStart.minutes() },
    [TimeSelectTypes.end]: { hour: initialEnd.hours(), minute: initialEnd.minutes() },
    [TimeSelectTypes.duration]: { hour: initialDuration.hours(), minute: initialDuration.minutes() },
  } as TimerSelectState;

  const [timeValues, setTimeValues] = useState<TimerSelectState>(initialTimeValues);

  const [timeSelectType, setTimeSelectType] = useState<"start" | "end" | "duration">(TimeSelectTypes.start);

  const handleTimeValuesChange = useCallback((type: TimeSelectTypes) => (values: TimeSelectValues) => {
    
    setTimeValues(current => getTimerValues({
      ...current,
      date: initialStart.format(),
      maxDurationHours: maxHour,
    })(type, values));
  }, [initialStart]);

  const handleTimeSave = useCallback(() => {
    closeModal();
    onValueSelect(timeValues);
  }, [timeValues, onValueSelect]);

  const TimeSelectTabs = useCallback(() => {

    return (
      <Row justifyContent="center" space={8}>
        {Object.values(TimeSelectTypes).map((type) => {
          return (
            <Pressable
              key={type}
              hitSlop={getHitSlop(8)}
              onPress={() => setTimeSelectType(type)}
            >
              <Text bold={timeSelectType === type}>{translate(`button.${type}`)}</Text>
            </Pressable>
          )
        })}
      </Row>
    )
  }, [timeSelectType])

  return (
    <ModalContainer title={title} closeModal={closeModal}>
      <Stack flexGrow={1} justifyContent="space-between" space={8}>
        <TimeSelectTabs />
          <Row>
            {
              map(Object.values(TimeSelectTypes), (type) => (
                <TimeSelect
                  key={type}
                  hourValue={timeValues[type].hour}
                  minuteValue={timeValues[type].minute}
                  durationSelect={type === TimeSelectTypes.duration}
                  onValueSelect={handleTimeValuesChange(type)}
                  visible={timeSelectType === type}
                  maxHour={maxHour + 1}
                  animated={false}
                />
              ))
            }
          </Row>
        <Button mt={8} onPress={handleTimeSave} label={buttonLabel || translate("button.start_timer")} />
      </Stack>
    </ModalContainer>
  );
};

export default TimeSelectModal;
