import { Animated } from "react-native";
import {
  DEFAULT_CONTROLLER_WIDTH,
  MAX_TEMPERATURE,
  CONTROLLER_COLORS,
  pickHexColor,
} from "./helpers";
import React, { useCallback, useMemo } from "react";
import { useControllerPanResponder } from "./hooks";
import { Box, Image, Row, Stack, View, useTheme } from "native-base";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Text } from "@huum/components/atoms/text";
import SaunaController from ".";
import moment from "moment";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { Icon } from "@huum/components/atoms/icon";
import { map } from "lodash";
import { SaunaStatusResponse } from "@huum/api/requests/sauna";

interface PowerLevelControllerControllerProps extends SaunaStatusResponse {
  width?: number;
  topOffset?: number;
  temperature: number;
  targetTemperature: number;
  handleSaunaStartPress?: () => void;
  handleSaunaStopPress?: () => void;
  onTemperatureChange: (temperature: number) => void;
  isHeating?: boolean,
}

const PowerLevelController = (props: PowerLevelControllerControllerProps) => {
  const {
    width: customWidth,
    temperature,
    targetTemperature,
    handleSaunaStartPress,
    handleSaunaStopPress,
    onTemperatureChange,
    topOffset = 0,
    isHeating,
  } = props;
  const width = customWidth || DEFAULT_CONTROLLER_WIDTH;

  const {
    panResponder,
    position,
    controllerTargetTemperature,
    handleControllerLayout,
    getStepsCoordinates,
  } = useControllerPanResponder({
    width,
    minTemperature: 0,
    maxTemperature: 110,
    targetTemperature,
    onTemperatureChange,
    mode: "power",
    topOffset
  });

  const { colors } = useTheme();

  const visibleTemperature = isHeating
    ? temperature
    : controllerTargetTemperature;

  const temperaturePercentage = visibleTemperature / MAX_TEMPERATURE;

  const visiblePowerValue = Math.round(visibleTemperature / 10)

  const temperatureColor = isHeating ? colors.red[600] : colors.primaryLight;

  const handleControllerPress = () => {
    if (isHeating) {
      handleSaunaStopPress();
    } else {
      handleSaunaStartPress();
    }
  };

  const renderControllerSteps = () => {
    const stepDotSize = width > 300 ? 22 : 16
    const correction = width > 300 ? 24 : 17;
    const steps = getStepsCoordinates(11, correction);

    
    return <>
     {
      map(steps, (step) => (
        <Box
          zIndex={1}
          position="absolute"
          left={step.position.x}
          top={step.position.y}
          width={`${stepDotSize}px`}
          height={`${stepDotSize}px`}
          borderRadius={16}
          background="#D3E2ED"
        />
      ))}
    </>
  };

  const renderInnerCircleContent = useCallback(({ size }: { size: number}) => {
    const fontSize = size > 200 ? "6xl" : "4xl";
    return (
      <Stack space={2} alignItems="center">
        <Row space={1} mt={2} alignItems="baseline">
          <Text
            fontSize={fontSize}
            lineHeight="xs"
            fontWeight="semibold"
            color={isHeating ? "#CA4E69" : undefined}
          >
            {visiblePowerValue}
          </Text>
          <Text color={isHeating ? "#CA4E69" : undefined}>/</Text>
          <Text color={isHeating ? "#CA4E69" : undefined}>
            11
          </Text>
        </Row>
        <Text color={isHeating ? "#CA4E69" : undefined} fontWeight="semibold" fontSize="xs">{"Power"}</Text>
        <Icon
          icon="heating2"
          tint={isHeating ? "#CA4E69" : "gray.400"}
        />
      </Stack>
    );
  }, [isHeating, visibleTemperature]);

  return (
    <SaunaController
      width={width}
      onLayout={handleControllerLayout}
      temperature={visibleTemperature}
      temperaturePercentage={temperaturePercentage}
      position={position}
      panResponder={panResponder}
      targetTemperature={controllerTargetTemperature}
      InnerCircleContent={renderInnerCircleContent}
      ExtraContent={renderControllerSteps}
      temperatureColor={temperatureColor}
      monotone
      onPress={handleControllerPress}
      controllerCircleText={visiblePowerValue.toString()}
    />
  );
};

export default PowerLevelController;
