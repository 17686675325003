//@ts-nocheck

import React from 'react';
import { NavigationContainerRef, StackActions } from '@react-navigation/native';
import { MainNavigatorParamList } from '@huum/navigation/main';

export const navigationRef = React.createRef<NavigationContainerRef<MainNavigatorParamList>>();

type mainRouteName = keyof MainNavigatorParamList

export function navigate(name: mainRouteName, params?: any) {
  navigationRef.current?.navigate(name, params);
}

// export function navigateNested(rootScreen: mainRouteName, screen: nestedRouteName, params?: any) {
//   navigate(rootScreen, {
//     screen,
//     params,
//   })
// }

export function replaceStack(screen, params?:any) {
  navigationRef.current?.dispatch(StackActions.replace(screen, params))
}

export function resetNavigation() {
  navigationRef.current.dispatch(StackActions.popToTop())
}

export function goBack() {
  navigationRef.current?.goBack();
}