import { DefaultTheme } from '@react-navigation/native';
import { extendTheme } from 'native-base';
import { gradients } from './helpers';
import { components, shadows } from './helpers/styles';
import huumConf from "./huum";
import ikiConf from "./iki";
import Config from 'react-native-config';

const themesConfigs = {
  huum: huumConf,
  iki: ikiConf,
}

const { colors, config } = themesConfigs[Config.CONFIG as keyof typeof themesConfigs || "huum"]

export const theme = extendTheme({
  colors,
  gradients,
  shadows,
  components: components(colors),
  config
});

export const themeConfig = {
  dependencies: {
    'linear-gradient': require('react-native-linear-gradient').default,
  },
};

// 2. Get the type of the CustomTheme
type CustomThemeType = typeof theme;

// 3. Extend the internal NativeBase Theme
declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}

export const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: colors.background
  },
};