import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Dimensions, Platform } from "react-native";
import { isTablet } from 'react-native-device-info';
import SaunaBlockedSection from "../sauna-blocked-section";
import Loader from "@huum/components/organisms/loader";
import TemperatureController from "@huum/components/organisms/sauna-controller/temperature-controller";
import PowerLevelController from "@huum/components/organisms/sauna-controller/power-level-controller";
import { toNumber } from "lodash";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";

const { width: SCREEN_WIDTH } = Dimensions.get('screen');

const SaunaController = (props: any) => {
  const {
    controllerTargetTemperature,
    onStartSauna,
    onStopSauna,
    onSaunaTemperatureChange,
    isHeating,
    saunaId,
    width: customWidth
  } = props;

  const widthNumber = customWidth || Math.min(SCREEN_WIDTH - 40, 400);
  const controllerWidth = isTablet() ? widthNumber * 0.7 : widthNumber;

 const saunaStatus = useSaunaStatus();
 const{ statusCode, targetTemperature, temperature: heaterTemperature } = saunaStatus;

  const controllerTemperature = targetTemperature || controllerTargetTemperature;

  if (!saunaId || !statusCode) return <Loader />;

  const controllerType = toNumber(saunaId) >= 500000 ? "power" : "temperature";

  if (
    saunaStatus.statusCode === SaunaStatusCode.SAUNA_OFFLINE
    || saunaStatus.statusCode === SaunaStatusCode.SAUNA_EMERGENCY_STOP
  ) {
    return <SaunaBlockedSection statusCode={saunaStatus.statusCode} />
  }

  const controllerProps = {
    temperature: isHeating ? heaterTemperature : controllerTargetTemperature,
    handleSaunaStartPress: onStartSauna,
    handleSaunaStopPress: onStopSauna,
    onTemperatureChange: onSaunaTemperatureChange,
    targetTemperature: controllerTemperature,
    width: controllerWidth,
    topOffset: 0,
    isHeating: isHeating,
  }

  if (controllerType === "power") {
    return (
      <PowerLevelController
       {...controllerProps}
      />
    )
  }

  return (
    <TemperatureController
      {...controllerProps}
    />
  )
};

export default SaunaController;