import { FlowComponentProps } from "@huum/components/organisms/flow-container";
import { useStores } from "@huum/store";
import React, { useCallback, useState } from "react";
import FreeRemoteControlView from "./free-remote-control";
import WifiRemoteControlSuccessView from "./wifi-remote-control";
import { toNumber } from "lodash";
import { navigate } from "@huum/services/NavigationService";
import { translate } from "@huum/i18n";
import { observer } from "mobx-react-lite";

const SaunaActivatedView = observer(() => {
  const { sauna } = useStores();
  const saunaId = toNumber(sauna.saunaId);
  const [showGsmView, setShowGsmView] = useState(false);

  const isGsm = saunaId > 120000 && saunaId < 200000;


  const handleContinue = useCallback(() => {
    if (isGsm && !showGsmView) {
      setShowGsmView(true);
    } else {
      navigate("controller");
    }
  }, [showGsmView, isGsm]);

  if (showGsmView && isGsm) {
   return <FreeRemoteControlView onContinue={handleContinue} />;
  }

  return (
    <WifiRemoteControlSuccessView
      label={isGsm ? translate("button.next") : translate("button.start")}
      onContinue={handleContinue}
    />
  );
})

export default SaunaActivatedView;
