import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { selectMonthlySessions } from '@huum/utils/helpers/statistics-selectors';
import MonthCalendar from '../calendar/month-calendar';
import { Box } from 'native-base';
import { StatisticsGraphProps } from '../statistics-line-graph';


interface StatisticsCalendarGraph extends StatisticsGraphProps {
  goToDayView: (value: string) => void;
}

const StatisticsCalendarGraph = (props: StatisticsCalendarGraph) => {
  const { goToDayView, selectedDate, statistics } = props;

  const montlySessionsData = useMemo(() => {
    const sessions = selectMonthlySessions(statistics, selectedDate);
    return sessions;
  }, [statistics, selectedDate]);

  const { dailySessionCount } = montlySessionsData;

  const setStatisticsDate = useCallback((day: string) => {
    goToDayView(day);
  }, []);

  return (
    <Box mt={10}>
      <MonthCalendar
        visible
        limitPastDates={0}
        limitFutureDates={0}
        activeMonth={moment(selectedDate).format("YYYY-MM-DD")}
        sessionCount={dailySessionCount}
        selectedDate={selectedDate}
        onDateSelect={setStatisticsDate}
      />
    </Box>
  );
};

export default StatisticsCalendarGraph;
