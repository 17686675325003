import React from "react";
import { Insets } from "react-native";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";
import { TextProps } from "@huum/components/atoms/text/types";
import { PresetType } from "@huum/components/atoms/text/preset";
import { palette } from "@huum/theme/helpers";


interface TextButtonProps extends TextProps {
  title: string;
  preset?: PresetType;
  onPress?: () => void;
  hitSlop?: null | Insets | number | undefined;
  extraTitle?: string;
}

const TextButton = (props: TextButtonProps) => {
  const { title, extraTitle, preset, onPress, hitSlop, ...rest } = props;

  return (
    <Touchable hitSlop={hitSlop} onPress={onPress}>
      <Text preset={preset} {...rest}>
        {title}{" "}
        <Text preset={preset} style={{ color: palette.invalid }}>
          {extraTitle}
        </Text>
      </Text>
    </Touchable>
  );
};

export default TextButton;
