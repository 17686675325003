//@ts-nocheck

import moment from 'moment';
import { map, forEach, size } from 'lodash';
import { timeDiff } from './statistics-selectors';


const splitReadingsIntoSessions = (readings) => {
  const sessions = [];
  let currentSession = [];
  let heatingTime = 0; // seconds;

  const length = size(readings);

  const pushSession = (session, currentHeatingCode, reading, heatingSeconds) => {
    sessions.push({
      heating: currentHeatingCode === '1',
      readings: session,
      target: parseInt(reading.target, 10),
      heatingTime: timeDiff(heatingSeconds * 1000),
    });
  };

  forEach(readings, (reading, i) => {
    const nextReading = i < length - 1 ? readings[i + 1] : {};

    // if we have a null instead of '0', interpret as '0'
    const currentHeatingCode = reading.isHeating ? reading.isHeating : '0';
    const nextHeatingCode = nextReading.isHeating ? nextReading.isHeating : '0';

    const currentTarget = reading.target ? reading.target : '0';
    const nextTarget = nextReading.target ? nextReading.target : '0';

    if (i === readings.length - 1) {
      // we've reached the end of readings, so add a session
      currentSession.push(reading);
      pushSession(currentSession, currentHeatingCode, reading, heatingTime);
      heatingTime = 0;
    } else if (currentHeatingCode === nextHeatingCode && currentTarget === nextTarget) {
      // we're in the middle of a session
      currentSession.push(reading);

      if (reading.temperature < currentTarget && nextHeatingCode === '1') {
        heatingTime += moment(nextReading.time).diff(reading.time, 'seconds');
      }
    } else if (currentHeatingCode === nextHeatingCode && !(currentTarget === nextTarget)) {
      // the target changed so we're in a new session
      currentSession.push(reading);
      pushSession(currentSession, currentHeatingCode, reading, heatingTime);

      currentSession = [reading];
      heatingTime = 0;

      if (reading.temperature < currentTarget && nextHeatingCode === '1') {
        heatingTime += moment(nextReading.time).diff(reading.time, 'seconds');
      }
    } else {
      // the heating code changed, so add a session
      currentSession.push(reading);
      pushSession(currentSession, currentHeatingCode, reading, heatingTime);

      currentSession = [];
      heatingTime = 0;
    }
  });

  return sessions;
};

const getLineGraphValues = (readingsData) => {
  const startOfDay = moment(readingsData[0].time).startOf('day');
  const endOfDay = moment(readingsData[readingsData.length - 1].time).endOf('day');

  const year = moment(startOfDay).year();
  const month = moment(startOfDay).month();
  const day = moment(startOfDay).date();

  // times from midnight to midnight
  const xAxisTimes = map([...Array(5).keys()], (key) => new Date(year, month, day, key * 6));

  const sessionsData = splitReadingsIntoSessions(readingsData);

  return {
    startOfDay,
    endOfDay,
    xAxisTimes,
    sessionsData,
  };
};

export default getLineGraphValues;
