import {
  DEFAULT_CONTROLLER_WIDTH,
} from "./helpers";
import React, { useCallback } from "react";
import { Stack } from "native-base";
import { Text } from "@huum/components/atoms/text";
import SaunaController from ".";
import { Icon } from "@huum/components/atoms/icon";
import { translate } from "@huum/i18n";
import { navigate } from "@huum/services/NavigationService";

interface NewSaunaEmptyControllerProps {
  width?: number;
}

const NewSaunaEmptyController = (props: NewSaunaEmptyControllerProps) => {
  const {
    width: customWidth,
  } = props;
  const width = customWidth || DEFAULT_CONTROLLER_WIDTH;


  const handleControllerPress = () => {
    navigate('saunaConnect')
  };

  const renderInnerCircleContent = useCallback(() => {
    return (
      <Stack space={2} alignItems="center">
        <Icon
          icon="plus"
          tint="primaryDark"
          size={10}
        />
        <Text>{translate("label.add_new_sauna")}</Text>
      </Stack>
    );
  }, []);

  return (
    <SaunaController
      width={width}
      temperature={0}
      temperaturePercentage={0}
      InnerCircleContent={renderInnerCircleContent}
      monotone
      onPress={handleControllerPress}
    />
  );
};

export default NewSaunaEmptyController;
