import { map } from 'lodash';
import { Radio as BaseRadio, IRadioProps } from 'native-base';

interface Option {
  value: string;
  label: string;
}

interface RadioProps extends Omit<IRadioProps, 'value'> {
  options: Option[];
  onChange: (value: string) => void;
  value: string;
}

const Radio = (props: RadioProps) => {
  const { options, onChange, value, ...rest } = props;
  
  return (
    <BaseRadio.Group space={4} name="" value={value} onChange={onChange} {...rest}>
      {map(options, (option) => (
        <BaseRadio value={option.value}>{option.label}</BaseRadio>
      ))}
    </BaseRadio.Group>
  );
}

export default Radio;