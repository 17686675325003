
import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
import messaging from "@react-native-firebase/messaging";
import { Platform } from "react-native";

export interface UserEmailAuthBody {
  username: string;
  password: string;
  version?: number;
  config?: string;
}

interface EmailRegisterBody {
  email: string;
  password: string;
}

export interface UserInfo {
  username: string
  email: string
  firstName: string
  lastName: string
  country: string
  city: string
  zipCode: string
  phoneNumber: string
}

interface PasswordRecover {
  token: string;
  username: string;
  password: string;
}

export interface RegisterParams {
  name: string;
  email: string;
  password: string;
  phone: string;
}

export interface UserDeleteData {
  version: number;
  id: number;
  password: string;
  name: string;
  email: string;
  deleted_at: string;
  updated_at: string;
  created_at: string;
  customer_id: number;
}

export async function loginUserWithEmail(data: UserEmailAuthBody) {
    const response = await api.huum.get("/action/login", data)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw problem
    }

    return response.data
}

export async function registerUserWithEmail(data: EmailRegisterBody) {
    const response = await api.huum.get("/user/register", data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw problem
    }

    return response.data
}


export async function getUserInfo(username: string) {
  const response = await api.huum.get("/user/info", {
    username,
  })

  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) throw problem
  }

  return response.data as UserInfo
}

export async function submitUserInfo(data: UserInfo) {
  const response = await api.huum.post("/user/info", data)

  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) throw problem
  }

  return response.data as any;
}

export async function passwordForgot(username: string) {
  const response = await api.huum.post("/user/password/forgot", {}, 
  { params: { username } } 
  );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) throw problem
  }

  return response.data as any;
}

export async function passwordChange(data: PasswordRecover) {
  const { username, password, token } = data;
  const response = await api.huum.post("/user/password/recover", 
  {username, password}, 
  { params: { password_recovery_key: token } } 
  );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response)
    if (problem) throw problem
  }

  return response.data as any;
}


export async function register(data: RegisterParams) {
  const response = await api.huum.post("/action/register", {
    version: 3,
    ...data,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}


export async function fcmTokenInitiation(session: string) {
  if (Platform.OS === "web") return;
  const permissionStatus = await messaging().hasPermission();
  if (permissionStatus === messaging.AuthorizationStatus.NOT_DETERMINED) {
    await messaging().requestPermission();
  }
  const fcmToken = await messaging().getToken();

  const response = await api.huum.post("/action/save_fcm_token", {
    session,
    fcmToken,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}


export async function resetPassword(email: string) {
  const response = await api.huum.get("/action/forgot_password", {
    email,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}


export async function deleteAccount({ session }: { session: string}) {
  const response = await api.huum.get("/action/user_delete", {
    session,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as UserDeleteData;
}
