import React, { useCallback, useState } from "react";
import { Row, Stack } from "native-base";
import { Switch } from "../switch";
import { Text } from "@huum/components/atoms/text";
import InfoLabel from "../info-label";

interface ToggleSectionProps {
  isToggled: boolean;
  onToggle?: (state: boolean) => void;
  disabled?: boolean;
  label?: string;
  infoText?: string;
  infoHeading?: string;
  ExtraComponent?: (props: ToggleSectionProps) => React.ReactElement;
}

const ToggleSection = (props: ToggleSectionProps) => {
  const {
    isToggled,
    onToggle,
    disabled,
    infoText,
    ExtraComponent,
    label,
  } = props;

  const [toggleState, setToggleState] = useState<boolean>(isToggled);

  const handleOnTogglePress = useCallback(
    (state: boolean) => {
      if (disabled) return;
      setToggleState(state);
      onToggle && onToggle(state);
    },
    [onToggle]
  );

  const Label = () => {
    if (!infoText) return (
      <Text>{label}</Text>
    );

    return (
      <InfoLabel label={label || ""} infoText={infoText} />
    )
  };

  const Content = () => {
    if (!ExtraComponent || !isToggled) return null;
    return <ExtraComponent isToggled={isToggled} disabled={disabled} />;
  };

  return (
    <Stack>
      <Row justifyContent="space-between" alignItems="center">
        <Row>
          <Label />
        </Row>
        <Switch
          value={isToggled}
          onToggle={handleOnTogglePress}
          disabled={disabled}
        />
      </Row>
      <Content />
    </Stack>
  );
};

export default ToggleSection;
