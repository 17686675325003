import { languageChange } from "@huum/api/requests/profile";
import { changeLanguage, TLocale } from "@huum/i18n";
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withEnvironment, withRootStore } from ".."
import api from "../../services/api";
import { navigate } from "../../services/NavigationService";

interface SessionStartParams {
  sessionHash: string,
  accountId?: string,
  paymentDue?: string | null,
  saunaIsPrivate?: boolean,
  settings: any,
  saunaId?: string,
  language?: string,
}

export const AuthStoreModel = types
  .model("AuthStore")
  .props({
    sessionHash: types.maybeNull(types.string),
    accountId: types.maybeNull(types.string),
    saunaId: types.maybeNull(types.string),
    settings: types.maybe(types.frozen()),
    paymentDue: types.maybeNull(types.string),
    saunaIsPrivate: types.optional(types.boolean, true),
    language: types.optional(types.string, "et"),
  })
  .actions((self) => ({
    startSession: ({
      sessionHash,
      accountId,
      settings,
      language,
    }: SessionStartParams) => {
      self.sessionHash = sessionHash;
      if (accountId) {
        self.accountId = accountId;
      }
      self.settings = settings;
      self.language = language || "et";
    },
    setLanguage: (lang: TLocale) => {
      languageChange({ language: lang, session: self.sessionHash });
      changeLanguage(lang);
      self.language = lang;
    },
    setNotificationSettings: (showNotification: any) => {
      self.settings = showNotification;
    },
  }))
  .extend(withEnvironment)
  .extend(withRootStore)
  .actions((self) => ({
    logout: () => {
      api.setAuthToken(undefined);
      self.sessionHash = null;
      self.accountId = null;
      self.saunaId = null;
      self.settings = undefined;
      self.paymentDue = null;
      self.rootStore.sauna.clearSaunaState();
    },
  }));

export type AuthStoreType = Instance<typeof AuthStoreModel>
export interface AuthStore extends AuthStoreType { }
type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>
export interface AuthStoreSnapshot extends AuthStoreSnapshotType { }
export const createAuthStoreDefaultModel = () => types.optional(AuthStoreModel, {})
