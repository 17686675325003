import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";


export async function userSafetyNotificationChange(data: any) {
  const response = await api.huum.post("action/save-settings", {
    ...data,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function getCustomerInfo(data: any) {
  const response = await api.huum.get("payment/get_customer", {
    ...data,
     version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function getClientToken(data: any) {
  const response = await api.huum.get("payment/get_client_token", {
    ...data,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function getPaymentAmount(data: any) {
  const response = await api.huum.get("payment/get_amount", {
    ...data,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function savePaymentNonce(data: any) {
  const response = await api.huum.post("payment/save_payment_nonce", {
    ...data,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}
