import React, { useCallback, useRef} from "react";
import { translate } from "@huum/i18n";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { Box, Row, Stack } from "native-base";
import { useForm } from "react-hook-form";
import { FormInput } from "@huum/components/atoms/form-input";
import { Screen } from "@huum/components/organisms/screen";
import { usePaymentFlow } from "@huum/hooks/api/usePaymentFlow";
import Button from "@huum/components/atoms/button";
import {
  formatCardNumber,
  formatCvvNumber,
  formatExpiryDate,
} from "@huum/utils/helpers/credit-card-from-helpers";
import { useRoute } from "@react-navigation/native";
import { get } from "lodash";


const BillingForm = () => {
  const { control, watch, handleSubmit } = useForm();

  const { isLoading, connectPayment } = usePaymentFlow("payment");

  const { params } = useRoute();

  const expirayDateValue = watch("card.expiry", "");
  const cardNumberValue = watch("card.number", "");
  const ccvValue = watch("card.cvv", "");

  const expiryInput = useRef(null);
  const cvvInput = useRef(null);
  const holderNameInput = useRef(null);

  const handlePayment = useCallback((values: any) => {
    connectPayment({ ...values, ...(get(params, "customer") || {}) });
  }, [params, connectPayment]);

  return (
    <>
      <MainHeader withBackNavigation title={translate("button.payment")} />
      <Screen>
        <Stack space={8} py={8}>
          <FormInput
            name="card.number"
            placeholder={`${translate("label.credit_card_number")}*`}
            keyboardType="number-pad"
            control={control}
            value={formatCardNumber(cardNumberValue, expiryInput) || ""}
          />
          <Row justifyContent={"space-between"}>
            <Box width={"49%"}>
              <FormInput
                ref={expiryInput}
                control={control}
                name="card.expiry"
                placeholder={`${translate("label.credit_card_exp")}*`}
                value={formatExpiryDate(expirayDateValue, cvvInput) || ""}
                autoCapitalize="none"
                keyboardType="number-pad"
              />
            </Box>
            <Box width={"49%"}>
              <FormInput
                ref={cvvInput}
                control={control}
                name="card.cvv"
                placeholder={`${translate("label.credit_card_cvv")}*`}
                keyboardType="number-pad"
                value={formatCvvNumber(ccvValue, cvvInput) || ""}
              />
            </Box>
          </Row>
          <FormInput
            ref={holderNameInput}
            forwardedRef={holderNameInput}
            control={control}
            name="card.holderName"
            placeholder={`${translate("label.credit_card_holder_name")}*`}
            autoCapitalize="words"
          />
          <Button
            isLoading={isLoading}
            label={translate("button.payment")}
            onPress={handleSubmit(handlePayment)}
          />
        </Stack>
      </Screen>
    </>
  );
};

export default BillingForm;
