import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { Actionsheet, Input, Stack } from "native-base";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Effect } from "./effects";
import TextButton from "@huum/components/molecules/text-button";
import { useKeyboard } from "@react-native-community/hooks";


interface EffectConfiguratorProps {
  setEffect: (effect: Effect) => void;
  onCancel: () => void;
  effect?: Effect;
}

const EffectConfigurator = (props: EffectConfiguratorProps) => {
  const { effect, setEffect, onCancel } = props;
  const { control, handleSubmit, reset } = useForm();
  const [error, setError] = useState<string>();
  

  useEffect(() => {
    if (effect?.slot) {
      reset()
    }
  }, [effect?.slot])

  const handleSelectEffect = (values: any) => {
    const { effectData } = values;
    setError(undefined)
    try {
      const config = JSON.parse(effectData);
      setEffect({
        ...effect,
        data: config
      } as Effect)
    } catch (e) {
      setError(`Invalid JSON input:  ${e.message}`)
    }  
  }

  const handleCancel = () => {
    onCancel();
    setError(undefined)
  }

  const { keyboardHeight, keyboardShown } = useKeyboard()
 
  if (!effect) return null;

  return (
    <Actionsheet isOpen={true}>
       <Actionsheet.Content>
        <Stack width="full" paddingBottom={keyboardShown ? keyboardHeight : 4}>
          <Stack p={4} width="full" space={2}>
            <Text>{`Set ${effect?.name} effect raw data`}</Text>
            <Text preset="caption">Only valid JSON is accepted</Text>
            <Controller
              name="effectData"
              control={control}
              render={({ field: { onChange, value }}) => (
                <Input
                  value={value}
                  onChangeText={onChange}
                  multiline
                  height={200}
                />
              )}
            />
            {!!error && (
              <Text color="red.600">{error}</Text>
            )}
          </Stack>
          <Stack width="full" space={4} alignItems={"center"}>
            <Button width="full" onPress={handleSubmit(handleSelectEffect)} label={translate('button.confirm')} />
            <TextButton onPress={handleCancel} title={translate('button.cancel')} />
          </Stack>
        </Stack>
       </Actionsheet.Content>
    </Actionsheet>
  )
}

export default EffectConfigurator;