import React, { useMemo } from 'react';
import { Dimensions } from 'react-native';
import * as scale from 'd3-scale';
import {
  G,
  Rect,
  Text,
} from 'react-native-svg';
import moment from 'moment';
import { palette } from '@huum/theme/helpers';
import { toInteger } from 'lodash';
import { translate } from '@huum/i18n';
import { timeDiff } from '@huum/utils/helpers/statistics-selectors';


const screenWidth = Dimensions.get('window').width;

const tooltipStyling = {
  sessionHeader: {
    // alignmentBaseline: "middle",
    textAnchor: "start",
    fontSize: 16,
    fontWeight: "600",
    fill: palette.primaryDark,
    stroke: palette.primaryDark,
    // ...Fonts.REGULAR,
  } as const,
  times: {
    // alignmentBaseline: "middle",
    textAnchor: "start",
    stroke: palette.dimmedText,
    fontSize: 10,
    fontWeight: "100",
    // ...Fonts.Regular,
  } as const,
  dimmedHeader: {
    // alignmentBaseline: "middle",
    textAnchor: "start",
    fill: palette.dimmedText,
    stroke: palette.dimmedText,
    fontSize: 14,
    fontWeight: "100",
    // ...Fonts.Regular,
  } as const,
  results: {
    // alignmentBaseline: "middle",
    textAnchor: "start",
    stroke: palette.primaryDark,
    fill: palette.primaryDark,
    fontSize: 16,
    fontWeight: "700",
    // ...Fonts.EXTRA_LIGHT,
    // fontWeight: "100",
  } as const,
};

const tooltipDimensions = {
  height: 224,
  width: 132,
};

const xWidth = tooltipDimensions.width / 2;

const TEXTDX = -50;

// const getBoxPositionY = (yCoordinate) => {
//   const defaultPosition = yCoordinate;
//   let position = defaultPosition;

//   // are we going out of the screen at the top?
//   if (yCoordinate < 0) {
//     position += tooltipDimensions.height + 20; // if yes, make it go out of the bottom instead;
//   }
//   // going out at the bottom never happens because
//   // the box is supposed to be on top of the circle button,
//   // which shouldn't be off screen

//   return position;
// };

// const getBoxPositionX = (boxRelativePosition, xCoordinate) => {
//   const boxActualPosition = (boxRelativePosition + xCoordinate);
//   const defaultPosition = boxRelativePosition; // default middle;
//   let position = defaultPosition;

//   // are we going out of the screen on the right?
//   if (boxActualPosition + tooltipDimensions.width * (3 / 2) > screenWidth - 40) {
//     position = -tooltipDimensions.width; // if yes, move it left;
//   }
//   // are we going out of the screen on the left?
//   if (boxActualPosition + tooltipDimensions.width * 2 < 0) {
//     position = +tooltipDimensions.width; // if yes, move it right;
//   }

//   return position;
// };


const TooltipBox = (props: any) => {
  const {
    startOfDay,
    endOfDay,
    isStart,
    startTime,
    startTemperature,
    endTime,
    endTemperature,
    target,
    heatingTime,
    tooltipNumber,
    openTooltip,
    temperatureUnit,
  } = props; 

  if (openTooltip === tooltipNumber) {
    const scaleTime = scale.scaleTime()
      .domain([startOfDay, endOfDay])
      .range([16, screenWidth - 80 - 16]);
    // function
    const scaleTemp = scale.scaleLinear()
      .domain([0, (temperatureUnit === 'celsius' ? 100 : 220)])
      .range([260, 20]);

    const usageTime = timeDiff(moment(endTime).diff(startTime, 'milliseconds'));

    // const timeCoordinate = scaleTime(isStart ? startTime : endTime);
    // const tempCoordinate = scaleTemp(isStart ? startTemperature : endTemperature);
    const sessionNumber = 1 + (isStart ? tooltipNumber : tooltipNumber - 0.5);
    
    //change postions to right or left based on new design
    const startPositionOverCenter = toInteger(moment(startTime).format("HH.mm")) >= 12.00;
    const endPositionOverCenter = toInteger(moment(endTime).format("HH.mm")) >= 12.00;
    const tooltipPosition = useMemo(() => {
      if(!startPositionOverCenter || !endPositionOverCenter)
      return { y: 28, x: screenWidth / 2 };
      else return { y: 26, x: 0.02 * screenWidth };
    }, [startTime, endTime])

    // currently component is set to static postition
    return (
      <G
        // x={timeCoordinate}
        key={`tooltip-${sessionNumber}`}
        // y={getBoxPositionY(tempCoordinate - tooltipDimensions.height - 10)}
        y={tooltipPosition?.y}
      >
        <G
          // styles={{position: "absolute", left: 50, right: 0}}
          // x={getBoxPositionX(-(tooltipDimensions.width / 2), timeCoordinate)}
          x={tooltipPosition?.x}
        >
          {/* Shadow */}
          <Rect
            height={tooltipDimensions.height + 7}
            width={tooltipDimensions.width + 7}
            fill="rgba(0, 0, 0, 0.075)"
            ry={10}
            rx={10}
            x={-2}
            y={-2}
          />

          <Rect
            height={tooltipDimensions.height}
            width={tooltipDimensions.width}
            fill="white"
            ry={5}
            rx={5}
          />
          <Text x={xWidth} dy={35} dx={TEXTDX} {...tooltipStyling.sessionHeader}>
            {`Session ${sessionNumber}`}
          </Text>
          <Text x={xWidth} dy={50} dx={TEXTDX} {...tooltipStyling.times}>
            {`${moment(startTime).format("HH:mm")} - ${moment(endTime).format(
              "HH:mm"
            )}`}
          </Text>
          <Text x={xWidth} dy={80} dx={TEXTDX} {...tooltipStyling.dimmedHeader}>
            {`${translate("text.target_temp")} `}
          </Text>
          <Text x={xWidth} dy={100} dx={TEXTDX} {...tooltipStyling.results}>
            {`${target}°`}
          </Text>
          <Text x={xWidth} dy={130} dx={TEXTDX} {...tooltipStyling.dimmedHeader}>
            {`${translate("text.total_usage")} `}
          </Text>
          <Text x={xWidth} dy={150} dx={TEXTDX} {...tooltipStyling.results}>
            {`${usageTime}` || "0 min"}
          </Text>
          <Text x={xWidth} dy={180} dx={TEXTDX} {...tooltipStyling.dimmedHeader}>
            {`${translate("text.heating_time")} `}
          </Text>
          <Text x={xWidth} dy={200} dx={TEXTDX} {...tooltipStyling.results}>
            {`${heatingTime}` || "0 min"}
          </Text>
        </G>
      </G>
    );
  }
  return null;
};

export default TooltipBox;
