import React, { useCallback, useState } from "react"
import { Box, Row, useTheme } from 'native-base';
import Slider from '@react-native-community/slider';
import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { useStyles } from "./styles";
import SliderSelect from "@huum/components/molecules/slider-select";

interface EffectSpeedControllerProps {
  defaultValue?: number;
  onChange: (value: number) => void;
}

const EffectSpeedController = (props: EffectSpeedControllerProps) => {
  const {
    defaultValue,
    onChange
  } = props;

  const [effectSpeed, setEffectSpeed] = useState<number>(defaultValue || 1);

  const styles = useStyles();

  const onSlideComplete = useCallback((value: number) => {
      setEffectSpeed(value);
      onChange(value);
  }, [onChange])

  return (
    <Row alignItems="center" width="full" justifyContent="space-between">
        <Box width={8} alignItems="center">
          <Icon
            size={10}
            tint="primaryDark"
            icon="timer"
          />
        </Box>
        <Box>
          <SliderSelect
            style={styles.slider}
            step={0.5}
            minValue={0}
            maxValue={5}
            showValue={false}
            value={effectSpeed}
            onChange={setEffectSpeed}
            onChangeEnd={onSlideComplete}
          />
        </Box>
        <Box width={10}>
          <Text fontSize="sm">{`${effectSpeed} x`}</Text>
        </Box>
      </Row>
  )
}

export default EffectSpeedController;
