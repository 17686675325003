import './i18n';
export * from './i18n';
export * from './translate';


export const languages = [
  {
    value: 'en', label: 'English', shortCode: 'en'
  },
  {
    value: 'et', label: 'Eesti', shortCode: 'et'
  },
  {
    value: 'de', label: 'Deutschland', shortCode: 'de'
  },
  {
    value: 'fi', label: 'Suomi', shortCode: 'fi'
  },
  {
    value: 'ru', label: 'Россия', shortCode: 'ru'
  },
  {
    value: 'pl', label: 'Polskie', shortCode: 'pl'
  },
  {
    value: 'it', label: 'Italia', shortCode: 'it'
  },
  {
    value: 'fr', label: 'France', shortCode: 'fr'
  },
];