import { Platform } from "react-native"

export const getHitSlop = (size: number) => {
  const value = size * (Platform.OS === "web" ? 4 : 2)
  return ({
    top: value,
    bottom: value,
    left: value,
    right: value,
  })
}