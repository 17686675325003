import React, { useCallback, useEffect, useMemo, useState } from "react"
import Button from "@huum/components/atoms/button"
import Checkbox from "@huum/components/atoms/checkbox"
import Radio from "@huum/components/atoms/radio"
import { translate } from "@huum/i18n"
import { useStores } from "@huum/store"
import { get, map } from "lodash";
import { Box, ScrollView, Stack } from "native-base"
import { ModalContainer } from "@huum/components/views/modals/modal-container"


const LanguageSelectModal = () => {
  const { modal, auth } = useStores();
  const { language } = auth;
  const { closeModal } = modal;

  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const props = get(modal, 'modalProps', {});

  const handleChangeLanguage = useCallback(
    (value: any) => {
      setSelectedLanguage(value);
  }, []);

  const handleLanguageConfirm = useCallback(async () => {
    props.onConfirm(selectedLanguage);
    closeModal();
  }, [selectedLanguage]);

  const OptionsList = useCallback(() => (
    <Radio options={props.options} onChange={handleChangeLanguage} value={selectedLanguage} />
  ), [props, selectedLanguage])

  return (
    <ModalContainer closeModal={modal.closeModal} title={translate("label.language")}>
      <Box flexGrow={1} justifyContent="space-between">
        <ScrollView flexGrow={1}>
          <Stack>
            <OptionsList />
          </Stack>
        </ScrollView>
        <Button
          mt={4}
          label={translate("button.confirm")}
          onPress={handleLanguageConfirm}
        />
      </Box>
    </ModalContainer>
  );

}

export default LanguageSelectModal