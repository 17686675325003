import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
import { useStores } from "@huum/store";
import { get } from "lodash";


interface ProfileBody {
  session: string | null;
  email: string;
  name: string;
  // phone: number;     // is not present in new design
}

export interface changePasswordParams {
  oldPassword: string;
  session: string | null;
  old_password: string;
  password1: string;
  password2: string;
}

export async function getProfile(session: any) {
  const response = await api.huum.get("/action/profile", {
    version: 3,
    session,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function saveProfile(props: ProfileBody) {
  const { session, email, name } = props;

  const profileData = {
    version: 3,
    session,
    email,
    name,
  };

  const response = await api.huum.post("/action/save_profile",  profileData );

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function changePassword(props: changePasswordParams) {
  const { old_password, password1, password2, session } = props;

  const passwordParams = {
    version: 3,
    session,
    old_password,
    password1,
    password2,
  };

  const response = await api.huum.get("/action/passwordchange",passwordParams);

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function verifyEmail(session: string | null) {
  const response = await api.huum.post("/action/verifyemail", {session});

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function languageChange(data: any) {
  const response = await api.huum.post("/action/save_language", {
    ...data,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function userSafetyNotificationChange(data: any) {
  const response = await api.huum.post("action/save-settings", {
    ...data,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}

export async function userTemperatureUnitChange(data: any) {
  const response = await api.huum.post("user/temperature", {
    ...data,
    version: 3,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data;
}

