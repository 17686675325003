import { replace, size, toNumber } from "lodash";

export const validateNumber = (value: any) => {
  const numberRegex = new RegExp(/^\d+$/);
  return numberRegex.test(value);
};

export const cleanNumberValue = (value: string) => toNumber(replace(value, /[^0-9]/g, ''));

export const emailIsCorrect = (email: any) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const validateEmail = (email: any) => emailIsCorrect(email);

export const validateLengthAtLeastNChars = (value: any, n: number) => size(value) >= n;