import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Dimensions } from "react-native";
import {
  map,
  toString,
  findIndex,
  size,
  includes,
  toUpper,
  get,
  reduce,
  range,
  times,
} from "lodash";
import moment from "moment";
import {
  loadNextMonth,
  toVisibleListData,
  loadNextWeek,
} from "../helpers";
import { getCalendarStyles } from "../styles";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { Text } from "@huum/components/atoms/text";
import { Box, FlatList, Stack } from "native-base";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import CalendarHeader from "../calendar-header/index";


const { width } = Dimensions.get("window");
const CALENDAR_WEEK_WIDTH = width;

const getItemLayout = (_data: any, index: any) => ({
  length: CALENDAR_WEEK_WIDTH,
  offset: CALENDAR_WEEK_WIDTH * index,
  index,
});

const getInitialWeeksList = (props: any) => {
  const startWeekDate = moment(props.selectedDate).subtract(1, "week")
  
  return reduce(times(3), (result, value) => {
    return loadNextWeek(moment(startWeekDate).add(value, 'week'), result)
  }, {})
};


const WeekCalendar = (props: any) => {
  const calendarList = React.createRef();
  const [selectedDate, setSelectedDate] = useState(props.selectedDate);
  const [visibleWeekDate, setVisibleDate] = useState(moment(props.selectedDate));
  const currentWeek = visibleWeekDate.get("week");

  const [weeksList, setWeeksList] = useState(getInitialWeeksList(props));

  const visibleWeeksList = useMemo(() => toVisibleListData(weeksList), [weeksList])

  const activeWeekIndex = useMemo(() => {
    return findIndex(visibleWeeksList, {
      week: toString(moment(visibleWeekDate).week()),
    });
  }, [visibleWeeksList, visibleWeekDate])

  const initialWeekIndex = findIndex(visibleWeeksList, {
    week: toString(currentWeek),
  });

  // no idea why, but animating scroll at start breaks android, making current week items invisible
  // skipping animation on load fixes that problem
  const [shouldAnimateScroll, setShouldAnimateScroll] = useState(false);

  const viewabilityConfig = useRef({
    waitForInteraction: true,
    viewAreaCoveragePercentThreshold: 50,
  });


  const styles = useThemedStyles(getCalendarStyles);

  useEffect(() => {
    if (!!visibleWeeksList[activeWeekIndex]) {
      calendarList.current.scrollToIndex({
        index: activeWeekIndex,
        animated: shouldAnimateScroll,
      });
    }
    if (!shouldAnimateScroll) {
      setShouldAnimateScroll(true);
    }
  }, [activeWeekIndex, visibleWeeksList]);


  const showNextWeek = () => {
    const nextVisibleWeek = moment(visibleWeekDate).add(1, "week").startOf("week");
    const nextWeekToLoad = moment(visibleWeekDate).add(2, "week").startOf("week");

    setVisibleDate(nextVisibleWeek);
    setWeeksList(loadNextWeek(nextWeekToLoad, weeksList));
    
  }

  const showPreviousWeek = () => {
    const previousVisibleWeek = moment(visibleWeekDate).subtract(1, "week").endOf("week");
    const nextWeekToLoad =  moment(previousVisibleWeek).add(1, "week").endOf("week");

    setVisibleDate(previousVisibleWeek);
    setWeeksList(loadNextWeek(nextWeekToLoad, weeksList));
  }

  const handleDateSelect = useCallback((date: any) => () => {
    if (props.disabled) return;

    if (typeof props.onDateSelect === "function") {
      props.onDateSelect(moment(date));
    }
    setSelectedDate(date);
  }, [props]);

  const renderActivityIndicator = useCallback((day: any) => {
    const hasIndicator = includes(props.activityIndicatorList, day.key);
    if (!hasIndicator) return null;
    return <Box style={styles.activityIndicator} />;
  }, []);

  const renderDay = useCallback((day: any) => {
    const isSelected = selectedDate === day.key;

    return (
      <Touchable
        onPress={handleDateSelect(day.key)}
        style={styles.weekCalendarDay}
      >
          <Text fontSize="sm" preset="subtitle1" fontWeight={700}>
            {day.date}
          </Text>
          <Text fontSize="sm" style={styles.selectedText}>
            {toUpper(moment(day.key).format("ddd"))}
          </Text>
          {
            !isSelected && (
              renderActivityIndicator(day)
            )
          }
      </Touchable>
    );
  }, [handleDateSelect]);


  const renderCalendarWeek = useCallback(({ item }) => (
    <Box style={styles.weekCalendarWeek} key={item.week}>
      {map(item.days, (value) => <Box key={value.key}>{renderDay(value)}</Box>)}
    </Box>
  ), [renderDay]);


  return (
    <Stack width="full">
      <Box justifyContent="space-between" pt={6} pb={2}>
        <CalendarHeader
          currentMonth={visibleWeekDate}
          futureDatesLimit={props?.limitFutureDates}
          pastDatesLimit={props?.limitPastDates}
          onPreviousWeekPress={showPreviousWeek}
          onNextWeekPress={showNextWeek}
        />
        <FlatList
          ref={calendarList}
          data={visibleWeeksList}
          renderItem={renderCalendarWeek}
          getItemLayout={getItemLayout}
          initialScrollIndex={initialWeekIndex}
          pagingEnabled
          horizontal
          showsHorizontalScrollIndicator={false}
          scrollEnabled={false}
          viewabilityConfig={viewabilityConfig.current}
        />
      </Box>
      {props.children}
    </Stack >
  );
};

export default WeekCalendar;
