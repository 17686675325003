import React from 'react';
import { Dimensions } from 'react-native';
import * as scale from 'd3-scale';
import {
  Line,
} from 'react-native-svg';
import { palette } from '@huum/theme/helpers';


const screenWidth = Dimensions.get('window').width;

const HorizontalLine = (props: any) => {
  const {
    target,
    startOfDay,
    endOfDay,
    firstTime,
    lastTime,
    temperatureUnit,
  } = props;

  const scaleTime = scale.scaleTime()
    .domain([startOfDay, endOfDay])
    .range([16, screenWidth - 80 - 16]);
  // function
  const scaleTemp = scale.scaleLinear()
    .domain([0, (temperatureUnit === 'celsius' ? 100 : 220)])
    .range([260, 20]);

  const lineStart = scaleTime(firstTime);
  const lineEnd = scaleTime(lastTime);

  return (
    <Line
      key="zero-axis"
      x1={lineStart}
      x2={lineEnd}
      y1={scaleTemp(target)}
      y2={scaleTemp(target)}
      stroke={palette.primaryDark}
      strokeDasharray={[3, 3]}
      strokeWidth={2}
    />
  );
};

export default HorizontalLine;
