import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { Box, Stack } from "native-base";
import SmartConfig from "react-native-smartconfig-quan";
import { request, PERMISSIONS, check, RESULTS } from "react-native-permissions";
import { useEffect, useState } from "react";
import { NetInfoStateType, useNetInfo } from "@react-native-community/netinfo";
import { NavigationHeader } from "@huum/components/molecules/header/navigation-header";
import { useForm } from "react-hook-form";
import { FormInput } from "@huum/components/atoms/form-input";
import { ActivityIndicator, Platform } from "react-native";
import TextButton from "@huum/components/molecules/text-button";

// timeout not work with android, on android default is 45s
const TIME_OUT_SMART_CONFIG = 30 * 1000; // 30s

const SmartConfigConnectScreen = () => {
  const networkState = useNetInfo();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [canAutoConnect, setCanAutoConnect] = useState<boolean>(true);
  const [connectedDevice, setConnectedDevice] = useState<{
    ip: string;
    bssid: string;
  }>();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      password: "",
      ssid: networkState.details?.ssid,
      bssid: networkState.details?.bssid,
    },
  });

  const handleConnect = (values: {
    password: string;
    ssid: string;
    bssid: string;
  }) => {
    setIsLoading(true);

    const { ssid, password, bssid } = values;

    SmartConfig.start(
      ssid || networkState.details?.ssid,
      bssid || networkState.details?.bssid,
      password,
      TIME_OUT_SMART_CONFIG,
      (event) => {
        let { eventName } = event;
        if (eventName === "onFoundDevice") {
          const data = JSON.parse(event.data);

          setConnectedDevice(data);
        } else if (eventName === "onFinishScan") {
          setIsLoading(false);
          if (!connectedDevice) {

          } else {
          }
        }
      }
    );
  };

  useEffect(() => {
    if (networkState.details?.bssid && networkState.details?.ssid) {
      reset();
    }
  }, [networkState.details]);

  useEffect(() => {
    const locationPermission =
      Platform.OS === "ios"
        ? PERMISSIONS.IOS.LOCATION_WHEN_IN_USE
        : PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION;
    check(locationPermission).then((result) => {
      if (result === RESULTS.BLOCKED) {
        setCanAutoConnect(false);
      }

      if (result === RESULTS.DENIED) {
        request(locationPermission);
      }
    });
  }, []);

  const handleCancel = (values: { password: string }) => {
    setIsLoading(false);
    SmartConfig.stop();
  };

  if (
    !networkState.isConnected &&
    networkState.type !== NetInfoStateType.wifi
  ) {
    return <Text>{"Wifi is not enabled"}</Text>;
  }

  return (
    <>
      <NavigationHeader title="Sauna WiFi connect" />
      <Stack
        flex={1}
        alignItems="center"
      >

        {!isLoading && connectedDevice && (
          <Stack p={4} space={4}>
            <Text fontWeight="bold">Connection was successful</Text>
            <Text>{"Device IP: " + connectedDevice?.ip}</Text>
            <Text>{"Device BSSID: " + connectedDevice?.bssid}</Text>
          </Stack>
        )}

        {isLoading && (
          <Stack p={4} space={4}>
            <ActivityIndicator animating />
            <TextButton onPress={handleCancel} label="Cancel" />
          </Stack>
        )}

        {!isLoading && !connectedDevice && (
          <Stack mt={8} px={4} space={4}>
            <Box>
              <Text mb={1}>Wifi SSID</Text>
              <FormInput
                name="ssid"
                control={control}
                defaultValue={networkState.details?.ssid}
              />
            </Box>
            <Box>
              <Text mb={1}>Wifi BSSID</Text>
              <FormInput
                name="bssid"
                control={control}
                defaultValue={networkState.details?.bssid}
              />
            </Box>
            <Box>
              <Text mb={1}>Wifi password</Text>
              <FormInput name="password" control={control} isSecure />
            </Box>

            <Button
              mt={4}
              label="Connect to wifi"
              onPress={handleSubmit(handleConnect)}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default SmartConfigConnectScreen;
