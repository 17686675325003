import { map, find } from "lodash"
import { observer } from "mobx-react-lite"
import React, { useEffect, useRef,  } from "react"
import { Pressable, View, Animated } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"
import { useStores } from "../../../store"
import { ToastMessage } from "../../../store/toast/toast-store"
import { Icon } from "@huum/components/atoms/icon"
import { Text } from "@huum/components/atoms/text"

import { useKeyboardHeight } from "@huum/hooks/useKeyboardHeight"
import { useThemedStyles } from "@huum/hooks/useThemedStyles"
import { getStyles } from "./styles"
import { Row } from "native-base"

interface ToastMessageProps extends ToastMessage {
  onHide: () => void
}

export const ToastMessageComponent = (props: ToastMessageProps) => {

  const styles = useThemedStyles(getStyles);

  const { message, type, closable = true, onHide, location, duration } = props

  const opacity = useRef(new Animated.Value(0)).current;

  const handleHideMessage = () => {
    if (closable) {
      onHide()
    }
  }

  useEffect(() => {
    if (closable) {
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }),
        Animated.delay(duration || 3000),
        Animated.timing(opacity, {
          toValue: 0,
          duration: 500,
          useNativeDriver: true,
        }),
      ]).start(handleHideMessage)
    } else {
      Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start()
    }
  })

  const getMessageStyle = (type) => {
    switch (type) {
      case "error":
        return {
          style: styles.ERROR_MESSAGE,
          icon: "info",
        }
      case "info":
         return {
           style: styles.INFO_MESSAGE,
           icon: "info",
         }
      default:
        return {
          style: styles.SUCCESS_MESSAGE,
          icon: "success",
        }
    }
  }
  
  const componentStyles = getMessageStyle(type)

  return (
    <Pressable onPress={handleHideMessage}>
      <Animated.View
        style={[
          componentStyles.style,
          {
            opacity,
            transform: [
              {
                translateY: opacity.interpolate({
                  inputRange: [0, 1],
                  outputRange: location === "top" ? [-60, 0] : [0, -60],
                }),
              },
            ],
          },
        ]}
      >
        <Row alignItems="center">
          <Icon icon={componentStyles.icon} tint="white.100" size={10} />
          <View style={styles.MESSAGE_CONTAINER}>
            <Text color="white.100">{message}</Text>
          </View>
        </Row>
      </Animated.View>
    </Pressable>
  )
}

export const ToastRoot = observer(() => {
  const { toast } = useStores()
  const { messages, hideToast, clearAll } = toast;
  const keyboardHeight = useKeyboardHeight()
  const moveUpWithKeyboard = keyboardHeight ? { bottom : keyboardHeight / 1.3} : null
  const styles = useThemedStyles(getStyles);

  useEffect(() => {
    clearAll()
  }, [])

  const style = find(messages, { location: "top" })
    ? styles.CONTAINER_TOP
    : [styles.CONTAINER_BOTTOM, moveUpWithKeyboard]

  if (messages.length === 0) return null;
  return (
    <SafeAreaView style={style}>
      {map(messages, message => (
        <ToastMessageComponent key={message.id} {...message} onHide={() => hideToast(message.id)} />
      ))}
    </SafeAreaView>
  )
})
