import { useStores } from "@huum/store";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { fetchSaunaStatus } from "@huum/api/requests/sauna";
import SaunaStatusCode from "@huum/utils/sauna-status-code";

import { useControllerSoftwareUpdate } from "./useSoftwareUpdate";

export const useSaunaStatus = (ignoreErrors?: boolean) => {
  const { auth, sauna } = useStores();
  const [steamerError, setSteamerError] = useState<boolean>(!!ignoreErrors)
  const session = get(auth, "sessionHash", null);
  const { data, isError, isSuccess } = useQuery(["saunastatus"], () => fetchSaunaStatus({ session, saunaId: sauna.saunaId }), { refetchInterval: 30000, enabled: !!sauna.saunaId });

  const { handleUpdateControllerSoftware } = useControllerSoftwareUpdate()


  useEffect(() => {
    if (data?.steamerError && !steamerError) {
      setSteamerError(true)
      // showSteamerErrorModal()
    }

    if (data?.showModal) {
      handleUpdateControllerSoftware()
    }
  }, [data])

  const targetTemperature = (data?.statusCode === SaunaStatusCode.SAUNA_ACTIVE || data?.statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED)
    ? data?.targetTemperature
    : 0

  return {
    targetTemperature,
    maxHeatingTime: data?.maxHeatingTime || 3,
    ...(data || {}),
    isError,
    isSuccess
  };
};
