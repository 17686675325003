import Button from "@huum/components/atoms/button";
import { FormInput } from "@huum/components/atoms/form-input";
import { Text } from "@huum/components/atoms/text";
import { FlowComponentProps } from "@huum/components/organisms/flow-container";
import { translate } from "@huum/i18n";
import { Row, ScrollView, Stack, useTheme } from "native-base";
import { useCallback, useEffect, useState } from "react";
import { useSaunaConnect } from "@huum/hooks/api/useSaunaConnect";
import { Switch } from "@huum/components/molecules/switch";
import { useStores } from "@huum/store";
import InfoLabel from "@huum/components/molecules/info-label";
import TextButton from "@huum/components/molecules/text-button";
import { IconButton } from "@huum/components/molecules/icon-button";
import { goBack } from "@huum/services/NavigationService";


const ConnectSaunaView = (props: FlowComponentProps) => {
  const { control, handleSubmit, setViewComponent } = props;
  
  const { sauna } = useStores()
  const { saunaConnect, isSuccess, showCodeInput, setSaunaIsPrivate } = useSaunaConnect();

  const { config } = useTheme();

  const handleConnect = useCallback(
    (values: any) => {
      saunaConnect({ ...values });
    },
    [],
  )

  useEffect(() => {
    if(isSuccess) {
      setViewComponent('saunaActivated')
    }
  }, [isSuccess])

  return (
    <ScrollView
      keyboardDismissMode="interactive"
      keyboardShouldPersistTaps="handled"
    >
      <Stack mt={16} mb={6} space={4}>
        <Text mb={6} textAlign="center" preset="h4">
          {translate("title.connect_with_sauna")}
        </Text>
        <FormInput
          placeholder={translate("label.id")}
          name="saunaID"
          control={control}
          keyboardType="numeric"
        />
       {
        showCodeInput && (
          <FormInput
            placeholder={translate("label.sauna_code")}
            name="saunaCode"
            control={control}
            keyboardType="numeric"
          />
        )
       }
       {showCodeInput && (
        <Row justifyContent="space-between">
          <InfoLabel
            label={translate("label.is_sauna_private")}
            infoText={translate('info.private_sauna', { supportEmail: config.helpEmail })}
          />
          <Switch disabled={!sauna.isFirstUser} value={!!sauna.saunaIsPrivate} onToggle={setSaunaIsPrivate} />
        </Row>
       )}
      </Stack>
      <Stack space={4}>
        <Text preset="subtitle2">
          {translate("text.connect_sauna_additional_text")}
        </Text>
        <Button
          onPress={handleSubmit(handleConnect)}
          label={translate("button.confirm")}
        />
      </Stack>
    </ScrollView>
  );
};

export default ConnectSaunaView;
