import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { FlowComponentProps } from "@huum/components/organisms/flow-container";
import { translate } from "@huum/i18n";
import { Center, Stack } from "native-base";



const LandingView = (props: FlowComponentProps) => {

  const { setViewComponent } = props;

  const showLoginView = () => setViewComponent('login')
  const showRegisterView = () => setViewComponent('register')

  return (
    <>
      <Center>
        <Center mb={{
          sm: 20,
          md: 10
        }} mt={16}>
          <Text preset="h3">
            {translate('title.hello')}
          </Text>
            <Text>
              {translate('title.lets_get_started')}
            </Text>
          </Center>
      </Center>
      <Stack
        space={8}
        width={{
          sm: "100%",
          md: "50%",
          lg: 300,
        }}
        alignSelf="center"
      >
        <Button onPress={showLoginView} label={translate('button.log_in')} />
        <Button onPress={showRegisterView} variant="outline" label={translate('button.create_account')} />
      </Stack>
    </>
      
  )
}

export default LandingView;