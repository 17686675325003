import React from "react";
import { Box, Row } from "native-base";
import { Text } from "@huum/components/atoms/text";
import { IconButton } from "../../../molecules/icon-button";
import Button from "@huum/components/atoms/button";

interface ModalContainerProps {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  closeModal: () => void;
  onPress?: () => void;
}

export const ModalContainer = (props: ModalContainerProps) => {
  const { title, closeModal, children, onPress } = props;

  return (
    <Box
      backgroundColor="gray.50"
      paddingX={7}
      borderRadius={16}
      pb={8}
      pt={6}
    >
      <Row justifyContent={"space-between"} mb={8}>
        <Text preset="subtitle1" fontWeight={700}>
          {title}
        </Text>
        <IconButton tint="primaryDark" size={12} onPress={closeModal} icon="close" />
      </Row>
      {children}
    </Box>
  );
};
