import * as React from "react";
import { View, Image, ImageStyle, StyleSheet } from "react-native";
import { IconProps } from "./icon.props";

import icons, { IconSetTypes } from "@huum/assets/icons";
import { useTheme } from "native-base";
import { get } from "lodash";


const ROOT: ImageStyle = {
  resizeMode: "contain",
};

export function Icon(props: IconProps) {
  const { style: styleOverride, icon, containerStyle, tint, size = 16, ...rest } = props;

  const { colors } = useTheme();

  if (!icon) return null;

  const iconStyle = StyleSheet.flatten([
    ROOT,
    styleOverride,
    { height: size * 2, width: size * 2 },
    { tintColor: get(colors, tint || '') || tint },
  ]);

  return (
    <View style={containerStyle} { ...rest }>
      <Image style={iconStyle} source={icons[icon as IconSetTypes]} />
    </View>
  );
}
