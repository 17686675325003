import { saunaSessionRequest, stopSaunaSessionRequest } from "@huum/api/requests/sauna"
import { useStores } from "@huum/store"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { get } from "lodash"
import moment from "moment"
import { useCallback } from "react"


export const useSaunaController = () => {
  const { mutateAsync: requestSaunaSession } = useMutation(saunaSessionRequest)
  const { mutateAsync: stopSauna } = useMutation(stopSaunaSessionRequest)
  const { auth, sauna } = useStores();
  const session = get(auth, "sessionHash", null);

  const queryClient = useQueryClient()

  const startSaunaSession = useCallback(
    async (values: any) => {
      const fallBackDuration = sauna.latestDuration > 0 ? sauna.latestDuration : 180;
      const start = moment(values.start || undefined);

      if (start.isBefore(moment())) {
        start.add(1, "day") // this mutates start so we don't need to change the end separately
      }

      const end = moment(values.end || moment(start).add(fallBackDuration, 'minutes'))

      await requestSaunaSession({
        session,
        type: "start",
        startDate: start.unix(),
        endDate: end.unix(),
        targetTemperature: values?.targetTemperature || sauna.latestTargetTemp,
        saunaId: sauna.saunaId!,
      })

      queryClient.invalidateQueries({ queryKey: ["saunastatus"]})
      sauna.setLatestDuration(end.diff(start, 'minutes'))
      sauna.setLatestTargetTemp(values?.targetTemperature || sauna.latestTargetTemp)
    },
    [],
  )

  const stopSaunaSession = useCallback(
    async () => {
      await stopSauna({ session, saunaId: sauna.saunaId! })
      queryClient.invalidateQueries({ queryKey: ["saunastatus"]})
    },
    [stopSauna],
  )

  const modifySaunaSession = useCallback(
    async (saunaStatus: any, updateData: any) => {
      await requestSaunaSession({
        session,
        type: "modify",
        saunaId: sauna.saunaId!,
        ...saunaStatus,
        ...updateData,
      })

      queryClient.invalidateQueries({ queryKey: ["saunastatus"]})
    },
    [],
  )
  
  const pullEmergencyBreak = useCallback(
    async () => {
      await requestSaunaSession({
        session,
        type: "break",
        saunaId: sauna.saunaId!,
      })
      queryClient.invalidateQueries({ queryKey: ["saunastatus"]})
    },
    [],
  )

  return {
    startSaunaSession,
    stopSaunaSession,
    modifySaunaSession,
    pullEmergencyBreak,
    defaultDuration: sauna.latestDuration,
    defaultTargetTemp: sauna.latestTargetTemp,
  };
}


