import { inRange, times } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react"
import { Animated, LayoutChangeEvent, PanResponderInstance } from "react-native";
import { PanResponder } from "react-native";
import { trigger } from "react-native-haptic-feedback";
import { DEGREE_TO_TEMPERATURE_RELATION, MAX_TEMPERATURE_ANGLE_DEGREES, MIN_TEMPERATURE_ANGLE_DEGREES, Point, calculateAngle, calculateClosestPointToCircle, convertDegreesToCoordinates, convertTemperatureToCoordinates } from "./helpers";
import { useStores } from "@huum/store";

type ModeType = "temperature" | "power";
interface ControllerConfig {
  minTemperature: number;
  maxTemperature: number;
  targetTemperature?: number;
  width: number;
  topOffset: number;
  onTemperatureChange: (temperature: number) => void;
  mode?: ModeType
}

export const useControllerPanResponder = (config: ControllerConfig) => {
  const {
    minTemperature,
    topOffset,
    width,
    onTemperatureChange,
    targetTemperature,
    mode = "temperature",
  } = config;

  const { sauna } = useStores()

  const position = useRef(new Animated.ValueXY())

  const temperature = sauna.latestTargetTemp

  const [panResponderIsActive, setPanResponderIsActive] = useState(false);
  const [controllerTargetTemperature, setControllerTargetTemperature] = useState(temperature);
  const [centerPoint, setCenterPoint] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const positionCoordinates = convertTemperatureToCoordinates(width)(controllerTargetTemperature);
    position.current.setValue(positionCoordinates);
  }, [])

  useEffect(() => {
    if (!panResponderIsActive) {
      if (mode === "power") {
        const roundedDegrees = Math.round(controllerTargetTemperature / 10) * 10;
        if (roundedDegrees !== controllerTargetTemperature) {
            const roundedPosition = convertTemperatureToCoordinates(width, 19)(roundedDegrees);
            position.current.setValue(roundedPosition);
            setControllerTargetTemperature(roundedDegrees)
          }
      }

      if (!panResponderIsActive && controllerTargetTemperature !== temperature) {
        onTemperatureChange && onTemperatureChange(controllerTargetTemperature);
      }
    }
  }, [panResponderIsActive, controllerTargetTemperature])

  useEffect(() => {
    
  }, [panResponderIsActive, temperature])

  const onPanResponderEnd = async () => {
    setPanResponderIsActive(false)
  }

  const handleControllerLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    const { layout: { x, y, left, top, ...rest } } = nativeEvent;

    const center = {
      x: left + width / 2,
      y: top + width / 2,
    }
    setCenterPoint(center)
    setPanResponder(center, mode)
  }, [width, mode])

  const panResponder = useRef<PanResponderInstance>();

  const setPanResponder = (center: Point, mode: ModeType) => {
    panResponder.current = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_evt, gestureState) => {
        const { moveX, moveY } = gestureState;
        _evt.preventDefault()

        const point = {
          x: moveX,
          y: moveY,
        };
  
        const angleDeg = calculateAngle(center, point);
  
        const newTargetTemperature = Math.round((((MIN_TEMPERATURE_ANGLE_DEGREES) - angleDeg + 360) % 360) / DEGREE_TO_TEMPERATURE_RELATION);
  
        if (newTargetTemperature < minTemperature) return;
        const newPosition = convertDegreesToCoordinates(width)(angleDeg);


        if (newTargetTemperature % 10 === 0) {
          trigger("impactHeavy", { ignoreAndroidSystemSettings: true })
        }
  
        if (!inRange(angleDeg, MIN_TEMPERATURE_ANGLE_DEGREES, MAX_TEMPERATURE_ANGLE_DEGREES)) {
          position.current.setValue(newPosition);
          setPanResponderIsActive(true)
          setControllerTargetTemperature(newTargetTemperature);
        }
      },
      onPanResponderTerminationRequest: () => true,
      onPanResponderRelease: onPanResponderEnd,
      onPanResponderTerminate: onPanResponderEnd,
    })
  }


  const getStepsCoordinates = (count = 11, correction = 19) => {
    return times(count + 1, (index) => {
      const indexAsTemperature = index * 10;
      const indexPosition = convertTemperatureToCoordinates(width, correction)(indexAsTemperature);

      return {
        position: indexPosition,
        index,
        value: index + 1,
      }
    })
  }

  return {
    position,
    centerPoint,
    panResponder,
    panResponderIsActive,
    controllerTargetTemperature,
    handleControllerLayout,
    getStepsCoordinates,
  }
}