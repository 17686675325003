import React, { useCallback } from "react";
import { FormInput } from "@huum/components/atoms/form-input";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "./modal-container";
import { translate } from "@huum/i18n";
import { Control, useForm } from "react-hook-form";
import { Box, Stack } from "native-base";
import { useSaunaConnect } from "@huum/hooks/api/useSaunaConnect";
import { validateNumber } from "@huum/utils/validator";
import { useStores } from "@huum/store";
import Button from "@huum/components/atoms/button";
import TextButton from "@huum/components/molecules/text-button";
import { IconType } from "@huum/components/atoms/icon/icon.props";
import { Icon } from "@huum/components/atoms/icon";
import { IconButton } from "@huum/components/molecules/icon-button";
import InfoLabel from "@huum/components/molecules/info-label";

interface ModalContentProps {
  icon: IconType;
  contentText: string;
  infoText?: string;
  closeModal: () => void;
}

const IconModal = (props: ModalContentProps) => {
  const { contentText, icon, closeModal, infoText } = props;
  const { modal } = useStores()
  const handleInfoPress = useCallback(() => {
    modal.openModal('iconModal', { contentText: infoText, icon })
  }, [infoText])

  return (
    <Box justifyContent="center" alignItems="center">
      <Stack
        minW={200}
        minH={200}
        p={12}
        borderRadius={16}
        space={4}
        justifyContent="center"
        alignItems="center"
        backgroundColor="primaryDark"
      >
        <Box position="absolute" top={6} right={6}>
          <IconButton
            icon="close"
            tint="white.100"
            size={8}
            onPress={closeModal}
          />
        </Box>
        <Icon tint="white.100" icon={icon} size={28} />
        <Text fontWeight="bold" color="white.100" textAlign="center">
          {contentText}
        </Text>
       {infoText && <IconButton tint="white.100" size={12} icon="info" onPress={handleInfoPress} />}
      </Stack>
    </Box>
  );
};

export default IconModal;
