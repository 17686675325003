import React from "react";
import { Pressable, IPressableProps } from "native-base";
import { getHitSlop } from "@huum/utils/hitSlop";

interface TouchableProps extends IPressableProps {
  children: any;
  onPress?: () => void;
}

export const Touchable = (props: TouchableProps) => {
  const { children, onPress, ...rest } = props;
  return (
    <Pressable hitSlop={getHitSlop(8)} _pressed={{ opacity: 0.8 }} onPress={onPress} {...rest}>
      {children}
    </Pressable>
  );
};
