// @ts-nocheck
import React from 'react';
import { View, PressableProps } from 'react-native';
import { Pressable, Row, Stack } from 'native-base';
import { translate } from '@huum/i18n';
import styles from './styles';
import { Text } from '@huum/components/atoms/text';


interface CalendarBookingItemProps extends PressableProps {
  mine: boolean,
  isPrivate: boolean,
  day: string;
  month: string;
  start: string;
  end: string;
  title: string;
  owner: string;
};

const CalendarBookingItem = (props: CalendarBookingItemProps) => {
  const {
    mine,
    isPrivate,
    day,
    month,
    start,
    end,
    title,
    owner,
    ...rest
  } = props;
    

  return (
    <Pressable
      borderRadius={16}
      padding={4}
      minHeight="80px"
      justifyContent="center"
      backgroundColor="white.100"
      shadow={mine ? 2 : 0}
      mb={4}
      {...rest}
    >
      <Row flex={1} space={4} alignItems="center" justifyContent="space-between">
        <Stack>
          <Text fontWeight="bold" fontSize="sm">
            {day}
          </Text>
          <Text fontWeight="bold" fontSize="sm">
            {month}
          </Text>
        </Stack>
        <Stack>
          <Text fontSize="sm">
            {start}
            -
            {end}
          </Text>
          <Text
            fontSize="sm"
            color={isPrivate ? "warning.600" : "primaryDark"}
          >
            {translate(isPrivate ? 'label.private' : 'label.public')}
          </Text>
        </Stack>
        <Stack flex={1}>
          <Text
            fontSize={16}
            fontWeight="bold"
            textBreakStrategy="simple"
            numberOfLines={2}

          >
            {title}
          </Text>
          <Text fontSize="sm">{owner}</Text>
        </Stack>
      </Row>
      
    </Pressable>
  );
}

export default CalendarBookingItem;
