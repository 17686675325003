import React, { useCallback, useState } from "react";
import { Input } from "./input";
import { InputProps } from "./input.props";

export const SecureInput = (props: InputProps) => {
  const [textIsVisible, setTextVisible] = useState(false);
  const inputIcon = textIsVisible ? 'eye-closed' : 'eye-open';

  const handleIconPress = useCallback(() => {
    setTextVisible(!textIsVisible);
  }, [textIsVisible]);

  return (
    <Input
      secureTextEntry={!textIsVisible}
      iconRight={inputIcon}
      onIconRightPress={handleIconPress}
      type={textIsVisible ? 'text' : 'password'}
      {...props}
    />
  );
};
