import React from "react";
import { NavigationHeaderProps } from "./props";
import { Heading } from "native-base";
import { BaseHeader } from "./base-header";
import { useRoute } from "@react-navigation/native";
import { translate, TxKeyPath } from "@huum/i18n";


/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function NavigationHeader(props: NavigationHeaderProps) {
  const {
    title,
    isRoute = true,
    onBackPress,
    withBackNavigation = true,
  } = props

  const route = isRoute ? useRoute() : { name: "" };

  const screenName = title ?? (route.name && translate(`navigation.${route.name}` as TxKeyPath))

  return (
    <BaseHeader
      withBackNavigation={withBackNavigation}
      onBackPress={onBackPress}
      isPlain
    >
     <Heading fontSize="2xl">{screenName}</Heading>
    </BaseHeader>
  )
}; 
