import React, { useCallback, useState } from "react"
import { Stack, Row, useTheme, Center, Box, Image, ScrollView, Spinner } from 'native-base';
import EffectSpeedController from "./effectSpeed";
import BrightnessController from "./brightness";
import EffectsSelect, { Effect, EffectKey } from "./effects";
import { ColorPicker, fromHsv } from "react-native-color-picker";
import { COLOR_WHEEL_SIZE, useStyles } from "./styles";
import { Pressable } from "react-native";
import { useLedController } from "@huum/hooks/useLedController";
import { HsvColor } from "react-native-color-picker/dist/typeHelpers";
import { throttle } from "lodash";
import { ModalContainer } from "@huum/components/views/modals/modal-container";
import { getHitSlop } from "@huum/utils/hitSlop";
import { ILinearGradientProps } from "native-base/lib/typescript/components/primitives/Box/types";
import { ColorType, ResponsiveValue } from "native-base/lib/typescript/components/types";


interface LedControllerProps {
  closeModal: () => void;
  bleModalVisible?: boolean;
}

interface RgbColors {
  red: number,
  green: number,
  blue: number,
}

const rgbWhite = {
  red: 255,
  green: 255,
  blue: 255,
}

const hexToRgb = (hex: string) => {
  return {
    red: parseInt(hex.substring(1,3), 16),
    green: parseInt(hex.substring(3,5), 16),
    blue: parseInt(hex.substring(5), 16),
  }
}

const WHITE_COLOR = "#fff";

const LedController = (props: LedControllerProps) => {
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [selectedEffect, selectEffect] = useState<EffectKey>(undefined);
  const [selectedColor, setSelectedColor] =
    useState<ResponsiveValue<ColorType | ILinearGradientProps>>(WHITE_COLOR);

  const { colors } = useTheme();
  const styles = useStyles();

  const { closeModal } = props;

  const {
    setSpeed,
    setEffect,
    setLedColor,
    setBrightness,
  } = useLedController();

  const handleEffectSpeedChange = (value: number) => {
    setSpeed(value)
  }
  const handleShowColorPicker = () => {
    setShowColorPicker(true)
  }
  const handleHideColorPicker = () => {
    setShowColorPicker(false)
    setSelectedColor(WHITE_COLOR)
    setLedColor(WHITE_COLOR)
    selectEffect(undefined)
  }

  const handleBrightnessChange = (brightness: number) => {
    setBrightness(brightness)
  }

  const handleSelectEffect = (effect: Effect) => {
    const effectKey = effect.effectKey;
    selectEffect(effectKey);
    setSelectedColor({
      linearGradient: {
        colors: effect.gradient as unknown as string[],
        start: [0, 0],
        end: [1, 0],
        locations: [0, 0.6],
      },
    });
    setShowColorPicker(false);
    setEffect(effect.name, effect.slot, effect.data)
  }

  const handleColorChange = (color: HsvColor) => {
    const hex = fromHsv(color);
    setLedColor(hex);
    setSelectedColor(hex);
  }

  const handleColorChangeThrottle = useCallback(throttle(handleColorChange, 1000), [setLedColor]);


  // if (!connectedDevice) {
  //   return (
  //     <ModalContainer closeModal={closeModal} title="">
  //       <Stack>
  //         <ScrollView>
  //           {isScanningDevices && <Spinner />}
  //           {map(devices, (device) => (
  //             <Pressable onPress={() => connectDevice(device)}>
  //               <Text>
  //                 {device.name || device.id}
  //               </Text>
  //             </Pressable>
  //           ))}
  //         </ScrollView>
  //         {!isScanningDevices && <Button onPress={scanDevices} label="Connect Bluetooth device"  />}
  //       </Stack>
  //     </ModalContainer>
  //   )
  // }
  

  return (
    <ModalContainer closeModal={closeModal} title="">
      
      <Stack flex={1} space={4}>
        <Center mb={4} height={COLOR_WHEEL_SIZE}>
          <Row left={0} right={0} justifyContent="space-between" position="absolute" bottom={0}>
            <Pressable hitSlop={getHitSlop(8)} onPress={handleShowColorPicker}>
              <Image alt="wheel" size={12} source={require('@huum/assets/legacy/color_wheel.png')} shadow={2} />
            </Pressable>
            <Pressable onPress={handleHideColorPicker}>
              <Box size={12} borderRadius={100} bgColor="white.100" shadow={2} />
            </Pressable>
          </Row>
          {
            showColorPicker && (
              <ColorPicker
                style={styles.colorPicker}
                hideSliders
                onColorChange={handleColorChangeThrottle}
              />
            )
          }
          <Box
              style={styles.colorPickerImageBox}
              bg={selectedColor}
            >
              <Image
                alt="picker"
                style={styles.colorPickerImage}
                tintColor={
                  selectedColor === WHITE_COLOR
                    ? colors.primaryDark
                    : colors.white[100]
                }
                source={require('@huum/assets/legacy/lightbulb.png')}
              />
            </Box>
        </Center>
        <EffectSpeedController onChange={handleEffectSpeedChange} />
        <EffectsSelect selected={selectedEffect} onSelect={handleSelectEffect} />
        <BrightnessController onChange={handleBrightnessChange} />
      </Stack>
    </ModalContainer>
  )
}

export default LedController;