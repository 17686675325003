import { MainHeader } from "@huum/components/molecules/header/main-header";
import { IconButton } from "@huum/components/molecules/icon-button";
import { useSaunaController } from "@huum/hooks/api/useSaunaController";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { ActiveToolbarItem } from "@huum/utils/sauna-toolbar";
import moment, { Moment } from "moment";
import { Box, Row, Stack } from "native-base";
import { Fragment, useCallback, useState } from "react";
import SaunaConfigTabs from "../../components/sauna-config-tabs";
import SaunaController from "../../components/sauna-controller";
import TopButtonsRow from "../../components/top-buttons-row";
import { includes, toNumber } from "lodash";
import { translate } from "@huum/i18n";
import { observer } from "mobx-react-lite";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { LayoutRectangle } from "react-native";
import { SaunaInfoSection } from "../../components/sauna-info-section";

type ToolbarItem = keyof typeof ActiveToolbarItem;

const MainControllerScreen = observer(() => {
  const { sauna, modal, auth } = useStores();
  const { door, remoteSafetyState, ...restStatus } = useSaunaStatus();
  const { statusCode } = restStatus || {};
  const { startSaunaSession, stopSaunaSession, modifySaunaSession } =
    useSaunaController();
  const [controllerIsDisabled, setControllerIsDisabled] =
    useState<boolean>(false);
  const [activeToolbarItem, setActiveToolbarItem] = useState<ToolbarItem>(
    ActiveToolbarItem.HEATING
  );
  const [timerValue, setTimerValue] = useState<{
    start: Moment | null;
    end: Moment | null;
  }>({ start: null, end: null });
  const [controllerLayout, setControllerLayout] = useState<LayoutRectangle>();

  const saunaSessionInProgress =
    statusCode === SaunaStatusCode.SAUNA_ACTIVE ||
    statusCode === SaunaStatusCode.SAUNA_ACTIVE_BLOCKED;

  const handleToolbarItemChange = useCallback((item: ToolbarItem) => {
    setActiveToolbarItem(item);
  }, []);

  const handleStartSauna = useCallback(
    (isImmediate: boolean) => () => {

      if (!door) {
        return modal.openModal("iconModal", {
          icon: "door-open",
          contentText: translate("text.door_is_open"),
        });
      }

      if (remoteSafetyState === "notSafe") {
        return modal.openModal("iconModal", {
          icon: "stopped",
          contentText: translate("error.sauna_not_safe"),
        });
      }
      
      startSaunaSession({
        ...(isImmediate ? {} : timerValue),
        targetTemperature: sauna.latestTargetTemp, //todo
      });

      if (isImmediate) {
        setTimerValue({ start: null, end: null });
      }
    },
    [startSaunaSession, timerValue, door, remoteSafetyState]
  );

  const handleTimerValueChange = useCallback((values: any) => {
    setTimerValue({
      start: moment(values.start),
      end: moment(values.end),
    });

    if (saunaSessionInProgress) {
      modifySaunaSession(restStatus, {
        start: values.start,
        end: values.end,
      });
    } else {
      handleStartSauna(false)();
    }
  }, [saunaSessionInProgress, modifySaunaSession, restStatus, handleStartSauna]);

  const handleStopSauna = useCallback(() => {
    stopSaunaSession();
  }, [stopSaunaSession]);

  const handleSaunaTemperatureChange = useCallback(
    (targetTemperature: number) => {
      if (saunaSessionInProgress) {
        modifySaunaSession(restStatus, {
          targetTemperature,
        });
      }
      sauna.setLatestTargetTemp(targetTemperature);
    },
    [modifySaunaSession, restStatus]
  );

  const handleSaunaHumidityChange = useCallback(
    (humidity: number) => {
      modifySaunaSession(restStatus, {
        humidity,
      });
    },
    [modifySaunaSession, restStatus]
  );

  const handleSaunaLightChange = useCallback(
    (enabled: boolean) => {
      modifySaunaSession(restStatus, {
        light: enabled ? 1 : 0,
        type: "light",
      });
    },
    [modifySaunaSession, restStatus]
  );

  const hideBookingButton =
    toNumber(sauna.saunaId) < 110000 ||
    statusCode === SaunaStatusCode.SAUNA_OFFLINE ||
    statusCode === SaunaStatusCode.SAUNA_EMERGENCY_STOP;

  return (
    <Stack flex={1}>
      <MainHeader withBackNavigation={false}>
        <Row space={4} alignItems="center">
          <IconButton
            onPress={() => navigate("Bookings")}
            icon={"calendar"}
            visible={!hideBookingButton}
            size={8}
          />
          <IconButton
            onPress={() => navigate("Menu")}
            icon={"menu"}
            testID={"main-menu-btn"}
            accessibilityLabel={"main-menu-btn"}
            size={8}
          />
        </Row>
      </MainHeader>
      <Box py={4} mb={8}>
        <TopButtonsRow statusCode={statusCode} door={door} />
      </Box>
      <Stack
        space={16}
        alignItems="center"
        justifyContent="center"
        key={`controller-${auth.language}`}
        flexDirection={{
          base: "column",
          md: "row",
        }}
      >
        <Stack
          onLayout={({ nativeEvent: { layout } }) =>
            setControllerLayout(layout)
          }
          mr={{
            base: 0,
            md: 16,
          }}
        >
          <SaunaController
            saunaId={sauna.saunaId}
            layout={controllerLayout}
            key={`controller-${auth.language}`}
            onStartSauna={handleStartSauna(true)}
            onStopSauna={handleStopSauna}
            onSaunaTemperatureChange={handleSaunaTemperatureChange}
            isHeating={saunaSessionInProgress}
          />
        </Stack>
        <SaunaConfigTabs
          saunaIsHeating={saunaSessionInProgress}
          saunaStatus={restStatus}
          activeToolbarItem={activeToolbarItem}
          onChangeItem={handleToolbarItemChange}
          onHumidityChange={handleSaunaHumidityChange}
          onLightChange={handleSaunaLightChange}
          onTimerChange={handleTimerValueChange}
        />
      </Stack>
    </Stack>
  );
});

export default MainControllerScreen;
