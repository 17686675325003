import { Theme, useTheme } from "native-base";
import { useMemo } from "react";
import {
  ImageStyle,
  TextStyle,
  ViewStyle,
} from "react-native";


type StyleObject = { [key: string]: ViewStyle | TextStyle | ImageStyle };

type StyleFunction = (theme: Theme) => StyleObject;

export const useFrilliceTheme = () => {
  const theme = useTheme();

  return theme
};

export const useThemedStyles = (styleFunction: StyleFunction) => {
  const theme = useFrilliceTheme();

  return useMemo(() => styleFunction(theme), [theme]);
};
