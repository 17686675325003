import React, { useState, useEffect, useCallback } from 'react';
import RNPickerSelect from 'react-native-picker-select';
import { getPickerStyles } from "./styles";
import { useThemedStyles } from '@huum/hooks/useThemedStyles';
import { Icon } from '@huum/components/atoms/icon';


const ScrollPicker = (props: any) => {
  const { items, placeholder, error, selectedValue, disabled } = props;
  const [value, setValue] = useState(selectedValue);

  const styles = useThemedStyles(getPickerStyles);

  useEffect(() => {
    props.onValueChange(value);
  }, [value]);

  const setSelectedValue = (value) => setValue(value);

  const displayIcon = useCallback(() => {
    return <Icon icon="chevron-down" size={8} />;
  }, []);

  return (
    <RNPickerSelect
      disabled={disabled}
      onValueChange={setSelectedValue}
      items={items}
      value={value || selectedValue}
      placeholder={placeholder}
      useNativeAndroidPickerStyle={false}
      style={{
        iconContainer: {
          ...styles.iconContainer,
        },
        viewContainer: {
          ...styles.inputContainer,
        },
        placeholder: {
          ...styles.placeholder,
        },
        inputIOS: {
          ...styles.input,
        },
        inputAndroid: {
          ...styles.input,
        },
        inputWeb: {
          ...styles.input,
        },
      }}
      Icon={displayIcon()}
    />
  );
};


// Dropdown.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
//   placeholder: PropTypes.shape(),
//   onValueChange: PropTypes.func.isRequired,
//   error: PropTypes.bool,
//   selectedValue: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string,
//     PropTypes.shape(),
//   ]),
//   disabled: PropTypes.bool,
// };

// Dropdown.defaultProps = {
//   placeholder: null,
//   error: false,
//   selectedValue: null,
//   disabled: false,
// };

export default ScrollPicker;
