import React, { useMemo } from "react";
import { Row } from "native-base";
import { map, split } from "lodash";
import moment from "moment";
import styles from "./styles";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { cleanNumberValue } from "@huum/utils/validator";
import { padNumber } from "@huum/utils/helpers/timer-helpers";

const TimeSelectorRow = (props: any) => {
  const { modal } = useStores();
  const { start, end, maxHeatingTime, onChange, defaultHeatingTime = 3 } = props;

  const startDateTime = moment(start || undefined);
  const endDateTime = end ? moment(end) :  moment(start || undefined).add(defaultHeatingTime, 'hours');

  const duration = moment.duration(endDateTime.diff(startDateTime));

  const getTimeValues = (key: string, value: any) => {
    const { hour, minute } = value;
    const maxDuration = moment.duration({ hours: maxHeatingTime, minutes: 0 });

    switch (key) {
      case 'start': {
        const startTime = moment(startDateTime).hour(hour).minute(minute);
        const endTime = moment(startTime).add(duration)
        return {
          start: startTime,
          duration,
          end: endTime,
        }
      }
      case 'end': {
        const endTime =  moment(endDateTime).hour(hour).minute(minute);
        const startTime = moment(startDateTime);

        if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day')
        }

        const newDuration = endTime.diff(startDateTime, 'minutes');

        if (newDuration > maxDuration.asMinutes()) {
          startTime.add(newDuration - maxDuration.asMinutes(), 'minutes')
        }

        return {
          start: startTime,
          duration: moment.duration(endTime.diff(startTime)),
          end: endTime
        }
      }
      case 'duration': {
        const duration = moment.duration({ hours: hour, minutes: minute });
        const chosenDurationLongerThanMaximum = duration.asMinutes() > maxDuration.asMinutes();

        if (chosenDurationLongerThanMaximum) {
          return {
            start: startDateTime,
            duration: maxDuration,
            end: moment(startDateTime).add(maxDuration)
          }
        } else {
          return {
            start: startDateTime,
            duration: duration,
            end: moment(startDateTime).add(duration.asMinutes(), 'minutes')
          }
        }
      }
      default:
        return {
          start: startDateTime,
          duration,
          end: endDateTime,
        }
    }
  };

  const handleTimeChange = (key: any, value: any) => {
    const values = getTimeValues(key, value)
    onChange && onChange(values);
  };

  const handleTimePress = (key: any, time: string, isDuration?: boolean, maxHours?: number) => {
    const [hour, minute] = split(time, /[^0-9]/g);

    const minuteNumber = cleanNumberValue(minute);
    const roundedMinute = minuteNumber > 0 ? minuteNumber + (5 - minuteNumber % 5) : minuteNumber;

    modal.openModal("timerSelect", {
      hour: cleanNumberValue(hour),
      minute: isDuration ? minuteNumber : roundedMinute,
      isDuration,
      onValueSelect: (value: any) => handleTimeChange(key, value),
      maxHours,
      title: key,
    });
  };

  const tabs = useMemo(() => {
    return [
      {
        key: "start",
        title: translate("button.start"),
        time: `${startDateTime.hours()}:${padNumber(startDateTime.minutes())}`,
      },
      {
        key: "duration",
        title: translate("button.duration"),
        time: `${duration.get("hours")} h ${padNumber(
          duration.get("minutes")
        )} m`,
        isDuration: true,
        maxHours: maxHeatingTime,
      },
      {
        key: "end",
        title: translate("button.end"),
        time: `${endDateTime.hours()}:${padNumber(endDateTime.minutes())}`,
      },
    ];
  }, [startDateTime, endDateTime, duration]);

  const Tab = ({ item }: { item: any }) => {
    const { title, time, isDuration, maxHours, key } = item;

    return (
      <Touchable onPress={() => handleTimePress(key, time, isDuration, maxHours)}>
        <Text style={styles.labelBold16}>{time}</Text>
        <Text style={styles.label14}>{title}</Text>
      </Touchable>
    );
  };

  return (
    <Row justifyContent={"space-between"}  paddingX={2}>
      {map(tabs, (tab, index) => (
        <Tab key={index} item={tab} />
      ))}
    </Row>
  );
};

export default TimeSelectorRow;
