import Button from "@huum/components/atoms/button";
import { Text } from "@huum/components/atoms/text";
import { translate } from "@huum/i18n";
import { Row, Stack, View, useTheme } from "native-base";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { map } from "lodash";
import moment from "moment";



const FreeRemoteControlView = (props: { onContinue: () => void }) => {
  const { sauna } = useStores();
  const { config, colors } = useTheme();

  const { onContinue } = props;

  const bulletListItems = [
    translate('text.core_app_features'),
    translate('text.secured_server'),
    translate('text.unlimited_gsm_data'),
    translate('text.software_updates'),
    translate('text.unlimited_users'),
  ];

  const Bullets = map(bulletListItems, (item) => (
    <Row alignItems="center" key={item} space={2}>
      <View size={1} borderRadius={10} backgroundColor="primaryDark" />
      <Text preset="subtitle2">{item}</Text>
    </Row>
  ))

  return (
    <Stack space={8}>
      <Stack flex={1} alignItems="center" space={4} mt={8}>
          <Text preset="h4">
            {translate('title.welcome')}
          </Text>
          <Text textAlign="center" >
            {translate('text.your_free_remote_year_has_started')}
          </Text>
      </Stack>
      <Stack space={2}>
        <Text preset="subtitle1" py={4}>
          {translate('text.annual_server_cost_will_be', { paymentEndDate: moment(sauna.paymentEndDate || moment().add(1, "year")).format('DD/MM/YYYY') })}
          {' '}
          <Text>
            {translate('text.annual_server_cost', { amount: config.serverCost })}
          </Text>
        </Text>
        <Text>
          {translate('text.annual_cost_includes')}
        </Text>
        {Bullets}
      </Stack>
      <Stack space={4}>
        <Text textAlign="center" preset="caption">
          {translate('text.sauna_activate_success_gsm_text')} <Text preset="caption" fontWeight="bold">{config.helpEmail}</Text>
        </Text>
        <Button onPress={onContinue} label={translate('button.continue')} />
      </Stack>
      
    </Stack>
      
  )
}

export default FreeRemoteControlView;