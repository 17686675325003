import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";
import { selectStatisticsTemperatureMonths } from "@huum/utils/helpers/statistics-selectors";
import { flatten, map } from "lodash";

export interface getTemperaturesParams {
  session: string | null;
  monthData: string;
}

export async function getTemperatures(data: getTemperaturesParams) {
  const { session, monthData } = data;
  const selectedMonths = selectStatisticsTemperatureMonths(monthData);

  const response: any[] = []
  for(let i=0; i < selectedMonths.length; i++) {
    const month = selectedMonths[i]
    const result = await api.huum.get("/action/get_temperatures", {
       version: 3, // must be with version
       session,
       month,
     });
     response.push(result);
  }

  const listitems = flatten(map(response, (item) => item.data));

  return listitems;
}
