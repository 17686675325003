import { Icon } from "@huum/components/atoms/icon";
import {
  IconProps,
} from "@huum/components/atoms/icon/icon.props";
import { getHitSlop } from "@huum/utils/hitSlop";
import React from "react";
import { Pressable } from "react-native";

interface IconButtonProps extends IconProps {
  onPress?: () => void;
  visible?: boolean;
  disabled?: boolean;
}

export const IconButton = (props: IconButtonProps) => {
  const { onPress, icon, visible = true, disabled, ...rest } = props;

  if (!visible) {
    return null;
  }

  return (
    <Pressable hitSlop={getHitSlop(8)} onPress={onPress}>
      <Icon icon={icon} {...rest} />
    </Pressable>
  );
};
