import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get } from "lodash";
import { useCallback, useMemo } from "react";
import { getTemperatures } from "@huum/api/requests/statistics";
import moment from "moment";


interface getStatisticsParams {
  month: string;
}

export const useStatistics = () => {
  const {
    mutate,
    data,
    isError,
    isSuccess,
    isLoading
  } = useMutation(getTemperatures);
  const { auth } = useStores();

  const statisticsResults = useMemo(() => {
    return data;
  }, [data]);

  const session = get(auth, "sessionHash", null);


  const getStatistics = useCallback((value: getStatisticsParams) => {
    mutate({
      session,
      monthData: moment(value.month).format("YYYY-MM"), // request must be made in this format
    });
  }, []);


  return { getStatistics, statisticsResults, isError, isSuccess, isLoading };
};


