import { padStart, toString } from 'lodash';
import moment from 'moment';

export const padNumber = (value: number | undefined) => padStart(toString(value), 2);

export const getRemainderToNextFive = (value: number) => 5 - (value % 5);


interface TimeSelectValues {
  minute: number;
  hour: number;
}

interface TimerValuesConfig {
  duration: TimeSelectValues;
  start: TimeSelectValues;
  end: TimeSelectValues;
  date: string;
  maxDurationHours: number;
}

export const getTimerValues = (config: TimerValuesConfig) => (key: string, value: TimeSelectValues) => {
  const { hour, minute } = value;
  const { start, end, duration, date, maxDurationHours } = config;

  const maxDuration = moment.duration(maxDurationHours, 'hours');
  const startDateTime = moment(date).hour(start.hour).minute(start.minute);;
  const endDateTime = moment(date).hour(end.hour).minute(end.minute);

  switch (key) {
    case 'start': {
      const startTime = moment(startDateTime).hour(hour).minute(minute);
      const endTime = moment(startTime).add(duration)

      return {
        start: {
          hour: startTime.hour(),
          minute: startTime.minute()
        },
        duration,
        end: {
          hour: endTime.hour(),
          minute: endTime.minute()
        },
      }
    }
    case 'end': {
      const endTime =  moment(endDateTime).hour(hour).minute(minute);
      const startTime = moment(startDateTime);

      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day')
      }

      const newDuration = endTime.diff(startDateTime, 'minutes');

      if (newDuration > maxDuration.asMinutes()) {
        startTime.add(newDuration - maxDuration.asMinutes(), 'minutes')
      }

      return {
        start: {
          hour: startTime.hour(),
          minute: startTime.minute()
        },
        duration: {
          hour: moment.duration(newDuration, 'minutes').hours(),
          minute: moment.duration(newDuration, 'minutes').minutes()
        },
        end: {
          hour: endTime.hour(),
          minute: endTime.minute()
        }
      }
    }
    case 'duration': {
      const duration = moment.duration({ hours: hour, minutes: minute });
      const chosenDurationLongerThanMaximum = duration.asMinutes() > maxDuration.asMinutes();
      const endTime = moment(startDateTime).add(maxDuration)
      if (chosenDurationLongerThanMaximum) {
        return {
          start: {
            hour: startDateTime.hour(),
            minute: startDateTime.minute()
          },
          duration: {
            hour: maxDuration.hours(),
            minute: maxDuration.minutes()
          },
          end: {
            hour: endTime.hour(),
            minute: endTime.minute()
          }
        }
      } else {
        const endTime = moment(startDateTime).add(duration.asMinutes(), 'minutes')
        return {
          start,
          duration: value,
          end: {
            hour: endTime.hour(),
            minute: endTime.minute()
          }
        }
      }
    }
    default:
      return {
        start,
        duration,
        end,
      }
  }
};