import { MODAL_TYPES } from "@huum/components/organisms/modal-root/modal-types-map";
import { keys, last } from "lodash";
import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const ModalStoreModel = types
  .model("ModalStore")
  .props({
    modalShouldClose: types.optional(types.boolean, false),
    modalType: types.maybeNull(types.string),
    modalProps: types.maybeNull(types.frozen()),
    modalStash: types.optional(types.array(types.frozen()), []),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    openModal: (modalType: string, props: any) => {
      if (self.modalType) {
        self.modalStash.push({
          modalType: self.modalType,
          modalProps: self.modalProps,
        })
      } else if (props?.clearStash) {
        self.modalStash.clear();
      }
      self.modalShouldClose = false;
      self.modalType = modalType;
      self.modalProps = props;
    },
    setModalProps: (props: any) => {
      self.modalProps = {
        ...(self.modalProps || {}),
        ...props,
      };
    },
    popModal: () => {
      const previousModal = last(self.modalStash)
      self.modalType = previousModal?.modalType || null;
      self.modalProps = previousModal?.modalProps || null;;
      return self.modalStash.pop()
    },
    closeModal: () => {
      self.modalShouldClose = true;
    },
    cleanModalState: (clearStash: boolean = false) => {
      self.modalType = null;
      self.modalProps = null;
      if (clearStash) {
        self.modalStash.clear();
      }
      self.modalShouldClose = false;
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

interface ModalStoreType extends Instance<typeof ModalStoreModel>{
  modalType: keyof typeof MODAL_TYPES | null;
}
export interface ModalStore extends ModalStoreType {}
type ModalStoreSnapshotType = SnapshotOut<typeof ModalStoreModel>
export interface ModalStoreSnapshot extends ModalStoreSnapshotType {}
export const createModalStoreDefaultModel = () => types.optional(ModalStoreModel, {})
