import { palette } from '@huum/theme/helpers';

import { StyleSheet } from 'react-native';
import { Theme } from 'native-base';

export const getLoaderStyles = (theme: Theme) => {
  const { colors, space, shadows } = theme;
  return StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: palette.white[100],
    justifyContent: 'center',
    alignItems: 'center',
  },
  animationContainer: {
    height: 150,
    width: 100,
    paddingTop: 20,
    alignItems: 'center',
    overflow: 'hidden',
  },
  dropImage: {
    height: 50,
    width: 30,
    resizeMode: 'contain',
    tintColor: "palette.green[200]",
  },
});
}
