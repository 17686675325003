//@ts-nocheck
import moment from 'moment';
import { forEach } from 'lodash';
import { translate } from '@huum/i18n';

export const getAverageDailyTemperatures = (readings) => {
  const dailyAverages = new Array(7).fill(0);
  let readingsTimesDurations = [];

  const getDailyAverage = (arrayOfRxT, duration) => {
    const sum = arrayOfRxT.reduce((a, b) => a + b, 0);

    const dailyAverage = sum / duration;
    return dailyAverage;
  };

  let currentDay = moment(new Date(readings[0].changeTime * 1000));
  let currentDayIndex = (currentDay.day() + 6) % 7;
  let currentDayDuration = 0;

  // run through all the readings of the week to get the temperature for each day
  forEach(readings, (reading, i) => {
    const readingDay = moment(new Date(reading.changeTime * 1000));
    const readingDayIndex = (readingDay.day() + 6) % 7;

    if (currentDayIndex === readingDayIndex && i !== readings.length - 1) {
      const nextReading = readings[i + 1] || readings[i];
      const { temperature, changeTime: currentTime } = reading;
      const { changeTime: nextTime } = nextReading;
      const duration = nextTime - currentTime;

      readingsTimesDurations.push(temperature * duration);
      currentDayDuration += duration;
    } else {
      const dailyAverage = getDailyAverage(readingsTimesDurations, currentDayDuration);
      dailyAverages[currentDayIndex] = dailyAverage;

      readingsTimesDurations = [];
      currentDay = readingDay;
      currentDayIndex = readingDayIndex;
      currentDayDuration = 0;
    }
  });

  return dailyAverages;
};

const mapSessionsIntoBars = (sessionsData) => {
  const defaultDay = {
    averageTemp: 0,
    averageTarget: 0,
    sessionsCount: 0,
    totalDuration: 0,
  };

  const barData = [
    { day: translate('label.mondayShort'), ...defaultDay },
    { day: translate('label.tuesdayShort'), ...defaultDay },
    { day: translate('label.wednesdayShort'), ...defaultDay },
    { day: translate('label.thursdayShort'), ...defaultDay },
    { day: translate('label.fridayShort'), ...defaultDay },
    { day: translate('label.saturdayShort'), ...defaultDay },
    { day: translate('label.sundayShort'), ...defaultDay },
  ];

  // add up totals for each day
  forEach(sessionsData, (session) => {
    const indexOfWeekday = moment(session.from).format('e');
    const { durationMillSec, averageTemperature, targetTemperature } = session;

    barData[indexOfWeekday].totalDuration += durationMillSec;
    barData[indexOfWeekday].averageTemp += averageTemperature * durationMillSec;
    barData[indexOfWeekday].averageTarget += targetTemperature * durationMillSec;

    if (targetTemperature > 0) {
      barData[indexOfWeekday].sessionsCount += 1;
    }
  });

  // get averages for each day
  forEach(barData, (day) => {
    // eslint-disable-next-line no-param-reassign
    day.averageTemp = (day.averageTemp / day.totalDuration) || day.averageTemp;
    // eslint-disable-next-line no-param-reassign
    day.averageTarget = (day.averageTarget / day.totalDuration) || day.averageTarget;
  });

  return barData;
};

const getBarGraphValues = (sessionsData) => {
  const barsData = mapSessionsIntoBars(sessionsData);

  return {
    barsData,
  };
};

export default getBarGraphValues;
