import { IMSTArray, Instance, IType, SnapshotOut, types } from "mobx-state-tree"
import uuid from "react-native-uuid"

export interface ToastMessage {
  message: string
  type: "success" | "error" | "info"
  closable?: boolean
  location?: "bottom" | "top"
  duration?: number
}

/**
 * Model description here for TypeScript hints.
 */
export const ToastStoreModel = types
  .model("ToastStore")
  .props({
    messages: types.optional(types.array(types.frozen()), []),
  })
  .views(self => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions(self => ({
    showToast: (toastMessage: ToastMessage) => {
      self.messages.push({
        ...toastMessage,
        id: uuid.v4()
      })
    },
    hideToast: (toastId: string) => {
      self.messages = self.messages.filter(message => message.id !== toastId) as IMSTArray<
        IType<any, any, any>
      >
    },
    clearAll: () => {
      self.messages.clear()
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type ToastStoreType = Instance<typeof ToastStoreModel>
export interface ToastStore extends ToastStoreType {}
type ToastStoreSnapshotType = SnapshotOut<typeof ToastStoreModel>
export interface ToastStoreSnapshot extends ToastStoreSnapshotType {}
export const createToastStoreDefaultModel = () => types.optional(ToastStoreModel, {})
