import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { map, toUpper } from "lodash";
import { Text } from "@huum/components/atoms/text";
import { Pressable, Row } from "native-base";

interface WeekDaysSelectorProps {
  onPress: (day: number) => void;
  selectedDays?: number[];
  disabled?: boolean;
}

// dow can be changed, to change the first day of the week
// by default it is 0 (Sunday)
// NB! when changing dow, CalendarDatesList needs to be adjusted
moment.updateLocale(moment.locale(), { week: { dow: 1 } });

const WeekDaysSelector = (props: WeekDaysSelectorProps) => {
  const { selectedDays, disabled, onPress } = props;

  const weekDays = moment.weekdaysMin(true);

  const checkIsDaySelected = useCallback(
    (indexOfDay: number) => selectedDays?.[indexOfDay] === 1,
    [selectedDays]
  );

  const handleOnItemPress = useCallback(
    (index: number) => {
      onPress(index);
    },
    [onPress]
  );

  const SelectItem = useCallback(
    (item: any) => {
      const { item: day, index } = item;
      const isSelected = checkIsDaySelected(index);
      return (
        <Pressable
          onPress={() => !disabled && handleOnItemPress(index)}
          key={day}
          bg={isSelected ? "green.200" : "transparent"}
          width={8}
          alignItems="center"
          borderRadius={8}
        >
          <Text fontSize="md" >
            {toUpper(day) || ""}
          </Text>
        </Pressable>
      );
    },
    [disabled, handleOnItemPress, checkIsDaySelected]
  );

  const weekDaysRow = useMemo(
    () =>
      map(weekDays, (day, index) => <SelectItem index={index} item={day} />),
    []
  );

  return (
    <Row space={2} alignItems="center" justifyContent="space-between">
      {weekDaysRow}
    </Row>
  );
};

export default WeekDaysSelector;
