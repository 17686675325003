import React, { useCallback, useState } from "react"
import { Box, Row, useTheme } from 'native-base';
import { Icon } from "@huum/components/atoms/icon";
import { Text } from "@huum/components/atoms/text";
import { useStyles } from "./styles";
import SliderSelect from "@huum/components/molecules/slider-select";

interface BrightnessControllerProps {
  defaultValue?: number;
  onChange: (value: number) => void;
}

const BrightnessController = (props: BrightnessControllerProps) => {
  const {
    defaultValue,
    onChange
  } = props;

  const [brightness, seBrightness] = useState<number>(defaultValue || 100);

  const styles = useStyles();

  const onSlideComplete = useCallback((value: number) => {
      seBrightness(value);
      onChange(value);
  }, [onChange])

  return (
    <Row alignItems="center" justifyContent="space-between">
        <Box width={8} alignItems="center">
          <Icon
            tint="primaryDark"
            icon="lights"
          />
        </Box>
        <Box>
          <SliderSelect
            style={styles.slider}
            step={1}
            showValue={false}
            value={brightness}
            onChange={seBrightness}
            onChangeEnd={onSlideComplete}
          />
        </Box>
        <Box width={10}>
          <Text fontSize="sm">{`${brightness}%`}</Text>
        </Box>
      </Row>
  )
}

export default BrightnessController;
