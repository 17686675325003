import {
  size,
  toNumber,
  includes,
  split,
  replace,
  join,
  truncate,
} from "lodash";

const cleanNumberValue = (value: string) => replace(value, /[^0-9]/g, "");

const getMonthValue = (month: any) => {
  const monthAsNumber = cleanNumberValue(month);

  // if value is one digit and from 2-9
  if (size(monthAsNumber) < 2 && /[2-9]/.test(monthAsNumber)) {
    return `0${monthAsNumber}`;
  }

  if (toNumber(monthAsNumber) > 12) {
    return "12";
  }

  return monthAsNumber;
};

export const formatCvvNumber = (value: any, ref: any) => {
  const valueAsNumber = cleanNumberValue(value);
  if (size(valueAsNumber) === 3) {
    ref.current?.focus();
  }
  return valueAsNumber;
};

export const formatCardNumber = (value: any, ref: any) => {
  const valueAsNumber = cleanNumberValue(value);
  if (size(valueAsNumber) === 16) {
    ref?.current?.focus();
  }
  return join(valueAsNumber.match(/.{1,4}/g), " ");
};

export const formatExpiryDate = (value: any, ref: any) => {

  const valuesArray = includes(value, "/") ? split(value, "/") : [value];
  const month = getMonthValue(valuesArray[0]);
  const year = cleanNumberValue(
    truncate(valuesArray[1], { length: 4, separator: "" })
  );

  if (!year && size(month) < 2) {
    return month;
  }

  if (size(month) === 2 && size(year) === 4) {
    ref?.current?.focus();
  }

  return `${month}/${year}`;
};
