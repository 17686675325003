
import { StyleSheet } from 'react-native';
import { useTheme } from 'native-base';

export const useBookingEventsAgendaStyles = () => {
  const { colors, shadows } = useTheme();

  return StyleSheet.create({
    bookingListItem: {
      height: 70,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 35,
      borderBottomWidth: 1,
      borderBottomColor: colors.primaryLight,
    },
    bookingListItemText: {
      fontSize: 14,
    },
    plusIcon: {
      height: 16,
      width: 16,
      marginRight: 7,
      tintColor: colors.primaryMedium,
    },
    bookButton: {
      flexDirection: 'row',
    },
    bookingEvent: {
      position: 'absolute',
      borderRadius: 10,
      backgroundColor: colors.primaryMedium,
      paddingHorizontal: 16,
      paddingVertical: 13,
      width: 233,
      right: 31,
      ...shadows[1]
    },
    eventContentText: {
      color: colors.white[100],
      fontSize: 14,
      marginTop: 5,
    },
    eventDescription: {
      color: colors.white,
    },
  });
}

