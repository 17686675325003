import { Text } from "@huum/components/atoms/text";
import TextButton from "@huum/components/molecules/text-button";
import CalendarBookingItem from "@huum/components/organisms/calendar-booking-item";
import { translate } from "@huum/i18n";
import { useStores } from "@huum/store";
import { Box, Center, FlatList, Stack } from "native-base";
import { useCallback, useState } from "react";

 const Bookings = ({ data, listKey }) => {
  const { modal } = useStores();

  const showBookingDetailsModal = useCallback(
    (item: any) => () => {
      modal.openModal("bookingDetail", {
        ...item,
        onContactHostPress: () => null,
        onEdit: () => null,
        onDelete: () => null,
      });
    },
    [modal]
  );

  const CalendarItem = ({ item }: {item: any}) => {
    return (
    <CalendarBookingItem
      onPress={showBookingDetailsModal(item)}
      {...item}
    />
    )
  };

  const EmptyBookingsListComponent = () => (
    <Text mt={4} textAlign="center" fontSize={14}>
      {translate('placeholder.empty_booking_list')}
    </Text>
  );

  return (
  <FlatList
    data={data}
    keyExtractor={(item: any) => `${listKey}${item.key || item.id}`}
    renderItem={CalendarItem}
    ListEmptyComponent={EmptyBookingsListComponent}
    removeClippedSubviews
    backgroundColor="white.100"
    contentContainerStyle={{ padding: 16 }}
  />
)};

const BookingListView = (props: { data: any, onViewModeChange: (viewMode?: "calendar" | "list") => void, isMyBookingList: boolean }) => {
  const { data, onViewModeChange, isMyBookingList } = props;
  const [viewMode, setViewMode] = useState<"calendar" | "list">("calendar")

  const handleChangeViewMode = useCallback((viewMode: "calendar" | "list") => {
    onViewModeChange(viewMode)
    setViewMode(viewMode)
  }, [onViewModeChange])

  return (
    <Stack flex={1} backgroundColor="white.100">
      <Bookings
        listKey="next"
        data={data}
      />
      {!isMyBookingList && (
         <Center>
            <TextButton
              title={viewMode === "calendar" ? translate('button.view_all_bookings') : translate('button.calendar_view')}
              onPress={() => handleChangeViewMode(viewMode === "calendar" ? "list" : "calendar")}
            />
       </Center>
      )}
    </Stack>
  )
}

export default BookingListView;