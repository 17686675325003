import api from "@huum/services/api";
import { getGeneralApiProblem } from "@huum/services/api/api-problem";


export interface SaunaParams {
  saunaID: string;
  saunaCode: string;
  session: string | null;
}

export interface SaunaStatusResponse {
  maxHeatingTime?: number;
  statusCode?: number
  door?: boolean
  showModal?: boolean
  config?: number;
  steamerError?: number;
  humidity?: number;
  light?: number;
  paymentEndDate?: string;
  temperature?: number;
  targetTemperature?: number;
  startDate?: number;
  endDate?: number;
  remoteSafetyState?: "safe" | "notSafe" | "disabled"
}

interface SaunaSessionRequest {
  type: 'start' | 'modify' | 'delete' | 'break' | 'light' | "update";
  session: string | null;
  saunaId: string;
  targetTemperature?: number;
  startDate?: number;
  endDate?: number;
  humidity?: number;
  isPrivate?: boolean;
}

interface SaunaSessionUpdate {
  session: string;
  saunaId: string;
}

export async function connectSauna(data: SaunaParams) {
  const { saunaID, saunaCode, session } = data;
  const response = await api.huum.get("action/changesaunaid/", {
    saunaID,
    saunaCode,
    version: 3,
    session,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as any;
}


export async function fetchSaunaStatus(data: any) {
  const { session, saunaId } = data;
  const response = await api.huum.get("action/status", {
    version: 3,
    session,
    saunaId,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as SaunaStatusResponse;
}


export async function saunaSessionRequest(data: SaunaSessionRequest) {
  const { session, type, ...rest } = data;
  const response = await api.huum.post(`action/${type}`, {
    version: 3,
    session,
    ...rest,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as SaunaStatusResponse;
}

export async function stopSaunaSessionRequest(data: SaunaSessionUpdate) {
  const { session, saunaId } = data;
  const response = await api.huum.get(`action/stop_sauna`, {
    version: 3,
    saunaId,
    session,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as SaunaStatusResponse;
}



export async function updateSaunaHumidityRequest(data: SaunaSessionUpdate) {
  const { session, saunaId } = data;
  const response = await api.huum.get(`action/modify`, {
    version: 3,
    session,
    saunaId,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as SaunaStatusResponse;
}

export async function emergencyBreakRequest(data: SaunaSessionUpdate) {
  const { session, saunaId } = data;
  const response = await api.huum.get(`action/break`, {
    version: 3,
    session,
    saunaId,
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as SaunaStatusResponse;
}

export async function updateControllerSoftwareRequest(data: SaunaSessionUpdate) {
  const { session, saunaId } = data;
  const response = await api.huum.get(`action/update`, {
    version: 3,
    session,
    saunaId
  });

  if (!response.ok) {
    const problem = getGeneralApiProblem(response);
    if (problem) throw problem;
  }

  return response.data as SaunaStatusResponse;
}