import { palette } from "../helpers";


export default {
  ...palette,
  primary: palette.green[500],
  primaryDark: palette.green[500],
  primaryMedium: palette.green[500],
  primaryLight: palette.green[200],
  iconColor: palette.green[500],
  background: palette.grey[5],
};