import { changeLanguage, TLocale } from "../i18n";

// let ReactotronDev;
// if (__DEV__) {
//   const { Reactotron } = require("../services/reactotron");
//   ReactotronDev = Reactotron;
// }

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    if (__DEV__) {
      // dev-only services
      // this.reactotron = new ReactotronDev();
    }
  }

  async setup() {
    // allow each service to setup
    if (__DEV__) {
      // await this.reactotron.setup();
    }
  }

  setLanguage(langCode: TLocale) {
    changeLanguage(langCode);
  }

  /**
   * Reactotron is only available in dev.
   */
  // reactotron: typeof ReactotronDev;
}
