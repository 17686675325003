import { I18n } from "i18n-js";
import { keys } from "lodash";
import et from './locales/et.json';
import en from './locales/en.json';
import de from "./locales/de.json";
import fi from "./locales/fi.json";
import fr from "./locales/fr.json";
import pl from "./locales/pl.json";
import ru from "./locales/ru.json";
import it from "./locales/en.json";
import se from "./locales/en.json";
import moment from "moment";


const locales = { et, en, de, fi, fr, it, pl, ru, se };
export const i18n = new I18n();
i18n.enableFallback = true;
i18n.translations = locales;

i18n.locale = 'en';

/**
 * Builds up valid keypaths for translations.
 * Update to your default locale of choice if not English.
 */
type DefaultLocale = typeof en;
export type TxKeyPath = RecursiveKeyOf<DefaultLocale>;

type RecursiveKeyOf<TObj extends Record<string, any>> = {
  [TKey in keyof TObj & string]: TObj[TKey] extends Record<string, any>
    ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
    : `${TKey}`;
}[keyof TObj & string];


export type TLocale = keyof typeof locales;

export const languageCodes = keys(locales) as TLocale[];

export const changeLanguage = (languageCode: TLocale) => {
  i18n.locale = languageCode;
  moment.locale(languageCode);
};
