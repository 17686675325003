const celciusToFahrenheit = (celsius: number) => {
  const celsiusToFahrenheit = ((celsius * 9) / 5) + 32;
  return Math.round(celsiusToFahrenheit);
};


// const fahrenheitToCelsius = (fahrenheit) => {
//   const fahrenheitToCelsius = ((fahrenheit - 32) * 5) / 9;
//   return fahrenheitToCelsius;
// };

export const getTemperatureUnit = (unit: string) => {
  return unit === 'fahrenheit' ? '°F' : '°C';
};

export const getTemperatureValue = (temperature: number, unit?: string) => {
  if (unit === 'fahrenheit') {
    return celciusToFahrenheit(temperature);
  }
  return temperature;
};
