import { Checkbox as BaseCheckbox, ICheckboxProps } from "native-base";
import { Icon } from "../icon";

interface CheckboxProps extends ICheckboxProps {
  label: string | React.ReactElement;
  value: string;
  onChange: () => void;
}

const Checkbox = (props: CheckboxProps) => {
  const { label, value, onChange } = props;

  return (
    <BaseCheckbox
      value={value}
      onChange={onChange}
      size="sm"
      colorScheme="green"
    >
      {label}
    </BaseCheckbox>
  );
};

export default Checkbox;
