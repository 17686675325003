import React, { useState } from 'react';
import { View, ViewStyle } from 'react-native';
import { getStyles } from './input.styles';
import { HelperTextProps, IconButtonProps, InputProps } from './input.props';
import { Touchable } from '../touchable/touchable';
import { FormControl, Input as BaseInput, Box } from 'native-base';
import { useThemedStyles } from '@huum/hooks/useThemedStyles';
import { Text } from '../text';
import { Icon } from '../icon';

// currently we have no presets, but that changes quickly when you build your app.
const PRESETS: { [name: string]: ViewStyle } = {
  default: {},
};

/**
 * A component which has a label and an input together.
 */
export function Input(props: InputProps) {
  const [isFocused, setIsFocused] = useState(false);
  const {
    label,
    preset = 'default',
    style: styleOverride,
    iconLeft,
    iconRight,
    iconSize,
    onIconLeftPress,
    onIconRightPress,
    helperText,
    helperTextType,
    value,
    ...rest
  } = props;

  const styles = useThemedStyles(getStyles);

  const IconButton = (props: IconButtonProps) => {
    const { icon, action, isActive } = props;
    if (!icon) {
      return null;
    }
  
    return (
      <Touchable style={styles.iconWrapper} onPress={action}>
        <Icon
          size={iconSize || 8}
          // tint={tintColor}
          icon={icon}
        />
      </Touchable>
    );
  };
  

  return (
    <Box flex={1}>
      <FormControl>
        <BaseInput
          value={value}
          variant="rounded"
          autoCorrect={false}
          InputRightElement={
            <IconButton
              isActive={isFocused}
              icon={iconRight}
              action={onIconRightPress}
            />
          }
          {...rest}
        />
        <FormControl.HelperText>{helperText}</FormControl.HelperText>
      </FormControl>
    </Box>
  );
}
