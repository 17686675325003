import React from "react";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";
import { getSupprtIconButtonStyles } from "./styles";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { Box, Center, Pressable } from "native-base";
import { TxKeyPath } from "@huum/i18n";
import { IconButton } from "@huum/components/molecules/icon-button";
import { translate } from "@huum/i18n";
import { Icon } from "@huum/components/atoms/icon";
import { IconType } from "@huum/components/atoms/icon/icon.props";


interface SupportButtonProps {
  onPress?: () => void;
  buttonIcon?: IconType;
}

const SupportIconButton = (props: SupportButtonProps) => {
  const { onPress } = props;

  const styles = useThemedStyles(getSupprtIconButtonStyles);

  return (
    <Center>
      <Touchable onPress={onPress}>
        <Icon
          icon="support"
          containerStyle={styles.iconContainer}
        />
        <Text preset="subtitle1" fontWeight="bold" >{translate("button.support_uppercase")}</Text>
      </Touchable>
    </Center>
  );
};

export default SupportIconButton;
