import { connectSauna, saunaSessionRequest } from "@huum/api/requests/sauna";
import { useStores } from "@huum/store";
import { useMutation } from "@tanstack/react-query";
import { get, toNumber } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { isNil, isNull } from "lodash";
import { translate } from "@huum/i18n/translate";
import { navigate } from "@huum/services/NavigationService";


interface SaunaIdParams {
  saunaID: string;
  saunaCode: string;
}

export const useSaunaConnect = (withModal?: boolean) => {
  const { auth, sauna, modal } = useStores();
  const { mutate, data: response, isError, isSuccess } = useMutation(connectSauna);
  const [showCodeInput, setShowCodeInput] = useState(false)
  const [saunaIsConnected, setSaunaIsConnected] = useState(false)

  const session = get(auth, "sessionHash", null);

  const saunaConnect = useCallback(
    (values: SaunaIdParams) => {
      mutate(
        { ...values, session },
        {
          onSuccess: (data: any) => {
            if (isNull(data?.paymentEndDate) || data?.paymentEndDate) {
              sauna.setPaymentEndDate(data.paymentEndDate);
              modal.closeModal();
            } 
            
            if (!isNil(data?.isFirstUser)) {
              sauna.setFirstUser(data.isFirstUser);
            }

            if (!values.saunaCode) {
              setShowCodeInput(true)
            }

            if (values.saunaCode) {
              sauna.setSaunaId(values.saunaID)

              if (withModal && data?.needsPurchase) {
                modal.openModal('confirmModal', {
                  content:  translate('text.app_usage_payment_info_confirm', { price: data.needsPurchase }),
                  onConfirm: () => navigate('paymentSettings'),
                  modalTitle: translate('title.sauna_has_been_activated'),
                  contentText: translate("text.app_usage_payment_info_confirm"),
                  confirmButtonLabel: translate('button.confirm'),
                })
              } 
              setSaunaIsConnected(true)
              if (toNumber(values.saunaID) >= 500000) {
                navigate('smartConfigConnect')
                modal.closeModal()
                return 
              }
            }
          },
          onError: () => {
            setSaunaIsConnected(false)
          }
        },
      );
    },
    [mutate, response, withModal]
  );

  const setSaunaIsPrivate = useCallback((isPrivate: boolean) => {
    sauna.setSaunaIsPrivate(!!isPrivate)
    saunaSessionRequest({ isPrivate, type: "update", session })
  }, [session])

  return { saunaConnect, isError, isSuccess: saunaIsConnected, showCodeInput, setSaunaIsPrivate };
};
