import { StyleSheet } from "react-native";
import { Theme } from "native-base";
import { palette } from "@huum/theme/helpers";

export const getStyles = (theme: Theme) => {
  const { colors, space } = theme;
  return StyleSheet.create({
    text: {
      fontWeight: "400",
      color: palette.dimmedText
    },
    activeText: {
      fontWeight: "bold",
    },
  });
};
