import { MainHeader } from "@huum/components/molecules/header/main-header";
import { IconButton } from "@huum/components/molecules/icon-button";
import { useSaunaController } from "@huum/hooks/api/useSaunaController";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { navigate } from "@huum/services/NavigationService";
import { useStores } from "@huum/store";
import { ActiveToolbarItem } from "@huum/utils/sauna-toolbar";
import moment, { Moment } from "moment";
import { Pressable, Center, Row, Stack, ScrollView, Box } from "native-base";
import {useCallback, useMemo, useState } from "react";
import { Text } from '@huum/components/atoms/text';;
import { translate } from "@huum/i18n";
import { observer } from "mobx-react-lite";
import NewSaunaEmptyController from "@huum/components/organisms/sauna-controller/new-sauna-empty-controller";
import { map } from "lodash";
import SaunaController from "../../components/sauna-controller";

type ToolbarItem = keyof typeof ActiveToolbarItem;

const SaunaSelectScreen = observer(() => {
  const { sauna, modal, auth } = useStores();
  const { door, remoteSafetyState, ...restStatus } = useSaunaStatus();
  const { statusCode } = restStatus || {};
  const { startSaunaSession, stopSaunaSession, modifySaunaSession } =
    useSaunaController();
  const [controllerIsDisabled, setControllerIsDisabled] =
    useState<boolean>(false);
  const [activeToolbarItem, setActiveToolbarItem] = useState<ToolbarItem>(
    ActiveToolbarItem.HEATING
  );
  const [timerValue, setTimerValue] = useState<{
    start: Moment | null;
    end: Moment | null;
  }>({ start: null, end: null });


  const handleSaunaSelect = useCallback(
    (saunaId: string) => () => {
      sauna.setSaunaId(saunaId);
      navigate("controller");
    },
    []
  );

  const saunasList = useMemo(() => {
    return map(sauna.saunas(), (sauna) => (
      {
        saunaId: sauna.saunaId,
        isHeating: false, // todo
        name: sauna.saunaId || "Sauna 1",
        targetTemp: sauna.latestTargetTemp || 90
      }
    ))
  }, [sauna.saunas])


  return (
    <Stack flex={1}>
      <MainHeader withBackNavigation={false}>
        <Row space={4} alignItems="center">
          <IconButton
            onPress={() => navigate("Menu")}
            icon={"menu"}
            testID={"main-menu-btn"}
            accessibilityLabel={"main-menu-btn"}
            size={8}
          />
          <IconButton
            onPress={() => navigate("controller")}
            icon={"close"}
            testID={"main-menu-btn"}
            accessibilityLabel={"main-menu-btn"}
            size={8}
            tint="primaryDark"
          />
        </Row>
      </MainHeader>
      <Center my={8} mb={8}>
        <Text preset="h4">{translate('title.my_saunas')}</Text>
      </Center>
      <ScrollView horizontal>
        <Row px={16} mt={12} key={`controller-${auth.language}`}>
          {map(saunasList, (sauna) => (
            <Pressable
              mr={16}
              onPress={handleSaunaSelect(sauna.saunaId)}
              pointerEvents="box-only"
            >
              <Stack>
                <Text mb={4} textAlign="center">{sauna.saunaId}</Text>
                <SaunaController
                  key={sauna.saunaId}
                  saunaId={sauna.saunaId}
                  isHeating={sauna.isHeating}
                  controllerTargetTemperature={sauna.targetTemp}
                  width={300}
                />
              </Stack>
            </Pressable>
          ))}
          <Box mt={12}>
            <NewSaunaEmptyController
              width={300}
            />
          </Box>
        </Row>
      </ScrollView>
    </Stack>
  );
});

export default SaunaSelectScreen;
