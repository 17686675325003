import { IMSTArray, Instance, IType, SnapshotOut, types } from "mobx-state-tree"
import uuid from "react-native-uuid"

/**
 * Model description here for TypeScript hints.
 */
export const LedControllerStoreModel = types
  .model("LedControllerStore")
  .props({
    connectedDeviceId: types.maybeNull(types.string),
    connectedDeviceName: types.maybeNull(types.string),
    connectedDeviceGatt: types.maybeNull(types.frozen()),
    connectedDeviceActive: types.optional(types.boolean, false),
  })
  .views(self => ({
    get isConnected() {
      return !!self.connectedDeviceId && self.connectedDeviceActive;
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions(self => ({
    setConnectedDeviceId(id?: string, connectedDeviceName?: string, connectedDeviceGatt?: any) {
      self.connectedDeviceId = id || null;
      self.connectedDeviceName = connectedDeviceName || null;
      self.connectedDeviceGatt = connectedDeviceGatt || null;
      self.connectedDeviceActive = !!id && !!connectedDeviceGatt;
    },
    setConnectionInactive() {
      self.connectedDeviceActive = false
    }
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type LedControllerStoreType = Instance<typeof LedControllerStoreModel>
export interface LedControllerStore extends LedControllerStoreType {}
type LedControllerStoreSnapshotType = SnapshotOut<typeof LedControllerStoreModel>
export interface LedControllerStoreSnapshot extends LedControllerStoreSnapshotType {}
export const createLedControllerStoreDefaultModel = () => types.optional(LedControllerStoreModel, {})
