import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { useStores } from "@huum/store";
import { get } from "lodash";
import { userTemperatureUnitChange } from "@huum/api/requests/profile";
import { TEMPERATUREUNITS } from "@huum/components/organisms/temperature-unit-select";


export const useUserTemperatureUnitChange = () => {
  const { sauna, auth } = useStores();
  const session = get(auth, "sessionHash", null);
  const { mutate } = useMutation(userTemperatureUnitChange);
  

  const changeTemperatureUnit = useCallback((unit: keyof typeof TEMPERATUREUNITS) => {
    sauna.updateSaunaTemperatureUnit(unit);

    mutate({
      tempConf: unit === "celsius" ? "C" : "F",
      session,
    });
  }, []);

  return { changeTemperatureUnit };
};
