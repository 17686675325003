import { Animated } from "react-native";
import {
  DEFAULT_CONTROLLER_WIDTH,
  MAX_TEMPERATURE,
  CONTROLLER_COLORS,
  pickHexColor,
} from "./helpers";
import React, { useCallback, useMemo } from "react";
import { useControllerPanResponder } from "./hooks";
import { Box, Image, Row, Stack, View, useTheme } from "native-base";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Text } from "@huum/components/atoms/text";
import {
  getTemperatureUnit,
  getTemperatureValue,
} from "@huum/utils/helpers/sauna-temperature-helpers";
import SaunaController from ".";
import { translate } from "@huum/i18n";
import moment from "moment";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { Icon } from "@huum/components/atoms/icon";
import { useStores } from "@huum/store";

interface TemperatureControllerProps {
  width?: number;
  temperature: number;
  targetTemperature: number;
  handleSaunaStartPress?: () => void;
  handleSaunaStopPress?: () => void;
  onTemperatureChange?: (temperature: number) => void;
  topOffset?: number;
  isHeating?: boolean,
}

const TemperatureController = (props: TemperatureControllerProps) => {
  const {
    width: customWidth,
    temperature,
    targetTemperature,
    handleSaunaStartPress,
    handleSaunaStopPress,
    onTemperatureChange,
    topOffset = 0,
    isHeating,
    endDate,
    startDate,
    duration,
  } = props;
  const width = customWidth || DEFAULT_CONTROLLER_WIDTH;

  const { sauna } = useStores()

  const timerIsActive =
    moment.unix(startDate).isAfter(moment()) &&
    moment.unix(endDate).isAfter(moment()) &&
    duration > 0;

  const {
    panResponder,
    position,
    controllerTargetTemperature,
    handleControllerLayout,
    panResponderIsActive,
    centerPoint,
  } = useControllerPanResponder({
    width,
    minTemperature: 0,
    maxTemperature: 110,
    targetTemperature,
    onTemperatureChange,
    topOffset,
  });


  const visibleTemperature = isHeating
    ? temperature
    : controllerTargetTemperature;

  const temperaturePercentage = visibleTemperature / MAX_TEMPERATURE;

  const temperatureColor = `rgb(${pickHexColor(
    CONTROLLER_COLORS.end,
    CONTROLLER_COLORS.start,
    temperaturePercentage
  ).join(",")})`;

  const handleControllerPress = () => {
    if (isHeating) {
      handleSaunaStopPress();
    } else {
      handleSaunaStartPress();
    }
  };

  const getControllerButtonText = () => {
    // target temp selection
    if (panResponderIsActive) return translate("text.target_temperature");

    // timer
    if (timerIsActive) return translate("text.stop_timer");

    // is heating
    if (isHeating) return translate("button.press_to_stop");

    return translate("button.press_to_start");
  };

  const renderInnerCircleContent = useCallback(({ size }: { size: number}) => {
    const fontSize = size > 200 ? "6xl" : "3xl";
    return (
      <Stack space={2} alignItems="center">
        <Row mt={2} alignItems="baseline">
          <Text
            fontSize={fontSize}
            lineHeight="xs"
            fontWeight="semibold"
            color={isHeating ? "#CA4E69" : undefined}
          >
            {getTemperatureValue(visibleTemperature, sauna.temperatureUnit)}
          </Text>
          <Text
            mt={4}
            color={isHeating ? "#CA4E69" : undefined}
          >
            {getTemperatureUnit(sauna.temperatureUnit)}
          </Text>
        </Row>
        <Icon icon="heating2" />

        <Text color={isHeating ? "#CA4E69" : undefined} fontWeight="semibold" fontSize="xs">
          {getControllerButtonText()}
        </Text>
      </Stack>
    );
  }, [isHeating, visibleTemperature, sauna.temperatureUnit]);

  return (
    <>
      <SaunaController
        width={width}
        onLayout={handleControllerLayout}
        temperature={visibleTemperature}
        temperaturePercentage={temperaturePercentage}
        position={position}
        panResponder={panResponder}
        targetTemperature={controllerTargetTemperature}
        InnerCircleContent={renderInnerCircleContent}
        temperatureColor={temperatureColor}
        onPress={handleControllerPress}
      />
    </>
  );
};

export default TemperatureController;
