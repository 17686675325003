import {
  Box,
  IButtonProps,
  Button as BaseButton,
  Row,
} from "native-base";
import React, { useCallback, useState } from "react";
import { Icon } from "../icon";
import { IconType } from "../icon/icon.props";

const PressEvents = {
  in: "in",
  out: "out",
} as const;

interface ButtonProps extends IButtonProps {
  label?: string;
  icon?: IconType;
  visible?: boolean;
  onPress?: () => void;
  iconSize?: number
  iconColor?: string
}

const Button = (props: ButtonProps) => {
  const { label, icon, iconSize = 10, visible = true, iconColor = "white.100", ...rest } = props;

  if (!visible) return null;


  return (
    <BaseButton startIcon={!!icon ? <Icon size={iconSize} tint={iconColor} icon={icon} /> : undefined} _text={{ fontSize: "md", px: 2 }} {...rest}>
      {label}
    </BaseButton>
  );
};

export default Button;
