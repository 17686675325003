import {
  IScrollViewProps,
  ScrollView,
} from "native-base";

interface ScreenProps extends IScrollViewProps {
  children?: React.ReactNode | React.ReactNode[];
  padding?: number;
}

export const Screen = (props: ScreenProps) => {
  const { children, padding = 16, ...rest } = props;

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ flexGrow: 1, padding, paddingBottom: 32 }}
      {...rest}
    >
      {children}
    </ScrollView>
  );
};
