import React, { useState, useEffect, useRef, useCallback, Children } from "react";
import { View, Dimensions, Image } from "react-native";
import {
  map,
  get,
  toNumber,
  isNumber,
  reduce,
  range,
  includes,
  findIndex,
  find,
  concat,
  times,
} from "lodash";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { getCalendarStyles } from "../styles";
import moment from "moment";
import { Box, FlatList, Row } from "native-base"
import { loadCalendarMonthData } from "../helpers";
import CalendarHeader from "../calendar-header";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { Text } from "@huum/components/atoms/text";


const { width } = Dimensions.get("window");
const CALENDAR_WEEK_WIDTH = width;

const getItemLayout = (_data, index) => ({
  length: CALENDAR_WEEK_WIDTH,
  offset: CALENDAR_WEEK_WIDTH * index,
  index,
});



const getInitialMonthsList = (props) => {
  const pastDatesLimit = props.limitPastDates;
  const futureDatesLimit = props.limitFutureDates;

  const monthsCountToRender = pastDatesLimit + 1 + futureDatesLimit;
  const currentMonth = moment().startOf("month");
  const initialDate = currentMonth.subtract(pastDatesLimit, "months");
  const iterations = range(0, monthsCountToRender);

  return reduce(
    iterations,
    (result: any[], value) => {
      const monthToLoad = moment(initialDate).add(value, "months");
      return loadCalendarMonthData(monthToLoad, result);
    },
    []
  );
};

const MonthCalendar = (props) => {
  const calendarList = React.createRef();
  const [selectedDate, setSelectedDate] = useState(props.selectedDate);
  const [visibleMonth, setVisibleMonth] = useState(moment(props.selectedDate));
  const initialMonthsList = getInitialMonthsList(props);
  const [monthListData, setMonthListData] = useState(initialMonthsList);

  const initialMonthIndex = Math.max(
    findIndex(monthListData, { month: visibleMonth.get("month") }),
    0
  );

  const [visibleMonthIndex, setVisibleMonthIndex] = useState(initialMonthIndex);

  const viewabilityConfig = useRef({
    waitForInteraction: true,
    viewAreaCoveragePercentThreshold: 50,
  });
  
  const styles = useThemedStyles(getCalendarStyles);

  useEffect(() => {
    if (calendarList.current) {
      //@ts-ignore-next-line
      calendarList.current?.scrollToIndex({ index: visibleMonthIndex });
    }
  }, [visibleMonthIndex]);

  useEffect(() => {
    if (!props.activeMonth) return;
    const month = moment(props.activeMonth);
    const monthData = loadCalendarMonthData(month, []);
    setMonthListData(monthData);
    setVisibleMonth(moment(props.activeMonth));
  }, [props.activeMonth]);

  const selectDate = (date) => () => {
    if (typeof props.onDateSelect === "function") {
      props.onDateSelect(moment(date));
    }
    setSelectedDate(date);
  };

  const handleCalendarSwipe = React.useRef(({ viewableItems }: { viewableItems : any }) => {
      const visibleItem = viewableItems[0];
      const month = get(visibleItem, "item.month");
      const index = get(visibleItem, "index");

      if (isNumber(month)) {
        setVisibleMonthIndex(index);
        setVisibleMonth(moment().month(month));
      }
    }
  );

  const renderActivityIndicator = (day) => {
    const hasIndicator = includes(props.activityIndicatorList, day.key);
    if (!hasIndicator) return null;
    return <View style={styles.activityIndicator} />;
  };

  const renderDay = useCallback((day, month) => {
    let sessionNumber = null
    if (props?.sessionCount) {
      const count = find(props?.sessionCount, session => session.date === day.key)
     if (count) {
       sessionNumber = count.sessionCount;
     }
    }

    const isAnotherMonth = moment(day.key).get("month") !== toNumber(month);
    const opacity = isAnotherMonth ? 0.6 : 1;

    const sessionText = <Text style={styles.sessionNumber}>{sessionNumber}</Text>;
 
    if (selectedDate === day.key) {
      return (
        <Touchable
          onPress={() => setSelectedDate(day.key)}
          style={styles.montCalendarDaySelected}
        >
          <Text preset="subtitle1" style={styles.calendarDayText}>
            {day.date}
          </Text>
          {sessionText}
        </Touchable>
      );
    }

    // const disableDate = props.disablePreviousDates && moment(day.key).isBefore(moment(), "day");

    // const isToday = moment(day.key).isSame(moment(), "day");

    return (
      <Touchable onPress={selectDate(day.key)} style={styles.monthCalendarDay}>
        <Text
          preset="subtitle1"
          opacity={opacity}
          style={styles.calendarDayText}
        >
          {day.date}
        </Text>
        {sessionText}
        {renderActivityIndicator(day)}
      </Touchable>
    );
  }, [props.sessionCount]);

    const renderCalendarFooter = useCallback(() => {
      return (
        <Row justifyContent={"space-between"} mt={3}>
          {times(7, (day) => (
            <Box width={12} alignItems={"center"} paddingX={1}>
              <Text style={styles.monthText}>{moment().day(day + 1).format("ddd")}</Text>
            </Box>
          ))}
        </Row>
      );
    }, []);

    const renderWeekDays = (days, month) =>
      map(days, (value) => renderDay(value, month));

    const renderCalendarMonth = ({ item } : {item: any}) => {


      return (
        <Box>
          {map(item.weeks, (week) => (
            <Box style={styles.monthCalendarWeek} key={item.week}>
              {renderWeekDays(week, item.month)}
            </Box>
          ))}
          {renderCalendarFooter()}
        </Box>
      );
    };

  if (!props.visible) return null;


  return (
    <Box paddingY={6}>
      {props.getSessionCount && <CalendarHeader
        currentMonth={visibleMonth}
        futureDatesLimit={props?.limitFutureDates}
        pastDatesLimit={props?.limitPastDates}
        onPreviousWeekPress={() => null}
        onNextWeekPress={() => null}
      />}
      <FlatList
        ref={calendarList}
        data={monthListData}
        renderItem={renderCalendarMonth}
        getItemLayout={getItemLayout}
        initialScrollIndex={visibleMonthIndex}
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        horizontal
        onViewableItemsChanged={handleCalendarSwipe.current}
        viewabilityConfig={viewabilityConfig.current}
      />
      {props.children}
    </Box>
  );
};

export default React.memo(MonthCalendar);
