import React, { useCallback, useMemo, useState } from "react";
import { Image, View } from "react-native";
import { debounce, includes, throttle, toNumber } from "lodash";
import SaunaStatusCode from "@huum/utils/sauna-status-code";
import { Switch } from "@huum/components/molecules/switch";
import SliderSelect from "@huum/components/molecules/slider-select";
import { Box, Row, Stack } from "native-base";
import { translate } from "@huum/i18n";
import { Text } from "@huum/components/atoms/text";
import { ModalContainer } from "@huum/components/views/modals/modal-container";

const SaunaHumidityController = (props: any) => {
  const {
    updateHumidity,
    config,
    humidity,
    statusCode,
    closeModal,
  } = props;
  const [isToggled, setIsToggled] = useState<boolean>(humidity > 0);

  const [humidityValue, setHumidityValue] = useState<number>(humidity);

  const isFunctionalityDisabled = !config ||
  !includes([1, 3], config) ||
  includes(
    [
      SaunaStatusCode.SAUNA_OFFLINE,
      SaunaStatusCode.SAUNA_ACTIVE_BLOCKED,
      SaunaStatusCode.SAUNA_EMERGENCY_STOP,
    ],
    statusCode
  )

  const humidityChangeDebounce = useMemo(() => throttle(updateHumidity, 1000), [updateHumidity]);

  const onHumiditySet = (value: any) => {
    setHumidityValue(value);

    if (!isToggled) {
      setIsToggled(true)
    }

    // if (saunaStatusCode === SaunaStatusCode.SAUNA_ACTIVE) {
      humidityChangeDebounce(value);
    // }
  };

  const HumiditySwitch = useCallback(() => {
    if (config === 2 || config === 3) {
      return (
        <Row alignItems="center">
          <Image
            source={require("@huum/assets/legacy/drop_outline_icon.png")}
            style={{
              height: 30,
              width: 30,
            }}
          />
          <Switch
            disabled={isFunctionalityDisabled}
            onToggle={() => {
              setIsToggled(!isToggled);
            }}
            value={isToggled}
          />
        </Row>
      );
    }

    return null;
  }, [isToggled, config])

  return (
    <ModalContainer title={translate("label.humidity")} closeModal={closeModal}>
      <Stack flex={1} alignItems="center" space={4} px={4} mt={4} >
        <Row mb={4} pb={8} borderBottomWidth={1} borderBottomColor="primaryMedium" width="full" justifyContent="space-between" alignItems="center">
          <Text preset="subtitle1" color="primaryDark" fontSize="lg">
            {translate("label.humidity")}
          </Text>
          <HumiditySwitch />
        </Row>
        <SliderSelect
          disabled={isFunctionalityDisabled}
          width="90%"
          onChange={onHumiditySet}
          defaultValue={humidity}
          value={humidityValue}
          unit={"%"}
          maxValue={100}
        />
      </Stack >
    </ModalContainer>
  );
};


export default SaunaHumidityController;
