import React, { useState, useEffect, useRef, useCallback } from 'react';
import { size } from 'lodash';
import { Text } from '@huum/components/atoms/text';
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { Box } from 'native-base';

const AnimatedBox = Animated.createAnimatedComponent(Box);

function AnimatedInfoSection({ contentSections, visible }) {
  const contentSectionsCount = size(contentSections);
  const [visibleContentIndex, setVisibleContentIndex] = useState(0);

  const animatedValue = useSharedValue(visible ? 1 : 0);

  const handleSectionChange = useCallback(() => {
    animatedValue.value = withTiming(0, { duration: 1000 }, () => {
      setVisibleContentIndex(current => (current + 1) === contentSectionsCount ? 0 : (current + 1))
      animatedValue.value = withTiming(1, { duration: 1000 });
    })
  }, []);

  useEffect(() => {
    let interval;
    if (visible) {
      interval = setInterval(handleSectionChange, 6000)
    }

    return () => {
      clearInterval(interval);
    }
  }, [visible]);


  const visibleContent = contentSections[visibleContentIndex || 0];

  if (size(contentSections) === 0) return null;


  const containerStyle = useAnimatedStyle(() => ({
    opacity: interpolate(animatedValue.value, [0, 1], [0, 1])
  }));

  if (!visible) {
    return null;
  }

  return (
    <AnimatedBox
      style={containerStyle}
    >
      <Text
        fontSize={14}
        // fontWeight="400"
        // style={visibleContent.labelColor && { color: visibleContent.labelColor }}
      >
        {visibleContent.label}
      </Text>
      <Text
        fontSize={22}
        fontWeight="bold"
      >
        {visibleContent.value}
      </Text>
    </AnimatedBox>
  );
}

export default AnimatedInfoSection;