import * as React from "react"
import { Text as BaseText } from 'native-base'
import { TextProps } from "./types"
import { translate } from "../../../i18n"
import { presets } from "./preset"

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native Elements one.
 */
export function Text(props: TextProps) {
  const {
    children,
    tx,
    preset = "body1",
    ...rest
  } = props

  const text = tx ? translate(tx) : children;

  const presetProps = presets[preset];

  return (
    <BaseText {...presetProps} {...rest} >
      {text}
    </BaseText>
  );
}
