import AnimatedInfoSection from "@huum/components/organisms/animated-info-section";
import { useSaunaInfoSections } from "@huum/hooks/useSaunaInfoSections"
import { Box } from "native-base";


interface SaunaInfoSectionProps {
  saunaStatus?: any;
  visible?: boolean;
}

export const SaunaInfoSection = (props: SaunaInfoSectionProps) => {
  const { saunaStatus, visible } = props

  const infoSections = useSaunaInfoSections({saunaStatus});

  return (
    <Box>
      {visible &&
        <AnimatedInfoSection
          visible={visible}
          contentSections={infoSections}
        />
      }
    </Box>
  )
}