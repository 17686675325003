import { useTheme } from 'native-base';
import { StyleSheet } from 'react-native';

const sliderHeight = 45;
const circlePseudoMargin = 3;
const sliderCircleSize = sliderHeight - circlePseudoMargin;

export const useSlideButtonStyles = () => {
  const { colors } = useTheme();
  return StyleSheet.create({
    container: {
      width: '100%',
      height: sliderHeight,
    },
    slideButtonContainer: {
      position: 'relative',
      width: '100%',
      maxHeight: 90,
      backgroundColor: colors.invalid,
      borderRadius: sliderHeight / 2,
      overflow: 'hidden',
    },
    slideButton: {
      position: 'absolute',
      width: '100%',
      height: sliderHeight,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.invalid,
    },
    sliderCircle: {
      position: 'absolute',
      backgroundColor: colors.white[100],
      right: 0,
      borderRadius: sliderCircleSize / 2,
      width: sliderCircleSize,
      height: sliderCircleSize,
    },
    sliderBackground: {
      position: 'absolute',
      backgroundColor: colors.invalid,
      width: '100%',
      height: sliderHeight,
      opacity: 0.75,
  
    },
    sliderLabel: {
      color: colors.white[100],
      marginLeft: sliderHeight,
      textAlign: 'center',
    },
    arrow: {
      marginRight: (sliderHeight / 2) - (circlePseudoMargin / 2) - (20 / 2),
      height: 10,
      width: 20,
      resizeMode: 'contain',
    },
    draggableOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    safeAreaStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      // backgroundColor: Colors.white,
    },
    animatedView: {
      height: sliderHeight,
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
  })
}
