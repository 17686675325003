import { Row, Stack, Box } from 'native-base';
import { find, map } from 'lodash';
import { Pressable } from 'react-native';
import { Text } from '@huum/components/atoms/text';
import EffectConfigurator from './effectConfigurator';
import { useState } from 'react';
import { getHitSlop } from '@huum/utils/hitSlop';

const effects = [
  {
    slot: 0,
    data: [{"c":"F00"},{"c":"0F0"},{"c":"00F"}],
    effectKey: "a",
    color: "#61b8a9",
    name: "Aurora",
    gradient: ["#90D0C5", "#38A391"]
  },
  // {
  //   slot: 1,
  //   data: [{"c":"F00"},{"c":"0F0"},{"c":"00F"}],
  //   effectKey: "p",
  //   color: "#dc9e00",
  //   name: "Random",
  // },
  {
    slot: 2,
    data: [{"c":"F00"},{"c":"0F0"},{"c":"00F"}],
    effectKey: "f",
    color: "#8cc5ef",
    name: "Random",
    gradient: ["#3C73DB", "#3D1D95"]
  },
  {
    slot: 3,
    data: [{"c":["F00","35F","0CB"]},{"c":["000"]}],
    effectKey: "r",
    color: "#3d45b6",
    name: "Candle",
    gradient: ["#E3DA00", "#D35100"]
  },
  {
    slot: 4,
    data: [{"c":"F00"},{"c":"0F0"},{"c":"00F"}],
    effectKey: "o",
    color: "#b40000",
    name: "Ocean",
    gradient: ["#A0D5FC", "#6EADDB"]
  },
  {
    slot: 5,
    data: [{"c":"F00"},{"c":"0F0"},{"c":"00F"}],
    effectKey: "s",
    color: "#b40000",
    name: "Sunset",
    gradient: ["#FDB813", "#E8630C"]
  },
] as const;

export type Effect =  typeof effects[number];
export type EffectKey = typeof effects[number]["effectKey"];

interface EffectSelectProps {
  selected: EffectKey;
  onSelect: (effect: Effect) => void;
}

interface EffectButtonProps {
  color: string,
  name?: string,
  onPress: (effectKey: EffectKey ) => void,
  onLongPress: (effectKey: EffectKey ) => void,
  icon?: any;
  effectKey: EffectKey
  isSelected?: boolean;
  gradient: [string],
}

export const EffectButton = (props: EffectButtonProps) => {
  const {color, gradient,  name, onPress, icon, effectKey, isSelected, onLongPress} = props;

  const handleOnPress = () => onPress(effectKey)
  const handleOnLongPress = () => onLongPress(effectKey)

  return (
    <Pressable hitSlop={getHitSlop(8)}  onPress={handleOnPress}>
      <Stack alignItems="center">
        <Box
          width={8}
          height={8}
          borderRadius={16}
          backgroundColor={{
            linearGradient: {
            colors: gradient,
            start: [0, 0],
            end: [1, 0],
            locations: [0, 0.6]
          }}}
        />
        {name && <Text bold={isSelected} fontSize="sm">{name}</Text>}
      </Stack>
    </Pressable>
  );
}

const EffectsSelect = (props: EffectSelectProps) => {
  const { selected, onSelect } = props;
  const [configurableEffect, setConfigurableEffect] = useState<Effect | undefined>()

  const onEffectPress = (effectKey: EffectKey) => {
    const effect = find(effects, { effectKey })
    onSelect(effect)
  }

  const onConfigurationSelect = (effect: Effect) => {
    onSelect(effect)
    setConfigurableEffect(undefined)
  }

  const onEffectLongPress = (effectKey: EffectKey) => {
    const effect = find(effects, { effectKey })
    setConfigurableEffect(effect)
  }

  return (
    <>
      <Row justifyContent="space-between">
        {
          map(effects, (effect) => (
            <EffectButton
              {...effect}
              isSelected={selected === effect.effectKey}
              onPress={onEffectPress}
              onLongPress={onEffectLongPress}
            />
          ))
        }
      </Row>
      <EffectConfigurator
        onCancel={() =>  setConfigurableEffect(undefined)}
        effect={configurableEffect}
        setEffect={onConfigurationSelect}
      />
    </>
  )
}

export default EffectsSelect;