import { useTheme } from 'native-base';
import { StyleSheet } from 'react-native';

export const useScrollSelectorStyles = () => {

  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    pickerItem: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      width: 50,
    },
    label: {
      marginBottom: 15,
      marginTop: 25,
      color: colors.gray[200],
      fontSize: 8,
    },
    valueText: {
      flex: 1,
      lineHeight: 40,
      fontSize: 36,
    },
    disabled: {
      color: colors.gray[600],
    },
  });
}
