import React, { useCallback } from "react";
import { Box, Row } from "native-base";
import { Text } from "@huum/components/atoms/text";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { getStyles } from "./styles";
import { map } from "lodash";


export const Tabs = (props: any) => {
  const { tabs, plain } = props;

  const style = useThemedStyles(getStyles);

  const TabButton = useCallback(
    (props: any) => {
     const { title, index, onPress, isFocused } = props;


      return (
        <Touchable key={index} onPress={onPress}>
          <Row flexDir={"row"} alignItems={"center"} justifyContent={"center"}>
            <Text fontSize="14px" fontWeight={700}>
              {title}
            </Text>
          </Row>
          {isFocused && (
            <Box
              mt={1}
              height={1}
              backgroundColor="green.200"
              borderRadius={2}
            />
          )}
        </Touchable>
      );
    },
    []
  );

  const Tabs = () => (
    <Row justifyContent="space-between">
      {map(tabs, (tab, index) => (
        <TabButton
          {...tab}
          index={index}
        />
      ))}
    </Row>
  )

  if (plain) return <Tabs />

  return (
    <>
      <Box
        safeArea
        paddingX={60}
        backgroundColor="white.100"
        borderTopRadius={20}
        safeAreaTop={5}
        style={style.container}
      >
        <Tabs />
      </Box>
    </>
  );
};
