import { palette } from '@huum/theme/helpers/palette';
import { Theme } from "native-base";
import { StyleSheet } from "react-native";

export const getMenuViewStyles = (theme: Theme) => {
  const { colors, space } = theme;
  return StyleSheet.create({
    dimmedText: {
      color: palette.dimmedText,
    },
  });
};
