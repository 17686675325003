import { Animated, LayoutChangeEvent, PanResponderInstance } from "react-native";
import {
  DEFAULT_CONTROLLER_WIDTH,
} from "./helpers";
import React, { useEffect, useRef, useState } from "react";
import Svg from "react-native-svg";
import { Box, Pressable, View } from "native-base";
import { Text } from "@huum/components/atoms/text";
import { getTemperatureValue } from "@huum/utils/helpers/sauna-temperature-helpers";
import ControllerGradientPath from "./gradient-path";
import { useStores } from "@huum/store";
import { observer } from "mobx-react-lite";
import { ControllerArc } from "./empty-arc";

export interface SaunaControllerProps {
  width?: number;
  targetTemperature: number;
  temperature: number;
  temperaturePercentage: number;
  statusCode?: number;
  position: React.MutableRefObject<Animated.ValueXY>;
  panResponder: React.MutableRefObject<PanResponderInstance>;
  temperatureColor: string;
  controllerCircleText?: string;
  monotone?: boolean;
  onLayout: (event: LayoutChangeEvent) => void;
  InnerCircleContent?: ({ size }: { size: number }) => JSX.Element;
  ExtraContent?: () => JSX.Element;
  onPress?: () => void;
}

const SaunaController = observer((props: SaunaControllerProps) => {
  const {
    width: customWidth,
    onLayout,
    temperature,
    temperaturePercentage,
    position,
    panResponder,
    temperatureColor,
    targetTemperature,
    controllerCircleText,
    monotone = false,
    InnerCircleContent,
    ExtraContent,
    onPress
  } = props;
  const width = customWidth || DEFAULT_CONTROLLER_WIDTH;
  const [viewSize, setViewSize] = useState<number>(0)

  const { sauna } = useStores();

  const innerCircleSize = width / 2 + 48;

  const controllerCircleSize = width > 350 ? 52 : 40;
  const controllerCircleContainerSize = width > 350 ? width + 32 : width + 18;

  useEffect(() => {
    setTimeout(() => {
       setViewSize(30)
    }, 3000)
  }, [])
 
  const getControllerCircleText = () => {
    if (controllerCircleText) {
      return controllerCircleText;
    }
    return (
      `${getTemperatureValue(targetTemperature, sauna.temperatureUnit)}°`
    )
  }

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      alignSelf="center"
      width={width}
      height={width}
      onLayout={onLayout}
      position="relative"
    >
        <ControllerArc width={width} />
        {ExtraContent && <ExtraContent />}
        <ControllerGradientPath
          monotone={monotone}
          temperatureColor={temperatureColor}
          size={width}
          temperature={temperature}
          temperaturePercentage={temperaturePercentage}
        />
        {
          !!panResponder && !!position && (
            <View
              position="absolute"
              zIndex={3}
              width={controllerCircleContainerSize}
              height={controllerCircleContainerSize}
            >
              <Animated.View
                style={[{ position: "absolute", zIndex: 200 }, position.current?.getLayout()]}
                {...panResponder?.current?.panHandlers}
                
              >
                <Box
                  height={`${controllerCircleSize}px`}
                  width={`${controllerCircleSize}px`}
                  borderRadius={controllerCircleSize / 2}
                  alignItems="center"
                  justifyContent="center"
                  background={temperatureColor}
                >
                  <Text color="white.100">
                    {getControllerCircleText()}
                  </Text>
                </Box>
              </Animated.View>
            </View>
          )
        }
      <Pressable
        onPress={onPress}
        position="absolute"
        height={innerCircleSize}
        width={innerCircleSize}
        borderRadius={innerCircleSize / 2}
        zIndex={5}
      >
        <Box
          flex={1}
          shadow={6}
          background="white.100"
          borderRadius={innerCircleSize / 2}
          alignItems="center"
          justifyContent="center"
        >
          {InnerCircleContent && <InnerCircleContent size={innerCircleSize} />}
        </Box>
      </Pressable>
    </Box>
  );
});

export default SaunaController;
