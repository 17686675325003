import React, { useCallback } from "react"
import { Input as BaseInput } from "../input/input"
import { Control, useController } from "react-hook-form"
import { SecureInput } from "../input/secure-input"
import { NativeSyntheticEvent, TextInputFocusEventData } from "react-native"
import { InputProps } from "../input/input.props"

export interface FormInputProps extends InputProps {
  isSecure?: boolean;
  name: string
  control: Control
  rules?: any
  transform?: {
    input?: (value: string) => string;
    output?: (event:string) => string;
  }
}

const FormInput = (props: FormInputProps) => {
  const { isSecure, name, control, rules, onBlur, defaultValue, transform, ...rest } = props

  const {
    field: { onChange, onBlur: formItemOnBlur, value, ref },
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const handleInputBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      formItemOnBlur()
      onBlur && onBlur(e)
    },
    [formItemOnBlur, onBlur],
  )

  const InputComponent = isSecure ? SecureInput : BaseInput

  const displayValue = transform?.input && transform.input(value) || value;
  const handleInputChange = useCallback((text: string) => {

    if (transform?.output) {
      onChange(transform.output(text))
    } else {
      onChange(text)
    }
  }, [])

  return (
    <InputComponent
      innerRef={ref}
      value={displayValue || (!isDirty ? defaultValue : undefined)}
      onChangeText={handleInputChange}
      helperTextType={error ? "error" : "info"}
      helperText={error?.message}
      onBlur={handleInputBlur}
      {...rest}
    />
  )
}

export { FormInput }
