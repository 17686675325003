//@ts-nocheck
import { createSelector } from 'reselect';
import {
  reduce,
  times,
  map,
  toNumber,
  find,
  flatten,
  compact,
  get,
  orderBy,
  groupBy,
  uniq,
  filter,
  size,
  values,
  last,
  first,
  take,
  clone,
  reject,
} from 'lodash';
import moment from 'moment';

export const getLoading = (state: { booking: { savingBookingInProgress: any; }; }) => state.booking.savingBookingInProgress;
export const selectRepeatDuration = (state: { booking: { bookingRepeatingData: { repeatDuration: any; }; }; }) => state.booking.bookingRepeatingData.repeatDuration;
export const selectBookingListActiveDate = (state: { booking: { bookingListActiveDate: any; }; }) => state.booking.bookingListActiveDate;
export const selectBookingListEvents = (state: { booking: { bookingListEvents: any; }; }) => state.booking.bookingListEvents;
export const selectBookingListEventsRaw = (state: { booking: { bookingListEventsRaw: any; }; }) => state.booking.bookingListEventsRaw;
export const selectBookingListEvent = createSelector(
  (state: { booking: { bookingListEvents: any; }; }) => state.booking.bookingListEvents,
  (state: { booking: { openedEventID: any; }; }) => state.booking.openedEventID,
  (state: { booking: { openedEventTime: any; }; }) => state.booking.openedEventTime,
  (events: any, openEventID: any, openedEventTime: moment.MomentInput) => find(
    events,
    (event) => event.id === openEventID
      && moment(event.startDate).isSame(moment(openedEventTime)),
  ) || {},
);

// HELPERS:

export const toggleRepeatingDayHelper = (indexOfDay: string | number, weekdays: { [x: string]: any; }) => {
  const stateOfDay = weekdays[indexOfDay];
  const output = clone(weekdays);
  output[indexOfDay] = stateOfDay === 0 ? 1 : 0;
  return output;
};

export const selectIntoBookingGuest = (guest: { id: any; }, bookingGuests: any) => {
  const isSelected = find(bookingGuests, { id: guest.id });
  if (isSelected) {
    return reject(bookingGuests, { id: guest.id });
  }
  return [
    ...bookingGuests,
    guest,
  ];
};

const formatCalendarEvent = (value: { bookingId?: any; parentId?: any; id?: any; title?: any; startTime?: any; endTime?: any; startDate?: any; private?: any; targetTemperature?: any; weeklyPattern?: any; repeatDuration?: any; saunaId?: any; user?: any; key?: any; duration?: any; comments?: any; guests?: any; isException?: any; }, userId: any) => {
  const {
    id,
    title,
    startTime,
    endTime,
    startDate,
    private: isPrivate,
    targetTemperature,
    weeklyPattern,
    repeatDuration,
    saunaId,
    user: owner,
    // bookingId: parentId,
    key,
    duration,
    comments,
    guests,
    isException,
    parentEvent,
    isRecurring,
  } = value;

  const parentId = value.bookingId || value.parentId;

  const start = moment.unix(startTime);
  const end = moment.unix(endTime);
  return ({
    id,
    key,
    mine: get(owner, 'user_id', '') === userId,
    isPrivate: !!toNumber(isPrivate),
    targetTemperature,
    weeklyPattern,
    repeatDuration: isRecurring > 0 ? repeatDuration : 0,
    day: start.format('D'),
    month: start.format('MMMM'),
    start: start.format('HH:mm'),
    startTime,
    endTime,
    end: end.format('HH:mm'),
    title,
    startDate: start.format(),
    editable: start.isAfter(moment().add(1, 'hour')),
    endDate: end.format(),
    owner: get(owner, 'name', ''),
    ownerEmail: get(owner, 'email', ''),
    ownerId: get(owner, 'user_id', ''),
    originalStartDate: startDate,
    saunaId,
    parentId,
    duration,
    comments,
    guests,
    isException: isException || false,
    parentEvent,
  });
};

export const selectIntoBookingListItems = (events: any, userId: any) => {
  const parsed = reduce(events, (result: any, value) => {
    const {
      isRecurring,
      weeklyPattern,
      startDate,
      startTime,
      endDate,
      endTime,
      bookingExceptions,
      id,
      repeatDuration: recurringEventLengthInWeeks,
    } = value;
    const eventDuration = moment.unix(endTime).diff(moment.unix(startTime), 'minutes');
    if (isRecurring && weeklyPattern) {
      const weeklyEvents = times(toNumber(recurringEventLengthInWeeks), (week) => map(
        weeklyPattern,
        // eslint-disable-next-line consistent-return
        (hasEvent, dayIndex) => {
          if (toNumber(hasEvent)) {
            const originalStartTime = moment.unix(startTime);
            const originalEndTime = moment.unix(endTime);
            // eslint-disable-next-line max-len
            const weeksToAdd = moment.unix(startTime).isoWeekday(dayIndex + 1).isBefore(moment.unix(startTime)) ? week + 1 : week;
            const start = moment.unix(startTime).add(weeksToAdd, 'weeks').isoWeekday(dayIndex + 1);
            const end = moment.unix(endTime).add(weeksToAdd, 'weeks').isoWeekday(dayIndex + 1);
            // eslint-disable-next-line max-len
            const correctStartTime = moment(start).hours(originalStartTime.hour()).minutes(originalStartTime.minute());
            const correctEndTime = moment(end).hours(originalEndTime.hour()).minutes(originalEndTime.minute());

            // This might not work properly,
            // correct start time covers 21:00 - 23:00 modifications
            // correct end time covers 00:00 -> 03:00 modifications
            // no idea what may happen when multiple days are selected.
            if (moment(correctStartTime).isSameOrAfter(moment.unix(endDate)) || moment(correctEndTime).isSameOrAfter(moment.unix(endDate))) {
              return null;
            }

            const exception = find(bookingExceptions, (except) => moment.unix(except.startTime).isSame(start, 'day'));
            if (exception) {
              const {
                startTime: exceptionStartTime,
                endTime: exceptionEndTime,
                isCancelled,
              } = exception;
              if (isCancelled === '1') {
                return null;
              }
              if (isCancelled === '0') {
                const exceptionDuration = moment.unix(exceptionEndTime).diff(exceptionStartTime, 'minutes');
                const event = formatCalendarEvent({
                  ...value,
                  ...exception,
                  duration: exceptionDuration,
                  id: exception.id || null,
                  isException: true,
                  parentEvent: value,
                }, userId)
                return event;
              }
            }

            return (formatCalendarEvent({
              ...value,
              key: `${start.unix()}${id || ''}`,
              startTime: correctStartTime.unix(),
              endTime: moment(correctStartTime).add(eventDuration, 'minutes').unix(),
              duration: eventDuration,
              parentEvent: value,
            }, userId));
          }
        },
      ));
      return [
        ...result,
        ...compact(flatten(weeklyEvents)),
      ];
    }

    return [
      ...result,
      formatCalendarEvent({ ...value, duration: eventDuration }, userId),
    ];
  }, []);
  return orderBy(parsed, 'startDate');
}

export const selectEventsDatesList = createSelector(
  (state: { booking: { bookingListEvents: any; }; }) => state.booking.bookingListEvents,
  (bookingListItems: any) => uniq(map(bookingListItems, (item) => moment(item.startDate).format('YYYY-MM-DD'))),
);

export const selectActiveDayBookings = createSelector(
  (state: { booking: { bookingListActiveDate: any; }; }) => state.booking.bookingListActiveDate,
  (state: { booking: { bookingListEvents: any; }; }) => state.booking.bookingListEvents,
  (bookingListActiveDate: moment.MomentInput, bookingList: any) => filter(bookingList, (event) => (
    moment(event.startDate).isSame(moment(bookingListActiveDate), 'day')
      || (moment(event.endDate).isSame(moment(bookingListActiveDate), 'day')
        && (moment(event.startDate).isBefore(moment(event.endDate), 'day')))
  )),
);

export const getUnAvailableHours = (bookingList: any, maxHeatingTime: number) => {
    const eventsByDay = groupBy(bookingList, (event) => moment(event.startDate).format('YYYY-MM-DD'));
    const daysUnavailableHours = flatten(reduce(
      eventsByDay,
      (result: any, value: any) => {
        const eventsHours = map(value, (event) => {
          const eventStartMoment = moment(event.startDate);
          const eventDurationInHours = moment(event.endDate).diff(eventStartMoment, 'hours');
          return times(eventDurationInHours, (iterator) => ({
            hour: moment(eventStartMoment).add(iterator, 'hours').get('hour'),
            date: moment(eventStartMoment).add(iterator, 'hours').format('YYYY-MM-DD'),
            id: event.id,
          }));
        });

        return [
          ...result,
          ...eventsHours,
        ];
      },
      [],
    ));

    if (maxHeatingTime !== 12) {
      return reduce(daysUnavailableHours, (result, value) => ({
        ...result,
        [value.date]: [
          ...(result[value.date] || []),
          value.hour,
        ],
      }), {});
    }

    const sessionsByIdAndDate = groupBy(daysUnavailableHours, (value) => `${value.date}-${value.id}`);

    const sessionsBySequence = reduce(values(sessionsByIdAndDate), (result, value) => {
      const previousValue = last(result);
      const previousValueLastItem = last(previousValue);
      if (previousValueLastItem) {
        const previousValueMoment = moment(previousValueLastItem.date).hour(previousValueLastItem.hour);
        const valueFirstItem = first(value);
        const valueFirstItemMoment = moment(valueFirstItem.date).hour(valueFirstItem.hour);

        if (valueFirstItemMoment.diff(previousValueMoment, 'hours') <= 1) {
          return [
            ...(take(result, size(result) - 1)),
            [
              ...previousValue,
              ...value,
            ],
          ];
        }
      }

      return [
        ...result,
        value,
      ];
    }, []);

    const eventsHoursWithBuffers = reduce(
      sessionsBySequence,
      (result, value) => {
        const sessionLength = size(value);

        if (sessionLength >= 12) {
          const lastItem = last(value);
          const lastItemMoment = moment(lastItem.date, 'YYYY-MM-DD').hour(lastItem.hour);
          const bufferHours = times(6, (iterator) => {
            const newMoment = moment(lastItemMoment).add(iterator + 1, 'hours');

            return {
              hour: moment(newMoment).get('hour'),
              date: moment(newMoment).format('YYYY-MM-DD'),
            };
          });
          return [
            ...result,
            ...value,
            ...bufferHours,
          ];
        }

        return [
          ...result,
          ...value,
        ];
      },
      [],
    );
    return reduce(eventsHoursWithBuffers, (result, value) => ({
      ...result,
      [value.date]: [
        ...(result[value.date] || []),
        value.hour,
      ],
    }), {});
  }

export const selectActiveDayUnavailableHours =
  (activeDate: moment.MomentInput, hours: any) => get(hours, moment(activeDate).format('YYYY-MM-DD'), []);
