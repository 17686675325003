import FlowContainer from "@huum/components/organisms/flow-container";
import { useFocusEffect } from "@react-navigation/native";
import { AvoidSoftInput } from 'react-native-avoid-softinput';
import { useCallback } from "react";
import ConnectSaunaView from "./views/connect-sauna";
import SaunaActivatedView from "./views/sauna-activated";
import { useStores } from "@huum/store";

const SaunaConnectingScreen = () => {
  const { sauna } = useStores();
  
  const onFocusEffect = useCallback(() => {
    AvoidSoftInput.setShouldMimicIOSBehavior(true); 
    AvoidSoftInput.setEnabled(true);
    return () => {
      AvoidSoftInput.setEnabled(false);
      AvoidSoftInput.setShouldMimicIOSBehavior(false);
    };
  }, []);

  useFocusEffect(onFocusEffect);

  const components = {
    connectSauna: ConnectSaunaView,
    saunaActivated: SaunaActivatedView,
  }

  return (
    <FlowContainer
      initialView="connectSauna"
      components={components}
      canGoBack={!!sauna.saunaId}
    />
      
  )
}

export default SaunaConnectingScreen;

