import React from 'react';
import { Dimensions } from 'react-native';
import * as scale from 'd3-scale'; 
import { Line } from 'react-native-svg';
import { useTheme } from 'native-base';



const screenWidth = Dimensions.get('window').width;
const graphWidth = screenWidth - 60;

interface HorizontalLineProps {
  target: number;
  index: number;
  temperatureUnit: string;
}


const HorizontalLine = (props: HorizontalLineProps) => {
  const { target=0, index, temperatureUnit='celsius' } = props;
  const { colors } = useTheme();
  // function
  const scaleTemp = scale
    .scaleLinear()
    .domain([0, temperatureUnit === "celsius" ? 100 : 220])
    .range([260, 20]);

  if (target) {
    return (
      <Line
        key="zero-axis"
        x1={index * (graphWidth / 7) + 5}
        x2={(index + 1) * (graphWidth / 7) - 5}
        y1={scaleTemp(target)}
        y2={scaleTemp(target)}
        stroke={colors.primaryDark}
        strokeDasharray={[3, 3]}
        strokeWidth={2}
      />
    );
  }
  return null;
};

export default HorizontalLine;
