
export const shadows = {
  5: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowOpacity: 0.4,
    shadowRadius: 5,
    elevation: 6,
  },
  6: {
    shadow:
      "-3px -3px 8px rgba(5, 91, 112, 0.04), 3px 3px 8px rgba(5, 91, 112, 0.25), inset 2px 2px 10px rgba(5, 91, 112, 0.04)",
  },
};


export const components = (colors: any) => ({
  Text: {
    baseStyle: {
      color: colors.primaryDark,
    },
    defaultProps: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    sizes: {
      xl: {
        fontSize: '24px',
        lineHeight: "28px",
      },
      lg: {
        fontSize: '18px',
        lineHeight: "26px",
      },
      md: {
        fontSize: '16px',
        lineHeight: "24px",
      },
      sm: {
        fontSize: '14px',
        lineHeight: "20px",
      }
    }
  },
  ScrollView: {
    defaultProps: {
      contentContainerStyle: {
        paddingVertical: 16,
      }
    }
  },
  Input: {
    defaultProps: {
      size: "md",
      minHeight: "48px",
      px: 4,
      placeholderTextColor: colors.dimmedText,
      color: colors.primaryDark,
    },
    baseStyle: {
      borderColor: colors.primaryDark,
      _text: {
        color: colors.primaryDark,
      },
      _focus: {
        backgroundColor: "transparent",
        borderColor: colors.primaryDark,
        borderWidth: 2
      }
    },
    variants: {
      rounded: {
        // borderRadius: 5
      },
      underline: {
        _stack: {
          paddingVertical: 6
        }
      }
    }
  },
  Button: {
    baseStyle: {
      height: 12,
      borderRadius: 25,
      _text: {
        fontWeight: 600,
      },
    },
    variants: {
      text: {
        _text: {
          fontWeight: "bold"
        }
      },
      solid: {
        backgroundColor: colors.primaryDark,
      },
      outline: {
        borderWidth: 2,
        borderColor:colors.primaryDark,
        _text: {
          color: colors.primaryDark,
        }
      }
    },
  },
  Radio: {

    defaultProps: {
      colorScheme: "green",
    },
  }
})