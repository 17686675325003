import { useCallback, useEffect, useRef, useState } from "react"
import { useStores } from "@huum/store";


export const useLedController = () => {
  const { iot } = useStores();
  const [isScanningDevices, setIsScanningDevices] = useState(false);
  const [connectedCharacteristic, setConnectedCharacteristic] = useState<any>();
  const [dataMessage, setDataMessage] = useState<string>();

  const connectedDevice = iot.connectedDeviceId;
  const connectionIsActive = iot.isConnected
  const connectedDeviceGatt = iot.connectedDeviceGatt;
  
  useEffect(() => {
    if (dataMessage) {
      try {
        const cleanMessage = `{${dataMessage.replace(/\n/g, ", ").replace(/,\s*$/, "")}}`;
        const message = JSON.parse(cleanMessage);

        // do something with message
        setDataMessage(undefined)
      } catch (e) {
        console.log("Invalid message", e)
      }
    };
  }, [dataMessage]);

  useEffect(() => {
    if (!connectedDeviceGatt) {
      scanDevices();
    } else {
      handleConnectDevice();
    }
  }, [connectedDeviceGatt]);

function handleCharacteristicValueChanged(event) {
  const value = event.target.value;
  const decodecValue = new TextDecoder().decode(value).replace(/[{}]/g, "");
  setDataMessage((current) => (current ? current + decodecValue : decodecValue))
}

const writeToDevice = useCallback(async (json: any) => {
  console.log(connectedCharacteristic);
  
  if (!connectedCharacteristic) return;

  const encoder = new TextEncoder();
  const value = encoder.encode(json)

  return connectedCharacteristic.writeValue(value);
}, [connectedCharacteristic])


const getLedState = async () => {

  const result = await writeToDevice(`{"get": ["s", "c", "b" , "e"]}\n`);
 }

const handleConnectDevice = async () => {
  try {
    console.log(iot.connectedDeviceGatt)
      const server = await iot.connectedDeviceGatt.connect();
      const service = await server.getPrimaryService(0xffe0);
      const characteristic = await service.getCharacteristic(0xffe1);
      setConnectedCharacteristic(characteristic);
      characteristic.startNotifications();
      characteristic.addEventListener(
        'characteristicvaluechanged',
        handleCharacteristicValueChanged
      );
  } catch (error) {
      console.log(error);
      iot.setConnectedDeviceId()
  }
}

  const scanDevices = async () => {
    setIsScanningDevices(true)

    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: 'HUUM' }],
        optionalServices: [0xffe0]
      });
      iot.setConnectedDeviceId(device.id, device.name, device.gatt);
    } catch (error) {
      console.log(error)
    } finally {
      setIsScanningDevices(false);
    }

  }
  // "fff0",
  // "fff1",

  const setLedColor = useCallback((hex: string) => {
    writeToDevice(`{"c": "${hex}", "e": -1}\n`)
  }, [writeToDevice])

  const setBrightness = (brightness: number) => {
    const calculatedBrightness = 255 * brightness / 100;
    writeToDevice(`{"b": ${calculatedBrightness}}\n`)
  }
  
  const setEffect = async (name: string, slot: number, effect: any) => {
    writeToDevice(`{"e": ${slot}}\n`);
  }

  const setSpeed = (speed: number) => {
    const speedValue = speed * 100;
    writeToDevice(`{"speed": ${speedValue}}\n`);
  }

  return {
    hasPermissions: false,
    isScanningDevices,
    connectedDevice,
    setLedColor,
    getLedState,
    setBrightness,
    setSpeed,
    setEffect,
  };
}