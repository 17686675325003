import React from "react";
import { BaseHeaderProps } from "./props";
import { SafeAreaView } from "react-native-safe-area-context";
import { getStyles } from "./styles";
import { Box, Row } from "native-base";
import { goBack } from "@huum/services/NavigationService";
import { useThemedStyles } from "@huum/hooks/useThemedStyles";
import { IconButton } from "../icon-button";
import { Platform } from "react-native";


/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export function BaseHeader(props: BaseHeaderProps) {
  const {
    withBackNavigation,
    children,
    isPlain = false,
    onBackPress,
  } = props;

  const style = useThemedStyles(getStyles)

  const headerContainerStyle = isPlain ? style.headerContainerPlain : style.headerContainer;
  // const extraPadding = Platform.OS === "web" ? 4 : 0;

  return (
    <SafeAreaView edges={["top"]} style={headerContainerStyle}>
      <Row flex={1} minHeight={8} space={4} alignItems="center" >
        {withBackNavigation && (
          <IconButton
            size={10}
            onPress={onBackPress || goBack}
            icon="arrow-left"
          />
        )}
        {children}
      </Row>
    </SafeAreaView>
  );
};                      