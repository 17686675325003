import { TEMPERATUREUNITS } from "@huum/components/organisms/temperature-unit-select";
import { Instance, SnapshotOut, cast, types } from "mobx-state-tree";
import { withEnvironment, withRootStore } from "..";

interface SaunaProps {
  temperatureUnit?: string;
  saunaIsPrivate?: boolean;
  saunaId?: string;
  isFirstUser?: boolean;
  paymentEndDate?: string;
}

export const SaunaStoreModel = types
  .model("SaunaStore")
  .props({
    saunaId: types.maybeNull(types.string),
    saunaIsPrivate: types.maybe(types.boolean),
    temperatureUnit: types.optional(types.string, TEMPERATUREUNITS.celsius),
    isFirstUser: types.optional(types.boolean, false),
    paymentEndDate: types.maybeNull(types.string),
    latestDuration: types.optional(types.number, 180),
    latestTargetTemp: types.optional(types.number, 90),
    ledDevice: types.maybeNull(types.frozen()),
    saunasList: types.optional(types.array(types.frozen()), []),
  })
  .views((self) => ({
    saunas: () => self.saunasList,
  }))
  .actions((self) => ({
    updateSaunaTemperatureUnit: (temperatureUnit:  keyof typeof TEMPERATUREUNITS) => {
      self.temperatureUnit = temperatureUnit;
    },
    changeSaunaProfile: ({
      saunaIsPrivate,
      saunaId,
    }: SaunaProps) => {
      if (saunaId) {
        self.saunaId = saunaId;
      }
      self.saunaIsPrivate = saunaIsPrivate;
      if (temperatureUnit) {
        self.temperatureUnit = temperatureUnit;
      }
    },
    setSaunaIsPrivate: (isPrivate: boolean) => {
      self.saunaIsPrivate = isPrivate;
    },
    setSaunaId: (saunaId: string) => {
      self.saunaId = saunaId;
    },
    setSaunasList: (saunas: any[]) => {
      self.saunasList = cast(saunas);
    },
    setFirstUser: (isFirstUser: boolean) => {
      self.isFirstUser = isFirstUser;
    },
    setLatestDuration: (duration: number) => {
      self.latestDuration = duration;
    },
    setLatestTargetTemp: (temp: number) => {
      self.latestTargetTemp = temp;
    },
    setPaymentEndDate: (paymentEndDate: string) => {
      self.paymentEndDate = paymentEndDate;
    },
    setLedDevice: (device: any) => {
      self.ledDevice = device;
    },
    clearSaunaState: () => {
      self.saunaId = null;
      self.saunaIsPrivate = undefined;
      self.isFirstUser = false;
      self.paymentEndDate = null;
      self.saunasList = cast([]);
    }
  }))
  .extend(withEnvironment)
  .extend(withRootStore);

export type SaunaStoreType = Instance<typeof SaunaStoreModel>;
export interface SaunaStore extends SaunaStoreType {}
type SaunaStoreSnapshotType = SnapshotOut<typeof SaunaStoreModel>;
export interface SaunaStoreSnapshot extends SaunaStoreSnapshotType {}
export const createSaunaStoreDefaultModel = () =>
  types.optional(SaunaStoreModel, {});
