import React, { useEffect } from "react";
import { translate } from "@huum/i18n";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { Row } from "native-base";
import { Text } from "@huum/components/atoms/text";
import { Controller, useForm } from "react-hook-form";
import { Switch } from "@huum/components/molecules/switch";
import { useStores } from "@huum/store";
import { get } from "lodash";
import { useMutation } from "@tanstack/react-query";
import { userSafetyNotificationChange } from "@huum/api/requests/user";


const SafetySettings = () => {
  const { auth } = useStores();
  const { control, watch } = useForm();

  const changeNotifications = useMutation(userSafetyNotificationChange);

  const newValue = watch("safety_confirm", true);
  const initialValue = get(auth.settings, "showNotification", true);

  useEffect(() => {
    if (newValue !== initialValue) {
      changeNotifications.mutate({
        session: auth.sessionHash,
        showNotification: newValue,
      });
      auth.setNotificationSettings({ showNotification: newValue });
    }
  }, [newValue, initialValue]);

  return (
    <>
      <MainHeader withBackNavigation title={translate("button.safety")} />
      <Row px={7} py={7} justifyContent={"space-between"} alignItems={"center"}>
        <Text preset="subtitle1" fontWeight={"700"}>
          {translate("text.safety_notification_label")}
        </Text>
        <Controller
          control={control}
          name="safety_confirm"
          defaultValue={initialValue}
          render={({ field: { value, onChange } }) => (
            <Switch value={value} onToggle={() => onChange(!value)} />
          )}
        />
      </Row>
    </>
  );
};;

export default SafetySettings;
