import { Box, Center, Row, ScrollView, Stack, Text, View } from "native-base";
import { MainHeader } from "@huum/components/molecules/header/main-header";
import { translate, TxKeyPath } from "@huum/i18n";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { filter, map } from "lodash";
import { useBookingList } from "@huum/hooks/api/useBookings";
import moment from "moment";
import WeekCalendar from "@huum/components/organisms/calendar/week-calendar";
import BookingListView from "../components/booking-list-view";
import { useSaunaStatus } from "@huum/hooks/api/useSaunaStatus";
import { useStores } from "@huum/store";
import { Dimensions } from "react-native";
import SideBar from "@huum/components/web/sidebar";
import Card from "@huum/components/atoms/card";
import BookingEventsAgenda from "@huum/components/organisms/booking-events-agenda";
import CreateBooking from "../create-bookings";

const { width, height } = Dimensions.get('screen')

const tabsConfig = [
  {
    key: "bookingsList",
    titleKey: "bookings",
    icon: "booking-all",
  },
  {
    key: "myBookings",
    titleKey: "me",
    icon: "person",
  },
  {
    key: "addBookings",
    titleKey: "book",
    icon: "plus",
  },
];

const TabKeys = {
  ALL: "bookingsList",
  MY: "myBookings",
  ADD: "addBookings"
}

const BookingsView = () => {
  const { auth } = useStores();
  const today = moment().format();
  const [activeTab, setActiveTab] = useState<string>(TabKeys.ALL);
  const [activeDate, setActiveDate] = useState<string>(today);
  const [listViewMode, setListViewMode] = useState<string | undefined>("calendar");
  const { maxHeatingTime } = useSaunaStatus(true);

  const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
  const [selectedBooking, setSelectedBooking] = useState<any>(null);

  const { data, unavailableHours, isLoading } = useBookingList(
    activeDate || today,
    maxHeatingTime
  );

  const tabsList = useMemo(
    () =>
      map(tabsConfig, (tab, index) => ({
        label: translate(`label.${tab.titleKey}` as TxKeyPath),
        icon: tab.icon,
        key: tab.key,
      })),
    []
  );

  const handleNewBookingPress = useCallback((date: string) => {
    setDetailsVisible(true);
  }, [])

  const handleNewBookingCallback = useCallback(() => {
    setDetailsVisible(false);
  }, [])


  const handleActiveDateChange = useCallback((date: string) => {
    setActiveDate(date);
  }, []);

  const handleViewModeChange = useCallback((viewMode?: "list" | "calendar") => {
    setListViewMode(viewMode);
  }, []);

  const bookingListData = useMemo(() => {
    if ((activeTab === TabKeys.ALL && listViewMode === "calendar") || activeTab === TabKeys.ADD) {
      return filter(data, (item: any) =>
        moment(item.startDate).isSame(moment(activeDate), "day")
      );
    }

    if (activeTab === TabKeys.MY) {
      return filter(data, (item: any) =>  item?.ownerId === auth.accountId);
    }

    return data;
  }, [data, activeDate, listViewMode, activeTab, auth.accountId]);

  const Calendar = useCallback(() => (
    <Card padding={4}>
      <WeekCalendar
        visible={
          activeTab === TabKeys.ADD ||
          (activeTab === TabKeys.ALL && listViewMode === "calendar")
        }
        selectedDate={moment(activeDate).format("YYYY-MM-DD")}
        onDateSelect={handleActiveDateChange}
        limitPastDates={activeTab === TabKeys.ALL && TabKeys.ADD}
        limitFutureDates={1}
      />
    </Card>
  ), [activeTab])

  return (
    <Row overflow="hidden">
      <SideBar
        HeaderComponent={
          <MainHeader
            withBackNavigation
            title={translate("label.bookings")}
            info={translate("label.all")}
          />
        }
        activeItem={activeTab}
        onActiveItemChange={setActiveTab}
        items={tabsList}
      />
      <Stack flex={1} p={4} space={4} height={height}>
        <Calendar />
        <ScrollView
          flex={1}
          contentContainerStyle={{ flexGrow: 1}}
        >
          { activeTab !== TabKeys.ADD &&
            <BookingListView
              isMyBookingList={activeTab === TabKeys.MY}
              data={bookingListData}
              onViewModeChange={handleViewModeChange}
            />
          }

          { activeTab === TabKeys.ADD &&
            <BookingEventsAgenda
                unAvailableHours={unavailableHours}
                events={bookingListData}
                activeDate={activeDate}
                onNewEventPress={handleNewBookingPress}
              />
          }
        </ScrollView>
      </Stack>
      { detailsVisible &&
        <Stack flex={1}>
        {!selectedBooking && <CreateBooking date={activeDate} onSubmit={handleNewBookingCallback} />}
        {selectedBooking && <Text>Details</Text>}
        </Stack>
      }
    </Row>
  );
};

export default BookingsView;
