import { RootStore } from "@huum/store";
import { handleGenericError } from "@huum/utils/error-handler";
import { QueryClient, MutationCache, QueryCache } from "@tanstack/react-query"

export const getQueryClient = (store: RootStore) => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
    },

    queryCache: new QueryCache({
      onError: (error) => handleGenericError(error, store),
    }),
    mutationCache: new MutationCache({
      onError: (error) => handleGenericError(error, store),
    }),
  })
}
