import {AppRegistry} from 'react-native';
import {name as appName} from '../app.json';
import App from '@huum/App'

console.tron = {
  error: (e) => console.log(e),
  log: (e) => console.log(e),
}

process.env.API_URL="https://saunadev.huum.eu";
process.env.CONFIG="huum";

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});