import { useStores } from "@huum/store";
import { useQuery } from "@tanstack/react-query";
import { get } from "lodash";
import { useMemo } from "react";
import { getBookingsList } from "@huum/api/requests/bookings";
import { getUnAvailableHours, selectActiveDayUnavailableHours, selectIntoBookingListItems } from "@huum/utils/selectors/booking";

export const useBookingList = (date?: string, maxHeatingTime?: number) => {
  const { auth, sauna } = useStores();
  const session = get(auth, "sessionHash", null);
  const saunaId = get(sauna, "saunaId", "");
  const {
    data,
    isError,
    isSuccess,
    isLoading
  } = useQuery(['bookingsList', date], () => date && saunaId && session && getBookingsList({ session, date, saunaId }));
  
  const bookingList = useMemo(() => {
    return selectIntoBookingListItems(data, auth.accountId);
  }, [data]);

  const unavailableHours = useMemo(() => getUnAvailableHours(bookingList, maxHeatingTime || 6), [bookingList]);

  const activeDateUnavailableHours = useMemo(() => selectActiveDayUnavailableHours(date, unavailableHours), [unavailableHours, date]);

  return { data: bookingList, unavailableHours: activeDateUnavailableHours, isError, isSuccess, isLoading };
};


