import { translate } from "@huum/i18n";
import { Dimensions } from "react-native";


const VIEWWIDTH = Math.min(Dimensions.get('screen').width, 700) - 20;

const visibleAngleDegrees = 360 - 72;
export const MAX_TEMPERATURE = 110;
export const DEGREE_TO_TEMPERATURE_RELATION = visibleAngleDegrees / MAX_TEMPERATURE;
export const MIN_TEMPERATURE_ANGLE_DEGREES = 234;
export const MAX_TEMPERATURE_ANGLE_DEGREES = 306;

export const CONTROLLER_COLORS = {
  start: [13, 80, 99],
  end: [213, 50, 98],
  idle: [],
  power: []
}

export const DEFAULT_CONTROLLER_WIDTH = VIEWWIDTH - 60;

export const getControllerButtonText = (heatingInProgress: boolean, isControllerActive: boolean, isTimerActive: boolean) => {
  // target temp selection
  if (isControllerActive) return translate('text.target_temperature');
  
  // is heating
  if (heatingInProgress) return translate('button.press_to_stop');

  // timer
  if (isTimerActive) return translate('text.stop_timer');


  return translate('button.press_to_start');
}

export const convertTemperatureToCoordinates = (width: number, correctionX = 24) => (temperature: number) => {
  const temperatureAngleDegrees = (MIN_TEMPERATURE_ANGLE_DEGREES - (temperature * DEGREE_TO_TEMPERATURE_RELATION) + 360) % 360;
  const degToRad = Math.PI / 180;
  const halfOuterDiameter = (width - correctionX) / 2;
  const x = (Math.cos(degToRad * temperatureAngleDegrees) * halfOuterDiameter) + halfOuterDiameter
  const y = (-Math.sin(degToRad * temperatureAngleDegrees) * halfOuterDiameter) + halfOuterDiameter
  return {
    x: x,
    y: y,
  };
}

export const convertDegreesToCoordinates = (width: number, correctionX = 24) => (degrees: number) => {
  const degToRad = Math.PI / 180;
  const halfOuterDiameter = (width - correctionX) / 2;
  const x = (Math.cos(degToRad * degrees) * halfOuterDiameter) + halfOuterDiameter
  const y = (-Math.sin(degToRad * degrees) * halfOuterDiameter) + halfOuterDiameter
  
  return {
    x: x,
    y: y,
  };
}

export const pickHexColor = (color1: number[], color2: number[], weight: number) => {
  var p = weight;
  var w = p * 2 - 1;
  var w1 = (w/1+1) / 2;
  var w2 = 1 - w1;
  var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)];
  return rgb;
}

export interface Point {
  x: number;
  y: number;
}

export function calculateClosestPointToCircle(center: Point, radius: number, position: Point): Point {
  // Step 1: Calculate the distance between the center of the circle and the given coordinates
  const distance: number = Math.sqrt(Math.pow((position.x - center.x), 2) + Math.pow((position.y - center.y), 2));

  // Step 5: Calculate the closest point within the circle
  if (distance < radius) {
    const closestX: number = center.x + (position.x - center.x) * (radius / distance);
    const closestY: number = center.y + (position.y - center.y) * (radius / distance);
    return { x: closestX, y: closestY };
  }

  // Step 6: The point lies outside the circle
  const dx: number = position.x - center.x;
  const dy: number = position.y - center.y;
  const angle: number = Math.atan2(dy, dx);

  const closestX: number = center.x + Math.cos(angle) * radius;
  const closestY: number = center.y + Math.sin(angle) * radius;
  return { x: closestX, y: closestY };
}

export function calculateAngle(center: Point, position: Point): number {
  const dx: number = position.x - center.x;
  const dy: number = position.y - center.y;
  const angleRadians: number = -Math.atan2(dy, dx);
  const angleDegrees: number = (angleRadians * 180) / Math.PI;

  // Ensure the angle is positive between 0 and 360 degrees
  const positiveAngleDegrees: number = (angleDegrees + 360) % 360;

  return positiveAngleDegrees;
}