export const privacy = `
<div class="browser-container">
    <div class="browser-container-inner">
        <!-- ngView:  -->
        <main ng-view="" ng-class="{'overflow-hidden': saunaView}" class="ng-scope">
            <p align="right" class="ng-scope">Approved 20 September 2018</p>
            <h1 align="center" class="ng-scope">PRIVACY POLICY</h1>
            <p align="left" class="ng-scope">At HUUM (hereinafter also referred to as “us”, “we” or “our”) we value privacy and personal data protection. We consider integrity and confidentiality of personal data of utmost importance and guarantee lawfulness of personal data processing. The privacy policy explains how we collect and use personal data including our website, www.huum.ee and app.</p>
            <h3 class="ng-scope">Terms and definitions</h3>
            <p align="left" class="ng-scope"><u>Personal data</u> – means any information relating to an identified or identifiable natural person ('data subject'); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person;</p>
            <p align="left" class="ng-scope"><u>Processing of personal data</u> – means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction;</p>
            <p align="left" class="ng-scope"><u>Controller</u> – means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law;</p>
            <p align="left" class="ng-scope"><u>Processor</u> - means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller;</p>
            <p align="left" class="ng-scope"><u>Third party</u> – means a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data;</p>
            <p align="left" class="ng-scope"><u>Personal data breach</u> – means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed;</p>
            <p align="left" class="ng-scope"><u>Data subject</u> – person whose personal data is processed (e.g. client who is a natural person, website user or a contact person of a legal entity client).</p>
            <h3 class="ng-scope">Principles</h3>
            <p align="left" class="ng-scope">HUUM and the processors working for or with us process personal data adhering to following principles:</p>
            <p align="left" class="ng-scope"><u>lawfulness, fairness and transparency</u> – the processing is lawful, fair and transparent to the data subject;</p>
            <p align="left" class="ng-scope"><u>purpose limitation</u> – collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes;</p>
            <p align="left" class="ng-scope"><u>data minimisation</u> – adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed;</p>
            <p align="left" class="ng-scope"><u>accuracy</u> – the personal data is accurate and up to date; we employ all reasonable measures to ensure that inaccurate personal data is deleted or corrected;</p>
            <p align="left" class="ng-scope"><u>storage limitation</u> – kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed;</p>
            <p align="left" class="ng-scope"><u>integrity and confidentiality</u> – processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures.</p>
            <h3 class="ng-scope">Security of processing</h3>
            <p align="left" class="ng-scope">HUUM applies necessary, and appropriate to a risk, organisational, physical and technological measures to protect personal data. These measures include rules and procedures for employees, for managing data and IT infrastructure, internal and external networks and protection of the equipment and the building of HUUM.
                <br />
                <br />In addition, HUUM may use processors to process personal data, we ensure that all our processors process personal data in accordance with our instructions, applicable law and employing all appropriate organisational and technological security measures.
                <br />
                <br />To ensure adequate levels of data protection in at HUUM we have provided relevant training to all employees processing personal data.</p>
            <h3 class="ng-scope">Lawful basis of personal data processing</h3>
            <p align="left" class="ng-scope">We process personal data:</p>
            <ul class="ng-scope">
                <li>to ensure <u>performance of a contract</u>, for example when we have concluded a contract and the contractual aim can only be achieved by processing personal data;</li>
                <li>to comply with <u>legal obligations</u>, means personal data processing under applicable laws and regulations for example Employment Contracts Law;</li>
                <li>we process personal data on grounds of our <u>legitimate interest</u> to improve the quality of our services and for the purposes of business development. To ensure that our legitimate interest doesn’t breach data subjects’ fundamental freedoms and rights we conduct a three part test to guarantee that the processing is purposeful, required and proportional to the stated purpose.</li>
                <li>or on the basis of data subject’s consent, when processing personal data with consent as lawful basis we only process specifically what data subject has consented to. The consent is freely given, specific and informed. Data subject can take back consent at any given time and as easily taken back as it was given.</li>
            </ul>
            <h3 class="ng-scope">Controller or processor and processing of data</h3>
            <p align="left" class="ng-scope">HUUM can is a controller of personal data that is collected directly from the data subject i.e. you give us the information yourself, when we conclude a service contract with you.
                <br />
                <br />To ensure data subjects privacy rights HUUM abides by confidentiality principles and strictly limits disclosure of personal data to third parties. Only the persons authorised by HUUM have the right to modify and process personal data.</p>
            <h3 class="ng-scope">Types and purpose of personal data processing</h3>
            <p align="left" class="ng-scope">At HUUM we may collect following types of data:</p>
            <ul class="ng-scope">
                <li>personal data: first and last name;</li>
                <li>internet data: data on website visitors’ sessions, cookies, log data and IP addresses.</li>
                <li>contact details: e-mail address, contact telephone number, postal address (place of residence);</li>
                <li>sauna heating statistics.</li>
            </ul>
            <p align="left" class="ng-scope">We collect personal data and contact details to provide personalised modern sauna control services, to that the sauna heater can be remotely controlled by the owners. We also offer user statistics of sauna heaters if the client has ordered the statistics service from us.</p>
            <p align="left" class="ng-scope">At HUUM we collect the user statistics of www.huum.eu and app to improve the user experience of using the website and app. When you access our website we may automatically collect:</p>
            <ul class="ng-scope">
                <li>The type of Web browser and operating system you have used to access our website;</li>
                <li>The type of device you have used to access our Services, including unique device identifiers;</li>
                <li>Your IP address (which is a number automatically assigned to your computer when you access the Internet and can be used to derive your general geographic location);</li>
                <li>The websites you visited before and after you visit our Services;</li>
                <li>Information about the content you view and the actions you take on the Services;</li>
                <li>Information about your current location (if you consent to providing us this information);</li>
                <li>Information we collect using cookies, as described below.</li>
            </ul>
            <p align="left" class="ng-scope">We may use third-party providers to help us collect some of this information. This information is not linked to your browser session ID as stored by cookies nor is it individually identifiable. For more information on how we use cookies, please see Cookies section below</p>
            <h3 class="ng-scope">Retention of personal data</h3>
            <p align="left" class="ng-scope">HUUM retains personal data only as long as this is necessary to fulfil the purpose for which the personal data is processed, unless there is an applicable legal obligation stating otherwise.
                <br />
                <br />We retain invoices and personal data on them for 7 years in accordance with the Estonian Accounting Act. We store the data we collected to offer you personalised service up to one year after the end of business relationship.
                <br />
                <br />HUUM retains other data until the end of the respective retention term specified in the company’s personal data processing inventory.
                <br />
                <br />HUUM shall securely destroy and/or delete all personal data that has fulfilled its purpose or upon expiring of the retention term</p>
            <h3 class="ng-scope">Third parties and data processors</h3>
            <p align="left" class="ng-scope">The use of third parties and processors is limited by necessity and pursuant to the data processing purposes, HUUM may forward personal data to third parties and data processors for the following purposes:</p>
            <ul class="ng-scope">
                <li>for client relationship management;</li>
                <li>IT housing services.</li>
            </ul>
            <p align="left" class="ng-scope">Regardless of access restrictions, HUUM shall release a document to an organisation or a person who legal right to request the data (e.g. police, court, supervisory authority etc).</p>
            <h3 class="ng-scope">Rights of the data subject</h3>
            <p align="left" class="ng-scope">The data subject, i.e. our client, has the right to receive information regarding processing of their personal data. You can obtain a copy of their personal data held by HUUM by submitting a request via e-mail to info@huum.eu .
                <br />
                <br />The data subject has the right to deletion of personal data if the processing of personal data took place on the basis of consent.
                <br />
                <br />The data subject has the right to restrict the processing of personal data and where feasible a data subject has the right to data portability.
                <br />
                <br />HUUM has a legal obligation to make sure that a person requesting information about themselves is indeed the person who has the right to receive the data. For this reason, the requesters may have to prove their identity or right to request the data.
                <br />
                <br />The data subject also has the right to lodge a complaint to the Data Protection Inspectorate regarding processing of personal data.</p>
            <h3 class="ng-scope">Cookies</h3>
            <p align="left" class="ng-scope">We use cookies on our website, www.huum.eu. A cookie is a small text file that a web browser automatically saves in the users’ device.
                <br />
                <br />HUUM uses different types of cookies and each has a specific function. Some cookies are essential to the working of the website and allow you to navigate easily from page to page. Other nonessential cookies allow us to analyse how you use our site, which enables us to make decisions about how best to improve the website design and functionality to fit your future needs.
                <br />
                <br />It is possible to refuse or block cookies on the device, this may mean that the website may not function properly and all services may not be available. To refuse or block cookies you need to change your browser settings.</p>
            <h3 class="ng-scope">Changes to the privacy policy</h3>
            <p align="left" class="ng-scope">Personal privacy is important to HUUM and we updates this privacy policy regularly. The version published on HUUM website is always the latest version.</p>
            <h3 class="ng-scope">Contact information</h3>
            <p align="left" class="ng-scope">If you have any issues, concerns or suggestions about personal data processing at HUUM please contact us at:
                <br />
                <br />
                <br />HUUM OÜ
                <br />Turu 45D
                <br />Tartu, 50106
                <br />info@huum.eu</p>
                <br />
        </main>
    </div>
</div>
`;

export const terms = `
<div class="browser-container">
  <div class="browser-container-inner">
      <main ng-view="" ng-class="{'overflow-hidden': saunaView}" class="ng-scope">
          <h1 class="ng-scope">Terms and Conditions ("Terms")</h1>
          <p align="right" class="ng-scope">Last updated: May 22, 2019</p>
          <br class="ng-scope">
          <p align="left" class="ng-scope">Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the HUUM app (the "Service") operated by HUUM ("us", "we", or "our").</p>
          <p align="left" class="ng-scope">Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
          <p align="left" class="ng-scope">By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
          <h1 class="ng-scope">Returns and Refunds Policy</h1>
          <h3 class="ng-scope">Digital services</h3>
          <p align="left" class="ng-scope">We do not issue refunds for digital products once the order is confirmed and the service is enabled.</p>
          <p align="left" class="ng-scope">We recommend contacting us via email at support@huum.eu for assistance if you experience any issues with the service.</p>
          <h3 class="ng-scope">Accounts</h3>
          <p align="left" class="ng-scope">When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
          <p align="left" class="ng-scope">You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
          <p align="left" class="ng-scope">You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
          <h3 class="ng-scope">Intellectual Property Rights</h3>
          <p align="left" class="ng-scope">Other than the content you own, under these Terms, HUUM OÜ and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
          <p align="left" class="ng-scope">You are granted limited license only for purposes of viewing the material contained on this Website.</p>
          <h3 class="ng-scope">Restrictions</h3>
          <p align="left" class="ng-scope">You are specifically restricted from all of the following:</p>
          <ul class="ng-scope">
              <li>selling, sublicensing and/or otherwise commercializing any app material;</li>
              <li>using this app in any way that is or may be damaging to this app and/or server;</li>
              <li>using this app in any way that impacts user access to this app and/or server;</li>
              <li>using this app contrary to applicable laws and regulations, or in any way may cause harm to the app, or to any person or business entity;</li>
              <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this app;</li>
              <li>using this app to engage in any advertising or marketing.</li>
          </ul>
          <p align="left" class="ng-scope">Certain areas of this app are restricted from being access by you. Any user ID and password you may have for this app are confidential and you must maintain confidentiality as well.</p>
          <h3 class="ng-scope">Links To Other Web Sites</h3>
          <p align="left" class="ng-scope">Our Service may contain links to third-party web sites or services that are not owned or controlled by HUUM.</p>
          <p align="left" class="ng-scope">HUUM has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that HUUM shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
          <p align="left" class="ng-scope">We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
          <h3 class="ng-scope">Termination</h3>
          <p align="left" class="ng-scope">We may terminate or suspend access to our Service immediately, without prior notice or liability, including without limitation if you breach the Terms.</p>
          <p align="left" class="ng-scope">We may terminate or suspend your account immediately, without prior notice or liability, including without limitation if you breach the Terms.</p>
          <p align="left" class="ng-scope">Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</p>
          <p align="left" class="ng-scope">All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
          <h3 class="ng-scope">Governing Law</h3>
          <p align="left" class="ng-scope">These Terms shall be governed and construed in accordance with the laws of Estonia, without regard to its conflict of law provisions.</p>
          <p align="left" class="ng-scope">Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
          <h3 class="ng-scope">Changes</h3>
          <p align="left" class="ng-scope">We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p align="left" class="ng-scope">By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
          <h3 class="ng-scope">Contact Us</h3>
          <p align="left" class="ng-scope">If you have any questions about these Terms and/or Returns and Refunds Policy, please contact us by email: support@huum.eu</p>
          <br />
      </main>
  </div>
</div>
`;

