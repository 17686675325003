import { useCallback, useEffect, useMemo, useState } from "react";
import { Input } from "@huum/components/atoms/input/input";
import { Touchable } from "@huum/components/atoms/touchable/touchable";
import { useStores } from "@huum/store";
import { get, find } from "lodash";
import { TLocale, languages } from "@huum/i18n";
import { observer } from "mobx-react-lite";

export interface LanguageSelectProps {
  onConfirm?: (value: any) => void;
}

const LanguageSelect = observer((props: LanguageSelectProps) => {
  const { modal, auth } = useStores();

  const { language, setLanguage } = auth

  const onConfirm = useCallback(
    (value: string) => {
      setLanguage(value as TLocale);
    },
    [setLanguage]
  );

  const onPress = useCallback(() => {
    modal.openModal("languageSelect", {
      options: languages,
      onConfirm,
    });
  }, [languages]);

  const displayValue = useMemo(() => {
    return get(
      find(languages, (item) => item.value === language),
      "label",
      ""
    );
  }, [language]);

  return (
    <Touchable flex={1} onPress={onPress}>
      <Input
        onPressIn={onPress}
        value={displayValue}
        editable={false}
        onIconRightPress={onPress}
        iconRight="chevron-down"
        iconSize={6}
      />
    </Touchable>
  );
});

export default LanguageSelect;
